import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TabItemType} from '../../../interfaces/api/content/home';
import {
  HomeTabsState,
  HomeFeatureItem,
} from '../../../interfaces/redux/homeTabs';
import {NewsItemType} from '../../../interfaces/api/tabs';

const initialState: HomeTabsState = {
  tabs: [],
  recommendations: [],
  features: [],
  mainHomeTabs: [],
  isInstructionsSeen: false,
  isLoading: false,
  isError: false,
};

export const homeTabsSlice = createSlice({
  name: 'homeTabs',
  initialState,
  reducers: {
    startHomeTabsLoading: state => {
      state.isLoading = true;
      state.isError = false;
    },
    setIsHomeTabsError: state => {
      state.isLoading = false;
      state.isError = true;
    },
    setTabs: (state, action: PayloadAction<TabItemType[]>) => {
      state.tabs = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    setRecommendations: (state, action: PayloadAction<NewsItemType[]>) => {
      state.recommendations = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    dismissHomeTabsInstructions: state => {
      state.isInstructionsSeen = true;
    },
    setFeatures: (state, action: PayloadAction<HomeFeatureItem[]>) => {
      state.features = action.payload;
    },
    setMainHomeTabs: (state, action: PayloadAction<TabItemType[]>) => {
      state.mainHomeTabs = action.payload;
    },
    moveHomeTabItem: (
      state,
      action: PayloadAction<{fromIndex: number; toIndex: number}>,
    ) => {
      const {fromIndex, toIndex} = action.payload;
      const newTabs = Array.from(state.mainHomeTabs);
      const [movedItem] = newTabs.splice(fromIndex, 1);
      newTabs.splice(toIndex, 0, movedItem);
      state.mainHomeTabs = newTabs;
    },
    moveHomeFeatureItem: (
      state,
      action: PayloadAction<{fromIndex: number; toIndex: number}>,
    ) => {
      const {fromIndex, toIndex} = action.payload;
      const newFeatures = Array.from(state.features);
      const [movedItem] = newFeatures.splice(fromIndex, 1);
      newFeatures.splice(toIndex, 0, movedItem);
      state.features = newFeatures;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  startHomeTabsLoading,
  setIsHomeTabsError,
  setTabs,
  setRecommendations,
  dismissHomeTabsInstructions,
  setFeatures,
  setMainHomeTabs,
  moveHomeTabItem,
  moveHomeFeatureItem,
} = homeTabsSlice.actions;

export default homeTabsSlice.reducer;
