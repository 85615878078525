const keyName = process.env.REACT_APP_KEY_NAME;

export const keys = {
  CURRENT_USER: `${keyName}_CURRENT_USER`,
  FAVORITE: `${keyName}_FAVORITE`,
  NOTES: `${keyName}_NOTES`,
  LANG: `${keyName}_LANGUAGE`,
  DARK_MODE: `${keyName}_DARK_MODE`,
  FONT: `${keyName}_FONT`,
  INSTRUCTIONS: `${keyName}_INSTRUCTIONS`,
  LAST_LOGIN: `${keyName}_LAST_LOGIN`,
  ONBOARDING: `${keyName}_ONBOARDING`,
  SORT_TABS: `${keyName}_SORT_TABS`,
  HOME_FEATURES: `${keyName}_HOME_FEATURES`,
  HOME_MAIN_TABS: `${keyName}_HOME_MAIN_TABS`,
};
