// Rheumatology Treatment Options
import {basePdfUrl} from '../../../../../api/api';

const treatmentOptionsToRheumatology = basePdfUrl + 'to/rheumatology/';

export const treatmentOptionsRheumatologyData = [
  {
    id: 0,
    title: 'Osteoporosis',
    pdf_link:
      treatmentOptionsToRheumatology + '2-Osteoporosis-Treatment-Options.pdf',
  },
  {
    id: 1,
    title: 'Rheumatoid Arthritis',
    pdf_link:
      treatmentOptionsToRheumatology +
      '2-Rheumatoid-Arthritis-Treatment-Options.pdf',
  },
  {
    id: 2,
    title: 'Sports Medicine',
    pdf_link:
      treatmentOptionsToRheumatology +
      '2-Sports-Medicine-Treatment-Options.pdf',
  },
  {
    id: 3,
    title: 'Ankylosing Spondylitis',
    pdf_link:
      treatmentOptionsToRheumatology +
      'Ankylosing-spondylitis-Treatment-Options.pdf',
  },
  {
    id: 4,
    title: 'Bone Fractures',
    pdf_link:
      treatmentOptionsToRheumatology + 'Bone-Fractures-Treatment-Options.pdf',
  },
  {
    id: 5,
    title: 'Gout',
    pdf_link: treatmentOptionsToRheumatology + 'Gout-Treatment-Options-.pdf',
  },
  {
    id: 6,
    title: 'Osteoarthritis',
    pdf_link:
      treatmentOptionsToRheumatology + 'Osteoarthritis-Treatment-Options.pdf',
  },
  {
    id: 7,
    title: 'Psoriatic Arthritis',
    pdf_link:
      treatmentOptionsToRheumatology +
      'Psoriatic-Arthritis-Treatment-Options(1).pdf',
  },
  {
    id: 8,
    title: 'Spinal Disorders',
    pdf_link:
      treatmentOptionsToRheumatology + 'Spinal-Disorders-Treatment-Options.pdf',
  },
];
