import * as React from 'react';
import {SVGProps} from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    strokeWidth="2"
    viewBox="0 0 24 24"
    strokeLinecap="round"
    strokeLinejoin="round"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="none"
    stroke="none">
    <path
      stroke={props.fill || '#000'}
      d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
    <circle stroke={props.fill || '#000'} cx="12" cy="7" r="4"></circle>
  </svg>
);
export default SvgComponent;
