// Gastroenterology Guidelines
import {basePdfUrl} from '../../../../../api/api';

const guidelinesGlGastroenterology = basePdfUrl + 'gl/gastroenterology/';

export const guidelinesGastroenterologyData = [
  {
    id: 0,
    title: 'Diarrhea and Constipation',
    pdf_link:
      guidelinesGlGastroenterology +
      '3-Diarrhea-and-constipation-guidelines.pdf',
  },
  {
    id: 1,
    title: 'Esophagitis and Gastritis',
    pdf_link:
      guidelinesGlGastroenterology +
      '3-Esophagitis-and-Gastritis-Guidelines.pdf',
  },
  {
    id: 2,
    title: 'Pancreatitis',
    pdf_link: guidelinesGlGastroenterology + '3-Pancreatitis-guidelines-.pdf',
  },
  {
    id: 3,
    title: 'Celiac Disease',
    pdf_link: guidelinesGlGastroenterology + 'Celiac-Disease-Guidelines.pdf',
  },
  {
    id: 4,
    title: 'Functional Dyspepsia',
    pdf_link:
      guidelinesGlGastroenterology + 'Functional-Dyspepsia-Guidelines.pdf',
  },
  {
    id: 5,
    title: 'Gastroesophageal Reflux Disease',
    pdf_link: guidelinesGlGastroenterology + 'GERD-Guidelines.pdf',
  },
  {
    id: 6,
    title: 'Gallstones and Cholecystitis',
    pdf_link:
      guidelinesGlGastroenterology +
      'Gallstones-and-Cholecystitis-Guidelines.pdf',
  },
  {
    id: 7,
    title: 'Gastroparesis',
    pdf_link: guidelinesGlGastroenterology + 'Gastroparesis-Guidelines.pdf',
  },
  {
    id: 8,
    title: 'Hepatitis and Cirrhosis',
    pdf_link:
      guidelinesGlGastroenterology + 'Hepatitis-and-Cirrhosis-Guidelines.pdf',
  },
  {
    id: 9,
    title: 'Inflammatory Bowel Disease',
    pdf_link:
      guidelinesGlGastroenterology +
      'Inflammatory-Bowel-Disease-Guidelines.pdf',
  },
  {
    id: 10,
    title: 'Irritable Bowel Syndrome',
    pdf_link:
      guidelinesGlGastroenterology + 'Irritable-Bowel-Syndrome-Guidelines.pdf',
  },
  {
    id: 11,
    title: 'Peptic Ulcer Disease',
    pdf_link:
      guidelinesGlGastroenterology + 'Peptic-Ulcer-Disease-Guidelines.pdf',
  },
];
