import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState, store} from '../redux/store';
import {login} from '../screens/auth/login/reducer';
import {updateConfig} from '../screens/auth/login/actions';
import {updateAppFont} from '../screens/content/fontChanger/actions';
import {toggleDarkMode} from '../screens/content/settings/action';
import RouteSwitcher from './routeSwitcher';
import {keys} from '../api/keys';
import {BrowserRouter} from 'react-router-dom';
import {MainLayoutProvider} from './routeMainLayout/context';
import {LoginDataType} from '../interfaces/api/auth/login';
import {getTabsFilters} from '../screens/content/home/actions';
import {initOnboardingScreen} from '../screens/auth/onboarding/actions';
import {storage} from '../utils/storage';

/**
 * The main navigation component that handles routing and navigation between screens.
 * @constructor
 */
const AppNavigator = () => {
  /**
   * The current router state, which determines whether to show 'content' , 'firstLogin' , 'onboarding' or 'auth' routes.
   * @type {'content' | 'auth' | 'firstLogin' | 'onboarding'| ''}
   */
  const [router, setRouter] = useState<
    'auth' | 'content' | 'firstLogin' | 'onboarding' | ''
  >('');
  const userStatus = useSelector((state: RootState) => state.auth);
  const {isSeen: isOnboardingSeen} = useSelector(
    (state: RootState) => state.onboarding,
  );

  useEffect(() => {
    initRouter();
    updateAppFont(undefined, true);
    toggleDarkMode({isInitialize: true});
  }, []);

  useEffect(() => {
    checkLogin();
    if (userStatus && userStatus?.currentUser?.token) {
      getTabsFilters();
    }
  }, [isOnboardingSeen, userStatus]);

  /**
   * Initialize routes by checking the user's login status in local storage.
   */
  const initRouter = async () => {
    await initOnboardingScreen();
    const currentUser = await storage.get<LoginDataType>(keys.CURRENT_USER);
    if (currentUser && currentUser?.token) {
      store.dispatch(login(currentUser));
      updateConfig();
    } else {
      store.dispatch(login(null));
    }
  };

  /**
   * Check user login status and set the correct routes ('content' or 'auth').
   */
  const checkLogin = async () => {
    if (isOnboardingSeen === false) {
      setRouter('onboarding');
    } else if (userStatus && userStatus?.currentUser?.is_first_login) {
      setRouter('firstLogin');
    } else if (userStatus && userStatus?.currentUser?.token) {
      setRouter('content');
    } else if (
      userStatus?.currentUser === null ||
      userStatus?.currentUser?.token === null
    ) {
      setRouter('auth');
    }
    return '';
  };

  return (
    <MainLayoutProvider>
      <BrowserRouter>
        <RouteSwitcher router={router} />
      </BrowserRouter>
    </MainLayoutProvider>
  );
};

export default AppNavigator;
