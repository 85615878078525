// Gastroenterology
import {basePdfUrl} from '../../../../../api/api';

const diagnosticToolsDtGastroenterology = basePdfUrl + 'dt/gastroenterology/';

export const diagnosticToolsGastroenterologyData = [
  {
    id: 0,
    title: 'Diarrhea and Constipation',
    pdf_link:
      diagnosticToolsDtGastroenterology +
      '1-Diarrhea-and-constipation-diagnosis.pdf',
  },
  {
    id: 1,
    title: 'Esophagitis and Gastritis',
    pdf_link:
      diagnosticToolsDtGastroenterology +
      '1-Esophagitis-and-Gastritis-Diagnostic-Tools.pdf',
  },
  {
    id: 2,
    title: 'Pancreatitis',
    pdf_link:
      diagnosticToolsDtGastroenterology + '1-Pancreatitis-diagnosis.pdf',
  },
  {
    id: 3,
    title: 'Celiac Disease',
    pdf_link:
      diagnosticToolsDtGastroenterology + 'Celiac-Disease-DIAGNOSIS.pdf',
  },
  {
    id: 4,
    title: 'Functional Dyspepsia',
    pdf_link:
      diagnosticToolsDtGastroenterology + 'Functional-Dyspepsia-Diagnosis.pdf',
  },
  {
    id: 5,
    title: 'Gastroesophageal Reflux Disease',
    pdf_link: diagnosticToolsDtGastroenterology + 'GERD-Diagnosis.pdf',
  },
  {
    id: 6,
    title: 'Gallstones and Cholecystitis',
    pdf_link:
      diagnosticToolsDtGastroenterology +
      'Gallstones-and-Cholecystitis-Diagnosis.pdf',
  },
  {
    id: 7,
    title: 'Gastroparesis',
    pdf_link: diagnosticToolsDtGastroenterology + 'Gastroparesis-Diagnosis.pdf',
  },
  {
    id: 8,
    title: 'Hepatitis and Cirrhosis',
    pdf_link:
      diagnosticToolsDtGastroenterology +
      'Hepatitis-and-Cirrhosis-Diagnosis.pdf',
  },
  {
    id: 9,
    title: 'Inflammatory Bowel Disease',
    pdf_link:
      diagnosticToolsDtGastroenterology +
      'Inflammatory-Bowel-Disease-Diagnosis.pdf',
  },
  {
    id: 10,
    title: 'Irritable Bowel Syndrome',
    pdf_link:
      diagnosticToolsDtGastroenterology +
      'Irritable-bowel-syndrome-DIAGNOSIS.pdf',
  },
  {
    id: 11,
    title: 'Peptic Ulcer Disease',
    pdf_link:
      diagnosticToolsDtGastroenterology + 'Peptic-Ulcer-Disease-Diagnosis.pdf',
  },
];
