// Anti-Infective
import {basePdfUrl} from '../../../../../api/api';

const diagnosticToolsDtAnti = basePdfUrl + 'dt/anti/';

export const diagnosticToolsAntiData = [
  {
    id: 0,
    title: 'Amebiasis',
    pdf_link: diagnosticToolsDtAnti + 'Amebiasis-Diagnosis.pdf',
  },
  {
    id: 1,
    title: 'Aspergillosis',
    pdf_link: diagnosticToolsDtAnti + 'Aspergillosis-Diagnosis.pdf',
  },
  {
    id: 2,
    title: 'COVID-19',
    pdf_link: diagnosticToolsDtAnti + 'COVID-19-Diagnosis.pdf',
  },
  {
    id: 3,
    title: 'Candidiasis',
    pdf_link: diagnosticToolsDtAnti + 'Candidiasis-DiagnosticTools.pdf',
  },
  {
    id: 4,
    title: 'Leishmaniasis',
    pdf_link: diagnosticToolsDtAnti + 'Leishmaniasis-Diagnosis.pdf',
  },
  {
    id: 5,
    title: 'Lyme Disease',
    pdf_link: diagnosticToolsDtAnti + 'Lyme-disease-Diagnosis.pdf',
  },
  {
    id: 6,
    title: 'Malaria',
    pdf_link: diagnosticToolsDtAnti + 'Malaria-Diagnosis-.pdf',
  },
  {
    id: 7,
    title: 'Meningitis',
    pdf_link: diagnosticToolsDtAnti + 'Meningitis-Diagnosis.pdf',
  },
  {
    id: 8,
    title: 'Respiratory Syncytial Virus',
    pdf_link:
      diagnosticToolsDtAnti + 'Respiratory-Syncytial-Virus-Diagnosis.pdf',
  },
  {
    id: 9,
    title: 'Syphilis',
    pdf_link: diagnosticToolsDtAnti + 'Syphilis-DiagnosticTools.pdf',
  },
];
