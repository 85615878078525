import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styled, {useTheme} from 'styled-components';
import {Theme} from '../../theme/lightTheme';
import GenioNews from '../genioNews';

const Container = styled.div`
  display: grid;
  gap: 16px;
  margin-bottom: 16px;
  @media (min-width: 1201px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const SkeletonCard = styled.div<{$isSmall?: boolean}>`
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1;
  background: ${({theme}) => theme.text.light};
  border-radius: ${({$isSmall}) => ($isSmall ? 8 : 10)}px;
`;
const LatestNewsItemSkeleton = styled(GenioNews)`
  opacity: 0;
  pointer-events: none;
  width: 100%;
  align-self: center;
`;

interface LatestNewsSkeletonProps {
  number?: number;
  isSmall?: boolean;
}

const LatestNewsSkeleton: React.FC<LatestNewsSkeletonProps> = ({
  number = 3,
  isSmall,
}) => {
  const theme = useTheme() as Theme;

  return (
    <Container>
      {Array(number)
        .fill(0)
        .map((_, index) => (
          <SkeletonCard $isSmall={isSmall} key={index}>
            <Skeleton
              baseColor={'transparent'}
              highlightColor={theme.home.cardBackground + '33'}
              height={'100%'}
              width={'100%'}
              style={{
                position: 'absolute',
                inset: 0,
                borderRadius: isSmall ? 8 : 10,
                zIndex: 22,
              }}
            />
            <LatestNewsItemSkeleton title={'aa'} />
          </SkeletonCard>
        ))}
    </Container>
  );
};

export default LatestNewsSkeleton;
