import React, {SVGProps} from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={74}
    height={73}
    viewBox="0 0 74 73"
    {...props}
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#0B0D6F"
      d="M36.78 68.74c17.878 0 32.37-14.492 32.37-32.37C69.15 18.493 54.658 4 36.78 4 18.903 4 4.41 18.493 4.41 36.37c0 17.878 14.493 32.37 32.37 32.37Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M49.49 34.25c0 7.02-5.69 12.72-12.72 12.72s-12.72-5.69-12.72-12.72"
    />
    <path
      stroke="#fff"
      strokeWidth={1.5}
      d="M30.42 23.649c0-3.51 2.85-6.36 6.36-6.36 3.51 0 6.36 2.85 6.36 6.36v10.6c0 3.51-2.85 6.36-6.36 6.36-3.51 0-6.36-2.85-6.36-6.36v-10.6Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M36.78 46.96v8.48M32.54 55.438h8.48M38.89 23.648h4.24M38.89 30.008h4.24"
    />
    <path
      stroke="url(#a)"
      strokeMiterlimit={10}
      strokeWidth={4}
      d="M37.1 70.519c-18.83 0-34.15-15.32-34.15-34.15S18.27 2.219 37.1 2.219s34.15 15.32 34.15 34.15-15.32 34.15-34.15 34.15Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={21.82}
        x2={52.37}
        y1={3.609}
        y2={69.129}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#7CF3FF" />
        <stop offset={0.07} stopColor="#71EBFA" />
        <stop offset={0.2} stopColor="#56D6EF" />
        <stop offset={0.35} stopColor="#2AB5DD" />
        <stop offset={0.38} stopColor="#23B0DB" />
        <stop offset={0.46} stopColor="#23A4DD" />
        <stop offset={0.61} stopColor="#2487E2" />
        <stop offset={0.81} stopColor="#2657EB" />
        <stop offset={1} stopColor="#2823F5" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgComponent;
