import React, {memo} from 'react';
import styled, {useTheme} from 'styled-components';
import {NavLink, useLocation} from 'react-router-dom';
import Icon from '../icon';
import {Theme} from '../../theme/lightTheme';
import Pulse from '../pulse';
import {tr} from '../../translation';
import {headerTabsData} from '../../assets/static/headerTabs';
import {isSmallHeight} from '../../utils/responsive';

const iconSize = (theme: any) =>
  theme.isMobile
    ? `${theme.dimensions.wdp(6)}px`
    : isSmallHeight
      ? '1.75rem'
      : '2rem';

const Container = styled.div`
  flex-grow: 0;
  padding: ${({theme}) =>
      `calc(${iconSize(theme)} / ${theme.isMobile ? 4 : 7})`}
    0;
  border-radius: 50px;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  pointer-events: auto;
`;

const TabIconContainer = styled.div<{isMain?: boolean}>`
  &:active {
    scale: ${({isMain}) => (isMain ? 1 : 0.9)};
    opacity: ${({isMain}) => (isMain ? 1 : 0.9)};
  }
  z-index: 99;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div<{isMain?: boolean}>`
  background: ${({theme}) => theme.text.light};
  aspect-ratio: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({isMain, theme}) =>
    isMain
      ? `calc(${iconSize(theme)} / 1.5)`
      : `calc(${iconSize(theme)} / 2.2)`};
  border-radius: 50%;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    scale: ${({isMain}) => (isMain ? 0.95 : 0.9)};
  }

  ${({isMain}) =>
    isMain &&
    `
    transform: scale(1.25);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    z-index: 2;
    `}
`;

const MainIconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
`;

const TabBackground = styled.div`
  display: none;
`;

const LabelContainer = styled.div`
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  width: 100%;
  text-align: center;
`;

const Label = styled.p`
  font-size: ${({theme}) => theme.text.s9};
  color: ${({theme}) => theme.text.dark};
  letter-spacing: 1px;
  pointer-events: none;
  text-align: center;
  white-space: nowrap;
  opacity: 0.8;
`;

/**
 * The header component.
 * Renders a header with title, back button, and optional right icon.
 *
 * @returns {JSX.Element} The rendered header component.
 */
const Header = () => {
  /**
   * Represents the current theme.
   * @type {Theme}
   */
  const theme = useTheme() as Theme;
  const {pathname} = useLocation();

  const _renderTabContent = item => {
    const isSelected = pathname === item.pathname;
    return (
      <NavLink to={item?.pathname}>
        <IconWrapper key={item.label} isMain={item.isMainIcon}>
          <TabIconContainer isMain={item.isMainIcon}>
            {!item.isMainIcon && (
              <TabBackground>
                <Icon
                  name={'tabBackground'}
                  color={theme.header.primary}
                  stroke={theme.header.primary}
                />
              </TabBackground>
            )}
            <Icon name={item.iconName} size={iconSize(theme)} />
          </TabIconContainer>
          {item.isMainIcon ? null : !theme.isMobile ? (
            <LabelContainer>
              <Label>{tr(`headerTabs.${item.label}`)}</Label>
            </LabelContainer>
          ) : null}
        </IconWrapper>
      </NavLink>
    );
  };

  const _renderHeaderTab = (item: any) => {
    if (item.isMainIcon) {
      return (
        <MainIconContainer key={item.label}>
          {_renderTabContent(item)}
        </MainIconContainer>
      );
    }
    return _renderTabContent(item);
  };

  return <Container>{headerTabsData.map(_renderHeaderTab)}</Container>;
};

export default memo(Header);
