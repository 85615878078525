import {tr} from '../translation';
import {FilterData, FilterItemType, FiltersType} from '../interfaces/api/tabs';
import {ConfigDataType, MatchingDataType} from '../interfaces/api/auth/login';
import moment, {Moment} from 'moment';
import {Filter} from '../interfaces/components/filtersModal';

/**
 * This method to get the error message from error description response
 * @param {string|string[]} error
 * @param {string} fallBackMessage
 *
 * @return {string}
 */
export const getErrorMessage = (
  error: string | string[],
  fallBackMessage: string = tr('app.error'),
): string => {
  if (!error) {
    return fallBackMessage;
  }
  if (typeof error === 'string') {
    if (error.includes('undefined')) {
      return fallBackMessage;
    }
    return error;
  }
  if (error.length) {
    return getErrorMessage(error[0]);
  }
  return fallBackMessage;
};

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Finds an item by the key and id in a FiltersType object.
 *
 * @param {FiltersType} filterDataList - The FiltersType object containing FilterData.
 * @param {string} key - The key to identify the FilterData object.
 * @param {number} id - The id of the item to find.
 * @returns {({filterData: FilterData; filterItem: FilterItemType | undefined} | undefined)} - The found item and its FilterData or undefined if not found.
 */
export const getFilterItemByKey = (
  filterDataList: FiltersType,
  key: string,
  id: number,
):
  | {filterData: FilterData; filterItem: FilterItemType | undefined}
  | undefined => {
  if (!id) {
    return undefined;
  }
  const filterData = Object.values(filterDataList).find(
    data => data.key === key,
  );
  if (filterData) {
    const filterItem = filterData.items.find(item => item.id === id);
    return {filterData, filterItem};
  }
  return undefined;
};

/**
 * Checks if the given MP3 URL is available for download.
 *
 * @param {string} url - The URL of the MP3 file to check.
 * @returns {Promise<boolean>} - A promise that resolves to ⁠ true ⁠ if the MP3 URL is available, and ⁠ false ⁠ otherwise.
 */
export const isMp3Available = async (url: string): Promise<boolean> => {
  try {
    const response = await fetch(url, {method: 'HEAD'});
    if (
      response.ok &&
      response.headers.get('content-type')?.includes('binary/octet-stream')
    ) {
      if (process.env.REACT_APP_DEBUG === 'true') {
        console.log('mp3 available');
      }
      return true;
    } else {
      if (process.env.REACT_APP_DEBUG === 'true') {
        console.log('mp3 not available: ', url);
      }
      return false;
    }
  } catch (error) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.error('Error checking MP3 URL:', error);
    }
    return false;
  }
};

export const findFilterKey = (
  filters: Filter[],
  searchKey: string,
): string | undefined => {
  for (const key in filters) {
    if (filters[key].key === searchKey) {
      return key;
    }
  }
  return undefined;
};

/**
 * Splits the input text into an array containing the parts of the text and any URLs found within it.
 * URLs are identified based on a predefined pattern that matches most common URL formats, including
 * protocols like http, https, ftp, and file.
 *
 * @param {string} text - The input string that may contain one or more URLs.
 * @returns {string[]} An array of strings, where each element is either a part of the original text
 *                      without URLs or an entire URL found in the text. If the input text is empty
 *                      or does not contain any URLs, the function returns an array containing
 *                      the original text or an empty array if the text is empty.
 */
export const getUrlParts = (text: string): string[] => {
  if (!text) {
    return [];
  }
  if (typeof text !== 'string') {
    text = String(text); // Ensure the input is a string
  }
  const urlPattern =
    /(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:.;,]*[-A-Z0-9+&@#/%=~_|]\b)|(\bwww\.[-A-Z0-9+&@#/%?=~_|!:.;,]*[-A-Z0-9+&@#/%=~_|]\b)/gi;
  return text.split(urlPattern);
};
/**
 * Determines whether the input text is a URL.
 * This function utilizes a regular expression pattern to identify URLs based on common URL formats,
 * including protocols such as http, https, ftp, and file. It is useful for validating whether a given
 * string of text represents a valid URL.
 *
 * @param {string} text - The text string to be validated as a URL.
 * @returns {boolean} Returns true if the input text matches the URL pattern, indicating it is a URL; otherwise, returns false.
 */
export const isUrl = (text: string): boolean => {
  let localText = text || '';
  if (!localText) {
    return false;
  }
  if (typeof localText !== 'string') {
    localText = String(localText); // Ensure the input is a string
  }
  const urlPattern =
    /(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:.;,]*[-A-Z0-9+&@#/%=~_|]\b)|(\bwww\.[-A-Z0-9+&@#/%?=~_|!:.;,]*[-A-Z0-9+&@#/%=~_|]\b)/gi;

  return !!localText.match(urlPattern);
};

/**
 * Opening the link with some app, if the URL scheme is "http" the web link should be opened
 * by the browser in the web environment
 * @param url
 */
export const openUrl = url => {
  try {
    // Add 'https://' if URL starts with 'www.'
    let tempUrl = url;
    if (url.startsWith('www.')) {
      tempUrl = `https://${tempUrl}`;
    }

    // Open the URL in a new browser tab
    window.open(tempUrl, '_blank', 'noopener,noreferrer');
  } catch (e) {
    console.error(e);
  }
};

export const isLanguageRTL = (languageCode: string): boolean => {
  const rtlLanguages = [
    'ar', // Arabic
    'fa', // Persian (Farsi)
    'he', // Hebrew
    'ur', // Urdu
    'yi', // Yiddish
  ];
  return rtlLanguages.includes(languageCode);
};

/**
 * Finds a country by id, gender, profession, speciality, and language in the config data.
 *
 * @param {ConfigDataType} configData - The configuration data containing arrays of various types.
 * @param {number} countryId - The id of the country to find.
 * @param {number} genderId - The id of the gender to match.
 * @param {number} professionId - The id of the profession to match.
 * @param {number} specialityId - The id of the speciality to match.
 * @param {number} languageId - The id of the language to match.
 * @returns {(CountryType | undefined)} - The found country or undefined if not found.
 */
export const getUserDetailsByIds = ({
  config: userConfig,
  country_id,
  gender_id,
  profession_id,
  speciality_id,
  language_id,
}: {
  config?: ConfigDataType;
  country_id?: number;
  gender_id?: number;
  profession_id?: string;
  speciality_id?: number;
  language_id?: number;
}): MatchingDataType => {
  const country = userConfig?.Countries?.find(c => c.id === country_id);
  const gender = userConfig?.Genders?.find(g => g.id === gender_id);
  const profession = userConfig?.Professions?.find(
    p => +p.id === +(profession_id || ''),
  );
  const speciality = userConfig?.Specialties?.find(s => s.id === speciality_id);
  const language = userConfig?.Languages?.find(l => l.id === language_id);

  return {country, gender, profession, speciality, language};
};

/**
 * Converts a given date string in any recognized format to YYYY-MM-DD format.
 *
 * @param dateString - The date string to be converted.
 * @returns The date in YYYY-MM-DD format, or null if the input is invalid.
 */
export const convertToYYYYMMDD = (dateString: string): string | null => {
  // Specify a wide range of expected date formats
  const formats = [
    'DD-MM-YYYY',
    'MM-DD-YYYY',
    'YYYY-MM-DD',
    'DD/MM/YYYY',
    'MM/DD/YYYY',
    'YYYY/MM/DD',
    'DD.MM.YYYY',
    'MM.DD.YYYY',
    'YYYY.MM.DD',
    'DD MMM YYYY',
    'MMM DD, YYYY',
    'YYYY MMM DD',
    'YYYY-MM-DDTHH:mm:ss.SSSZ',
    'YYYY-MM-DDTHH:mm:ssZ',
    'YYYY-MM-DDTHH:mm:ss',
    'YYYY-MM-DD HH:mm:ss',
    'YYYY-MM-DDTHH:mm',
    'YYYY-MM-DD HH:mm',
    'YYYY-MM-DDTHH:mm:ss.SSS',
    'DD-MM-YYYYTHH:mm:ss.SSSZ',
    'DD-MM-YYYYTHH:mm:ssZ',
    'DD-MM-YYYYTHH:mm:ss',
    'DD-MM-YYYY HH:mm:ss',
    'DD-MM-YYYYTHH:mm',
    'DD-MM-YYYY HH:mm',
    'DD-MM-YYYYTHH:mm:ss.SSS',
    'DD/MM/YYYYTHH:mm:ss.SSSZ',
    'DD/MM/YYYYTHH:mm:ssZ',
    'DD/MM/YYYYTHH:mm:ss',
    'DD/MM/YYYY HH:mm:ss',
    'DD/MM/YYYYTHH:mm',
    'DD/MM/YYYY HH:mm',
    'DD/MM/YYYYTHH:mm:ss.SSS',
    moment.ISO_8601, // ISO 8601 format
  ];

  const date = moment(dateString, formats, true);

  if (!date.isValid()) {
    return null;
  }

  return date.format('YYYY-MM-DD');
};

/**
 * Checks if a given date string in the format YYYY-MM-DD is valid using moment.js.
 *
 * @param dateString - The date string to be validated.
 * @returns true if the date is valid, false otherwise.
 */
export const isValidDate = (dateString?: string): boolean => {
  if (!dateString) {
    return false;
  }
  // Define the valid formats
  const validFormats = ['YYYY-MM-DD', moment.ISO_8601];
  // Use moment to parse the date and check if it is valid
  return moment(dateString, validFormats, true).isValid();
};

/**
 * Generates an array of all dates between the minimum and maximum dates,
 * excluding dates present in the lastSummaryDates array.
 *
 * @param {string[]} [lastSummaryDates] - Array of dates in ISO string format (e.g., 'YYYY-MM-DD').
 * @returns {{
 *       maxDate: Moment;
 *       minDate: Moment;
 *       completeDateRange: string[];
 *     }} Object containing maxDate, minDate, and an array of dates between min and max, excluding those in lastSummaryDates.
 */
export const generateExclusiveDateRange = (
  lastSummaryDates?: string[],
): {maxDate: Moment; minDate: Moment; completeDateRange: string[]} => {
  // Handle cases where lastSummaryDates is empty, null, or undefined
  if (!Array.isArray(lastSummaryDates) || lastSummaryDates.length === 0) {
    const today = moment();
    return {
      maxDate: today,
      minDate: today,
      completeDateRange: [],
    };
  }

  // Convert all input strings to moment objects and sort them
  const sortedDates = lastSummaryDates
    .map(date => moment(date)) // Parse each date string
    .sort((a, b) => a.valueOf() - b.valueOf()); // Sort in ascending order

  // Get the minimum and maximum dates
  const minDate = sortedDates[0];
  const maxDate = sortedDates[sortedDates.length - 1];

  // Convert lastSummaryDates to a Set for efficient lookup (as strings in 'YYYY-MM-DD')
  const excludedDates = new Set(
    lastSummaryDates.map(date => moment(date).format('YYYY-MM-DD')),
  );

  // Generate all dates between minDate and maxDate
  const completeDateRange: string[] = [];
  const currentDate = minDate.clone();

  while (currentDate.isBefore(maxDate) || currentDate.isSame(maxDate, 'day')) {
    const formattedDate = currentDate.format('YYYY-MM-DD');
    if (!excludedDates.has(formattedDate)) {
      completeDateRange.push(formattedDate);
    }
    currentDate.add(1, 'day'); // Move to the next day
  }

  return {
    maxDate,
    minDate,
    completeDateRange,
  };
};

/**
 * Removes all HTML tags from the given string.
 *
 * @param {string} text - The input string that may contain HTML tags.
 * @returns {string} - The cleaned text without any HTML tags.
 */
export const removeHtmlTags = (text: string): string => {
  if (!text) return '';
  return text.replace(/<[^>]*>/g, ''); // Regular expression to match HTML tags
};

/**
 * Removes filters from the FiltersType object based on the specified array of keys.
 *
 * @param filters - The FiltersType object.
 * @param keysToRemove - An array of keys to be removed from the filters object.
 * @returns {FiltersType} A new FiltersType object with the specified keys removed.
 */
export const removeFilters = (
  filters: FiltersType,
  keysToRemove: string[],
): FiltersType => {
  const updatedFilters = {...filters};
  keysToRemove.forEach(key => {
    if (updatedFilters[key]) {
      delete updatedFilters[key];
    } else {
      if (process.env.REACT_APP_DEBUG === 'true') {
        console.log(`Filter with key "${key}" not found.`);
      }
    }
  });

  return updatedFilters;
};
