import React, {useMemo, useCallback, memo} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {motion} from 'framer-motion';
import styled, {useTheme} from 'styled-components';
import Icon from '../icon';
import {tr} from '../../translation';
import {Theme} from '../../theme/lightTheme';
import AiAssistant from '../aiAssistant';
import {RootState} from '../../redux/store';
import {toggleDrawer} from '../../connected-components/drawer/actions';
import Paths from '../../config/paths';
import defaultImage from '../../assets/images/user.png';

const Container = styled(motion.div)`
  width: 100%;
  z-index: 99;
  overflow: hidden;
  display: flex;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  backdrop-filter: blur(10px);
`;

const ContentContainer = styled.div<{topPadding?: number}>`
  width: 100%;
  padding: ${({topPadding}) => Math.max(topPadding || 0, 24)}px 5% 16px;
  z-index: 2;
`;

const ImageBackground = styled(motion.img)`
  width: 110%;
  height: 110%;
  position: absolute;
  top: -10%;
  left: -5%;
  object-fit: cover;
  overflow: hidden;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  z-index: 1;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
`;

const MenuIconContainer = styled.button`
  background-color: ${({theme}) => theme.text.light};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 4px ${({theme}) => theme.shadow};
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px ${({theme}) => theme.shadow};
  }

  &:active {
    transform: translateY(0);
  }
`;

const ProfileContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  cursor: pointer;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.9;
  }
`;

const ProfileImageContainer = styled(motion.div)`
  border-radius: 50%;
  width: ${({theme}) => `calc(${theme.text.s5} * 2.5)`};
  aspect-ratio: 1;
  overflow: hidden;
  border: 3px solid ${({theme}) => theme.homeBackground};
`;

const ProfileImage = styled(motion.img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ProfileContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.h3`
  font-size: ${({theme}) => theme.text.s7};
  padding: 0 ${({theme}) => theme.text.s7};
  color: ${({theme}) => theme.text.light};
  text-transform: capitalize;
  margin: 0;
`;

const Specialty = styled.p`
  font-size: ${({theme}) => theme.text.s8};
  padding: 0 ${({theme}) => theme.text.s7};
  color: ${({theme}) => theme.text.light + 'CC'};
  margin: 4px 0 0;
`;

const Title = styled(motion.h2)`
  font-size: ${({theme}) => theme.text.s55};
  color: ${({theme}) => theme.text.light};
  margin: 24px 0 0;
`;

const Subtitle = styled(motion.h1)`
  font-size: ${({theme}) => `calc(${theme.text.s3} * 0.9)`};
  color: ${({theme}) => theme.text.light};
  letter-spacing: 1px;
  margin: ${({theme}) => `calc(${theme.text.s3} / 2)`} 0 0;
`;

interface HomeHeaderProps {}

const arePropsEqual = (prevProps: any, nextProps: any) => {
  // Compare currentUser properties that we actually use
  const userEqual =
    prevProps.currentUser?.img === nextProps.currentUser?.img &&
    prevProps.currentUser?.first_name === nextProps.currentUser?.first_name &&
    prevProps.currentUser?.last_name === nextProps.currentUser?.last_name;

  // Compare specialty name only since that's all we use
  const specialtyEqual =
    prevProps.specialty?.name === nextProps.specialty?.name;

  // Compare onClick reference
  const onClickEqual = prevProps.onClick === nextProps.onClick;

  return userEqual && specialtyEqual && onClickEqual;
};

const MemoizedProfile = memo(
  ({
    currentUser,
    specialty,
    onClick,
  }: {
    currentUser: any;
    specialty: any;
    onClick: () => void;
  }) => {
    const [imageError, setImageError] = React.useState(false);

    const handleImageError = () => {
      setImageError(true);
    };

    const imageSource = React.useMemo(() => {
      if (imageError) return defaultImage;
      return currentUser?.img && currentUser.img.startsWith('http')
        ? currentUser.img
        : defaultImage;
    }, [currentUser?.img, imageError]);

    return (
      <ProfileContainer
        onClick={onClick}
        initial={{opacity: 0, x: -20}}
        animate={{opacity: 1, x: 0}}
        transition={{duration: 0.3, ease: 'easeOut'}}>
        <ProfileImageContainer
          initial={{scale: 0.8}}
          animate={{scale: 1}}
          transition={{duration: 0.3, delay: 0.1}}>
          <ProfileImage
            src={imageSource}
            alt="Profile"
            onError={handleImageError}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 0.3, delay: 0.2}}
          />
        </ProfileImageContainer>
        <ProfileContentContainer>
          <Name
            as={motion.h3}
            initial={{opacity: 0, y: 10}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.3, delay: 0.3}}>
            {currentUser?.first_name || ''} {currentUser?.last_name || ''}
          </Name>
          {specialty?.name && (
            <Specialty
              as={motion.p}
              initial={{opacity: 0, y: 10}}
              animate={{opacity: 1, y: 0}}
              transition={{duration: 0.3, delay: 0.4}}>
              {specialty.name}
            </Specialty>
          )}
        </ProfileContentContainer>
      </ProfileContainer>
    );
  },
  arePropsEqual,
);

MemoizedProfile.displayName = 'MemoizedProfile';

const MemoizedMenuButton = memo(
  ({theme, onClick}: {theme: Theme; onClick: () => void}) => (
    <MenuIconContainer onClick={onClick}>
      <Icon
        type={'SVG'}
        name={'menu'}
        color={theme.secondary}
        size={theme.text.s5}
      />
    </MenuIconContainer>
  ),
);

MemoizedMenuButton.displayName = 'MemoizedMenuButton';

const MemoizedWelcomeSection = memo(() => {
  const navigate = useNavigate();

  return (
    <motion.div>
      <Title>{tr('home.welcomeTitle')}</Title>
      <Subtitle>{tr('home.welcomeSubtitle')}</Subtitle>
      <AiAssistant onPress={() => navigate(Paths.content.qna)} />
    </motion.div>
  );
});

MemoizedWelcomeSection.displayName = 'MemoizedWelcomeSection';

const HomeHeader: React.FC<HomeHeaderProps> = () => {
  const navigate = useNavigate();
  const theme = useTheme() as Theme;

  // Memoize selector results
  const authState = useSelector((state: RootState) => state.auth);
  const currentUser = useMemo(
    () => authState?.currentUser || {},
    [authState?.currentUser],
  );
  const config = useMemo(() => authState?.config || {}, [authState?.config]);

  const specialty = useMemo(
    () =>
      (config?.Specialties || []).find(
        (s: any) => s.id === currentUser?.speciality_id,
      ),
    [config?.Specialties, currentUser?.speciality_id],
  );

  const handleProfileClick = useCallback(() => {
    navigate(Paths.content.profile);
  }, [navigate]);

  const handleMenuClick = useCallback(() => {
    toggleDrawer();
  }, []);

  return (
    <Container>
      <ImageBackground
        src={require('../../assets/images/homeHeaderBackground.png')}
        alt="Header background"
        initial={{scale: 5}}
        animate={{scale: 1}}
        transition={{duration: 1.5}}
      />
      <ContentContainer>
        <Row className="top-section">
          <MemoizedProfile
            currentUser={currentUser}
            specialty={specialty}
            onClick={handleProfileClick}
          />
          <MemoizedMenuButton theme={theme} onClick={handleMenuClick} />
        </Row>
        <MemoizedWelcomeSection />
      </ContentContainer>
    </Container>
  );
};

HomeHeader.displayName = 'HomeHeader';

export default memo(HomeHeader);
