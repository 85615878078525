import {StackItem} from '../../interfaces/router/stackItem';
import paths from '../../config/paths';
import React from 'react';

const OnBoarding = React.lazy(() => import('../../screens/auth/onboarding'));

/**
 * An array of onboarding route for a navigation stack.
 * @type {StackItem[]}
 */
const onboardingRouts: StackItem[] = [
  {
    path: paths.auth.onboarding,
    component: OnBoarding,
  },
];

export default onboardingRouts;
