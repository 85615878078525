import React from 'react';
import styled, {useTheme} from 'styled-components';
import {Theme} from '../../../theme/lightTheme';
import {FaPhoneAlt, FaEnvelope} from 'react-icons/fa';
import {tr} from '../../../translation';
import MyPressable from '../../../components/myPressable';
import background from '../../../assets/images/contactusBackground.png';

const Container = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({theme}: {theme: Theme}) => theme.dimensions.hdp(100)}px;
`;
const ContentContainer = styled.div`
  overflow-y: auto;
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${({theme}) => (theme?.isMobile ? '90%' : '60%')};
  padding: 3%;
  margin: 7% auto 5% auto;
  background: linear-gradient(
    360deg,
    ${({theme}) => theme.secondary + '22'} -68.52%,
    ${({theme}) => theme.primary + '22'} 122.02%
  );
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 26px;
  @keyframes fadeInFromBottom {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  animation: fadeInFromBottom 0.5s ease-in-out;
`;
const Title = styled.p`
  font-size: ${({theme}) => theme.text.s5};
  color: ${({theme}) => theme.text.light};
  text-align: left;
  padding-bottom: 0.5rem;
  font-weight: bold;
  align-self: flex-start;
`;

const Line = styled.div`
  height: 5px;
  border-radius: 5px;
  background-color: rgba(60, 183, 103, 0.7);
  width: 30%;
  align-self: flex-start;
`;

const ContactItemsContainer = styled.div`
  padding-top: 5%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TopImage = styled.img`
  width: 45%;
  aspect-ratio: 1.3;
  margin: 0 auto 5% auto;
  opacity: 0.9;
`;

const ItemTitle = styled.p`
  font-size: ${({theme}) => theme.text.s7};
  color: ${({theme}) => theme.text.light};
  font-weight: 600;
  padding-bottom: 0.5rem;
`;
const ItemValue = styled.p`
  font-size: ${({theme}) => theme.text.s7};
  color: ${({theme}) => theme.text.light};
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  padding: 0 1.5rem;
`;
const BlurContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: ${({theme}) => (theme?.isMobile ? '90%' : '60%')};
  background: linear-gradient(
    360deg,
    ${({theme}) => theme.secondary + '22'} -68.52%,
    ${({theme}) => theme.primary + '22'} 122.02%
  );
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 13px;
  padding: 1rem 1.2rem;
  margin-top: 1rem;
  margin-bottom: 8px;
  backdrop-filter: blur(8px);
`;
/**
 * Support screen component.
 */
const Support = () => {
  const theme = useTheme() as Theme;

  const mailUs = () => {
    window.location.href = `mailto:${process.env.REACT_APP_EMAIL}?subject=Support&body=Hello, I need support with...`;
  };

  const callUs = () => {
    window.location.href = `tel:${process.env.REACT_APP_PHONE_NUMBER}`;
  };

  const contactUsListItem = [
    {
      title: tr('contactUs.phoneTitle'),
      value: process.env.REACT_APP_PHONE_NUMBER,
      iconName: 'phone',
      iconType: 'MaterialCommunityIcons',
      label: 'phone',
      onPress: callUs,
    },
    {
      title: tr('contactUs.emailTitle'),
      value: process.env.REACT_APP_EMAIL,
      iconName: 'email',
      iconType: 'MaterialCommunityIcons',
      label: 'email',
      onPress: mailUs,
    },
  ];

  return (
    <Container>
      <ContentContainer>
        <FormContainer>
          <TopImage src={background} alt="Contact Us" />
          <Title>{tr('contactUs.titlePart1')}</Title>
          <Line />
          <ContactItemsContainer>
            {contactUsListItem.map((item, index) => (
              <MyPressable
                onPress={() => item?.onPress()}
                disableRipple
                containerStyle={{
                  width: '100%',
                }}>
                <BlurContainer key={index}>
                  {item?.label === 'phone' ? (
                    <FaPhoneAlt color={theme.text.light} size={theme.text.s3} />
                  ) : (
                    <FaEnvelope color={theme.text.light} size={theme.text.s3} />
                  )}
                  <TextContainer>
                    <ItemTitle>{item.title}</ItemTitle>
                    <ItemValue>{item?.value}</ItemValue>
                  </TextContainer>
                </BlurContainer>
              </MyPressable>
            ))}
          </ContactItemsContainer>
        </FormContainer>
      </ContentContainer>
    </Container>
  );
};

export default Support;
