import {get, set, del, clear, createStore} from 'idb-keyval';

// Create a custom store for our app
const store = createStore('genio-app-store', 'keyval-store');

/**
 * High-performance storage utility using IndexedDB
 * - Uses idb-keyval for maximum performance
 * - Fully type-safe
 * - Promise-based API
 * - ~600 bytes gzipped
 */
export const storage = {
  /**
   * Get a value from storage
   * @template T - Type of the stored value
   * @param {string} key - Storage key
   * @returns {Promise<T | null>} Stored value or null if not found
   *
   * @example
   * const user = await storage.get<User>('currentUser');
   * if (user) console.log(user.name);
   */
  async get<T>(key: string): Promise<T | null> {
    try {
      return await get<T>(key, store);
    } catch (error) {
      console.warn(`Failed to read from storage: ${key}`, error);
      return null;
    }
  },

  /**
   * Set a value in storage
   * @template T - Type of value to store
   * @param {string} key - Storage key
   * @param {T} value - Value to store
   * @returns {Promise<void>}
   *
   * @example
   * await storage.set('preferences', { theme: 'dark' });
   */
  async set<T>(key: string, value: T): Promise<void> {
    try {
      await set(key, value, store);
    } catch (error) {
      console.warn(`Failed to write to storage: ${key}`, error);
      throw error; // Re-throw to let caller handle
    }
  },

  /**
   * Remove a value from storage
   * @param {string} key - Storage key to remove
   * @returns {Promise<void>}
   *
   * @example
   * await storage.remove('tempData');
   */
  async remove(key: string): Promise<void> {
    try {
      await del(key, store);
    } catch (error) {
      console.warn(`Failed to remove from storage: ${key}`, error);
      throw error;
    }
  },

  /**
   * Clear all data from storage
   * @returns {Promise<void>}
   *
   * @example
   * await storage.clear(); // Clear all stored data
   */
  async clear(): Promise<void> {
    try {
      await clear(store);
    } catch (error) {
      console.warn('Failed to clear storage', error);
      throw error;
    }
  },
};
