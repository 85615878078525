import * as React from 'react';

interface SvgProps extends React.SVGProps<SVGSVGElement> {
  fill?: string;
}

const SvgComponent: React.FC<SvgProps> = ({ fill = '#0B0D6F', ...props }) => (
  <svg
    width={29}
    height={42}
    viewBox="0 0 29 42"
    fill="none"
    {...props}>
    <path
      stroke={fill}
      strokeLinecap="round"
      strokeWidth={2}
      d="M27.73 18.652c0 7.3-5.92 13.22-13.22 13.22-7.3 0-13.22-5.92-13.22-13.22"
    />
    <path
      stroke={fill}
      strokeWidth={2}
      d="M7.91 7.641a6.61 6.61 0 0 1 13.22 0v11.01a6.61 6.61 0 0 1-13.22 0V7.641Z"
    />
    <path
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14.52 31.871v8.81M10.11 40.68h8.81M16.72 7.64h4.41M16.72 14.25h4.41"
    />
  </svg>
);

export default SvgComponent;
