import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {getStacksGroups} from '../utils/router';
import authRoutes from './routes/authRoutes';
import paths from '../config/paths';
import contentRoutes from './routes/contentRoutes';
import sharedRoutes from './routes/sharedRoutes';
import Icon from '../components/icon';
import {tr} from '../translation';
import styled, {useTheme} from 'styled-components';
import {Theme} from '../theme/lightTheme';
import afterLoginRoutes from './routes/afterLogin';
import MainLayout from '../screens/mainLayout';
import {isSmallHeight} from '../utils/responsive';
import useDeepLinking from '../utils/hooks/useDeepLinking';
import onboardingRouts from './routes/onboardingRoutes';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100vh;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
  backdrop-filter: blur(5px);
`;
const BlurContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    360deg,
    ${({theme}) => theme.secondary + '11'} -68.52%,
    ${({theme}) => theme.primary + '11'} 122.02%
  );
  border-radius: 26px;
  padding: ${isSmallHeight ? 3 : 5}rem;
  aspect-ratio: 1;
`;
const LoadingText = styled.p`
  padding-top: 1.5rem;
  font-size: ${({theme}) => theme.text.s7};
  font-weight: bold;
  letter-spacing: 1px;
  color: ${({theme}) => theme.text.dark};
`;

export const AppLoading = () => {
  const theme = useTheme() as Theme;
  return (
    <MainLayout>
      <Container>
        <BlurContainer>
          <Icon
            type={'SVG'}
            name={'loader'}
            color={theme.primary}
            size={theme.text.s2}
          />
          <LoadingText>{tr('app.loading')}</LoadingText>
        </BlurContainer>
      </Container>
    </MainLayout>
  );
};

/**
 * Select and render the appropriate router ( 'auth' , 'firstLogin' , 'onboarding' or 'content').
 * If router value is empty, render a loading indicator.
 *
 * @returns {JSX.Element} The JSX element representing the selected router.
 */
const RouteSwitcher = ({
  router,
}: {
  router: 'content' | 'auth' | 'firstLogin' | 'onboarding' | '';
}): JSX.Element => {
  // The custom hook for deep linking
  useDeepLinking();
  return (
    <React.Suspense fallback={<AppLoading />}>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          {getStacksGroups(
            onboardingRouts,
            router === 'content'
              ? paths.content.home
              : router === 'firstLogin'
                ? paths.auth.afterLogin
                : router === 'auth'
                  ? paths.auth.login
                  : '',
          )}
          {getStacksGroups(
            authRoutes,
            router === 'content'
              ? paths.content.home
              : router === 'firstLogin'
                ? paths.auth.afterLogin
                : router === 'onboarding'
                  ? paths.auth.onboarding
                  : '',
          )}
          {getStacksGroups(
            afterLoginRoutes,
            router === 'auth'
              ? paths.auth.login
              : router === 'content'
                ? paths.content.home
                : '',
          )}
          {getStacksGroups(
            contentRoutes,
            router === 'auth'
              ? paths.auth.login
              : router === 'firstLogin'
                ? paths.auth.afterLogin
                : router === 'onboarding'
                  ? paths.auth.onboarding
                  : '',
          )}
          {getStacksGroups(sharedRoutes)}
        </Route>
      </Routes>
    </React.Suspense>
  );
};

export default RouteSwitcher;
