// Cardiology Guidelines
import {basePdfUrl} from '../../../../../api/api';

const guidelinesGlCardiology = basePdfUrl + 'gl/cardiology/';

export const guidelinesCardiologyData = [
  {
    id: 0,
    title: 'Acute Coronary Syndrome',
    pdf_link: guidelinesGlCardiology + 'ACS-guideline.pdf',
  },
  {
    id: 1,
    title: 'Atrial Fibrillation',
    pdf_link: guidelinesGlCardiology + 'Atrial-Fibrillation-Guidelines-.pdf',
  },
  {
    id: 2,
    title: 'Dyslipidemia',
    pdf_link: guidelinesGlCardiology + '3-Dyslipidemia-Guidelines.pdf',
  },
  {
    id: 3,
    title: 'Heart Failure',
    pdf_link: guidelinesGlCardiology + 'HF-Guidelines.pdf',
  },
  {
    id: 4,
    title: 'Hypertension',
    pdf_link: guidelinesGlCardiology + 'Hypertention-Guideline.pdf',
  },
  {
    id: 5,
    title: 'Pulmonary Arterial Hypertension',
    pdf_link:
      guidelinesGlCardiology + 'PULMONARY-ARTERIAL-HYPERTENSION-GUIDELINES.pdf',
  },
  {
    id: 6,
    title: 'Rheumatic Heart Disease',
    pdf_link: guidelinesGlCardiology + 'RHD-recommendation.pdf',
  },
  {
    id: 7,
    title: 'Stroke',
    pdf_link: guidelinesGlCardiology + 'Stroke-Guidelines.pdf',
  },
  {
    id: 8,
    title: 'Thrombosis',
    pdf_link: guidelinesGlCardiology + 'Thrombosis-Guidelines.pdf',
  },
];
