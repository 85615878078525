// Urology Guidelines
import {basePdfUrl} from '../../../../../api/api';

const guidelinesGlUrology = basePdfUrl + 'gl/urology/';

export const guidelinesUrologyData = [
  {
    id: 0,
    title: 'Male Infertility and ED',
    pdf_link: guidelinesGlUrology + '3-Male-Infertility-and-ED-Guidelines.pdf',
  },
  {
    id: 1,
    title: 'Pyelonephritis and Nephrolithiasis',
    pdf_link:
      guidelinesGlUrology +
      '3-Pyelonephritis-and-Nephrolithiasis-Guidelines.pdf',
  },
  {
    id: 2,
    title: 'Benign Prostatic Hyperplasia',
    pdf_link:
      guidelinesGlUrology + 'Benign-Prostatic-Hyperplasia-Guidelines.pdf',
  },
  {
    id: 3,
    title: 'Epididymitis',
    pdf_link: guidelinesGlUrology + 'Epididymitis-Guidelines.pdf',
  },
  {
    id: 4,
    title: 'Overactive Bladder',
    pdf_link: guidelinesGlUrology + 'Overactive-Bladder-Guidelines.pdf',
  },
  {
    id: 5,
    title: 'Prostatitis',
    pdf_link: guidelinesGlUrology + 'Prostatitis-Guidelines.pdf',
  },
  {
    id: 6,
    title: 'Urethritis and Cystitis',
    pdf_link: guidelinesGlUrology + 'Urethritis-and-Cystitis-Guidelines.pdf',
  },
  {
    id: 7,
    title: 'Urinary Incontinence',
    pdf_link: guidelinesGlUrology + 'Urinary-Incontinence-Guidelines-.pdf',
  },
  {
    id: 8,
    title: 'Urinary Retention',
    pdf_link: guidelinesGlUrology + 'Urinary-Retention-Guidelines.pdf',
  },
];
