import {store} from '../../redux/store';
import {showDrawer, hideDrawer} from './reducer';

export const openDrawer = () => {
  store.dispatch(showDrawer());
};

export const closeDrawer = () => {
  store.dispatch(hideDrawer());
};

export const toggleDrawer = () => {
  const {isVisible} = store.getState().drawer;
  if (isVisible) {
    closeDrawer();
  } else {
    openDrawer();
  }
};
