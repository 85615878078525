import React, {SVGProps} from 'react';

const SvgComponent = ({
  width = 48,
  height = 48,
  fill = '#0B0D6F',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      stroke="none"
      strokeWidth={4}
      {...props}>
      <g>
        <path stroke={fill} d="M23.82 3.5v41" />
        <path stroke={fill} d="M16.59 11.983v24.034" />
        <path stroke={fill} d="M9.374 19.759v8.482" />
        <path stroke={fill} d="M31.099 11.983v24.034" />
        <path stroke={fill} d="M38.626 19.759v8.482" />
      </g>
    </svg>
  );
};

export default SvgComponent;
