/**
 * Clean and format raw Markdown-like text from SSE or logs.
 *
 * 1) Unescapes double-escaped newlines (\\n).
 * 2) Unescapes escaped quotes (\\").
 * 3) Removes repeated lines (exact duplicates).
 * 4) Converts real newlines to Markdown line-break syntax (two spaces + \n).
 * 5) Removes surrounding quotes if present.
 *
 * @param {string} raw - The raw text possibly containing escaped sequences or repeated lines.
 * @returns {string} A cleaned, formatted string ready for Markdown rendering.
 */
export const cleanAndFormatMarkdown = (raw: string): string => {
  let text = raw.replace(/\\n/g, '\n');
  text = text.replace(/\\"/g, '"');
  // text = (() => {
  //   const lines = text.split('\n');
  //   const seen = new Set();
  //   const uniqueLines = lines.filter(line => {
  //     const trimmed = line.trim();
  //     if (seen.has(trimmed)) {
  //       return false;
  //     }
  //     seen.add(trimmed);
  //     return true;
  //   });
  //   return uniqueLines.join('\n');
  // })();

  text = text.replace(/\r?\n/g, '  \n ');

  if (text.startsWith('"') && text.endsWith('"')) {
    text = text.slice(1, -1);
  }

  return text;
};
