import React from 'react';
import styled, {css, useTheme} from 'styled-components';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {RootState} from '../../redux/store';
import {NewsItemDetailsType} from '../../interfaces/api/tabs';
import {tr} from '../../translation';
import {getUrlParts, isUrl, openUrl} from '../../utils/helper';
import {renderArticleContent} from '../../utils/html';

const Container = styled.div`
  width: 100%;
  padding: 1rem;
  border: 1px solid ${({theme}) => theme.text.light};
  border-radius: 8px;
`;
const TableRowContainer = styled.div`
  flex: 1;
`;
const TableItemViewContainer = styled.div<any>`
  display: flex;
  flex-direction: row;
  border-bottom: ${({noLine}) => (noLine ? 'none' : '1px solid #fff')};
  padding: 1.2rem 0;
  align-items: center;
  ${({isLanguageRTL}) =>
    isLanguageRTL &&
    css`
      direction: rtl;
    `};
`;

const BoldText = styled.p<any>`
  font-weight: bold;

  color: ${({theme}) => theme.text.light};
  font-size: ${({fontSize}) => fontSize + 1}px;
  line-height: ${({fontSize}) => fontSize * 1.5}px;
  align-self: flex-start;
  text-align: center;
  ${({isLanguageRTL, fontSize}) =>
    isLanguageRTL
      ? css`
          margin-left: ${fontSize * 0.8}px;
        `
      : css`
          margin-right: ${fontSize * 0.8}px;
        `};
`;

const ReadMoreButtonText = styled.p`
  color: ${({theme}) => theme.primary};
  font-weight: bold;
  cursor: pointer;
`;

const ReadMoreText = styled.p<any>`
  color: ${({theme}) => theme.primary};
  font-size: ${({fontSize}) => fontSize}px;
  ${({theme}) =>
    theme.isMobile &&
    `
    display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
    `};
`;

const LabelText = styled.p<any>`
  color: ${({theme}) => theme.text.light};
  font-size: ${({font}) => font?.fontSize}px;
  line-height: ${({font}) => font?.fontSize * 1.5}px;
`;
const LinkText = styled(LabelText)<any>`
  text-decoration: underline;
  cursor: pointer;
  word-break: break-all;
  &:hover {
    opacity: 0.8;
  }
`;

const TableItem = ({
  speciality,
  subspeciality,
  uri,
  publisher,
  resource,
  readabilityscore,
  sentiment,
  accesslevel,
  publishdate,
  typeofstudiesandreaserch,
  paraphrasesummarization,
  origin,
  summary,
  onCustomSeeMorePress,
  isLanguageRTL,
  summarydate,
  tableTranslations,
  specialty,
  subspecialty,
  url,
  type_of_study_value,
  description,
}: NewsItemDetailsType) => {
  const userStatus = useSelector((state: RootState) => state?.auth);
  const {font} = userStatus?.config || {};
  const theme = useTheme();
  const TableItemView = ({title, value, noLine, isReadMoreText}: any) => {
    if (!value) return null;

    return (
      <TableItemViewContainer noLine={noLine} isLanguageRTL={isLanguageRTL}>
        <BoldText
          isLanguageRTL={isLanguageRTL}
          fontSize={font?.fontSize || theme.text.s8}>
          {(title || '').replace('-', '/\n')}
        </BoldText>
        {isReadMoreText && value.length > 20 ? (
          <div style={{flex: 1}}>
            <ReadMoreText fontSize={font?.fontSize || theme.text.s8}>
              {renderArticleContent(value, {
                isRTL: isLanguageRTL,
                color: theme.text.light,
                fontSize: font.fontSize,
                fontWeight: font.fontWeight,
              })}
            </ReadMoreText>
            {theme.isMobile && (
              <ReadMoreButtonText onClick={onCustomSeeMorePress}>
                {tableTranslations?.viewMore || tr('readMoreText.more')}
              </ReadMoreButtonText>
            )}
          </div>
        ) : (
          renderArticleContent(value, {
            isRTL: isLanguageRTL,
            color: theme.text.light,
            fontSize: font.fontSize,
            fontWeight: font.fontWeight,
          })
        )}
      </TableItemViewContainer>
    );
  };

  const detailsMap = [
    {
      title: (tableTranslations?.specialty || 'Specialty') + ':',
      value: specialty,
    },
    {
      title: (tableTranslations?.subspecialty || 'Topic') + ':',
      value: subspecialty,
    },
    {
      title: (tableTranslations?.url || 'Link / Reference') + ':',
      value: url,
    },
    {
      title: `${tableTranslations?.speciality || 'Specialty'}:`,
      value: speciality,
    },
    {
      title: `${tableTranslations?.subspeciality || 'Topic'}:`,
      value: subspeciality,
    },
    {title: `${tableTranslations?.uri || 'Link / Reference'}:`, value: uri},
    {
      title: `${tableTranslations?.publisher || 'Publisher'}:`,
      value: publisher,
    },
    {title: `${tableTranslations?.resource || 'Resource'}:`, value: resource},
    {title: `${tableTranslations?.origin || 'Origin'}:`, value: origin},
    readabilityscore && {
      title: `${tableTranslations?.readabilityscore || 'Readability score'}:`,
      value: parseFloat(readabilityscore).toFixed(2),
    },
    {
      title: `${tableTranslations?.sentiment || 'Sensation'}:`,
      value: sentiment,
    },
    {
      title: `${tableTranslations?.accesslevel || 'Access Level'}:`,
      value: accesslevel,
    },
    (publishdate || summarydate) && {
      title: `${tableTranslations?.date || 'Date'}:`,
      value: moment(publishdate || summarydate).format('YYYY'),
    },
    {
      title: `${tableTranslations?.typeofstudiesandreaserch || 'Type of the study'}:`,
      value: typeofstudiesandreaserch,
    },
    {
      title:
        (tableTranslations?.type_of_study_value || 'Type of the study') + ':',
      value: type_of_study_value,
    },
    {
      title: `${tableTranslations?.summary || 'Summary'}:`,
      value: description || paraphrasesummarization || summary,
      isReadMoreText: true,
      noLine: true,
    },
  ].filter(Boolean);

  return (
    <Container>
      {detailsMap.map((item, index) => (
        <TableItemView
          key={index}
          title={item.title}
          value={item.value}
          isReadMoreText={item.isReadMoreText}
          noLine={item.noLine}
        />
      ))}
    </Container>
  );
};

export const renderUrlParts = ({value, font}: {value?: string; font?: any}) => {
  const parts = getUrlParts(String(value)?.replace(/http/g, '  http') || '');
  return (
    <TableRowContainer>
      {parts.map((part, index) => {
        if (!part?.trim()) return null;

        if (isUrl(part)) {
          return (
            <LinkText font={font} key={index} onClick={() => openUrl(part)}>
              {part}
            </LinkText>
          );
        }

        return (
          <LabelText
            key={index}
            font={font}
            style={{color: '#fff', fontWeight: font?.fontWeight || 'normal'}}>
            {part}
          </LabelText>
        );
      })}
    </TableRowContainer>
  );
};

export default TableItem;
