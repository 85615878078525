import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
  const key = Object.values(props || [])
    .join('')
    .replaceAll('%', '');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={15}
      viewBox={'0 0 18 15'}
      {...props}
      fill="none"
      stroke="none">
      <path fill={`url(#a${key})`} fillOpacity={0.85} d="M0 6h18v3H0z" />
      <path fill={`url(#b${key})`} fillOpacity={0.85} d="M0 0h18v3H0z" />
      <path fill={`url(#c${key})`} fillOpacity={0.85} d="M0 12h18v3H0z" />
      <defs>
        <linearGradient
          id={`a${key}`}
          x1={8.393}
          x2={8.393}
          y1={6}
          y2={9}
          gradientUnits="userSpaceOnUse">
          <stop stopColor={props.fill || '#009F9C'} />
          <stop offset={1} stopColor={props.fill || '#007BB2'} />
        </linearGradient>
        <linearGradient
          id={`b${key}`}
          x1={8.393}
          x2={8.393}
          y1={0}
          y2={3}
          gradientUnits="userSpaceOnUse">
          <stop stopColor={props.fill || '#009F9C'} />
          <stop offset={1} stopColor={props.fill || '#007BB2'} />
        </linearGradient>
        <linearGradient
          id={`c${key}`}
          x1={8.393}
          x2={8.393}
          y1={12}
          y2={15}
          gradientUnits="userSpaceOnUse">
          <stop stopColor={props.fill || '#009F9C'} />
          <stop offset={1} stopColor={props.fill || '#007BB2'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgComponent;
