import {
  CreateThreadResponse,
  GetChatQuestionsResponse,
  GetThreadMessagesResponse,
  GetUserThreadsResponse,
  QNAForm,
  QNAResponse,
} from '../../interfaces/api/qna';
import {api} from '../api';
import http, {handleError} from '../http';
import {endSession} from '../../screens/auth/login/actions';
import {SignatureV4} from '@aws-sdk/signature-v4';
import {Sha256} from '@aws-crypto/sha256-browser';
import {ChatType} from '../../utils/enums';

// Reference to current active stream controller
let activeStreamController: AbortController | null = null;

/**
 * Stops the currently active stream.
 */
export const stopStreamingSSE = () => {
  if (activeStreamController) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.log('Stopping stream...');
    }
    activeStreamController.abort();
    activeStreamController = null;
  }
};

// Helper to parse SSE data
const parseSSEData = (data: string): {event?: string; data?: string} => {
  const result: {event?: string; data?: string} = {};
  // Split by line and process each line
  const lines = data.split('\n').filter(line => line.trim() !== '');
  for (const line of lines) {
    if (line.startsWith('event:')) {
      result.event = line.slice(6).trim();
    } else if (line.startsWith('data:')) {
      result.data = line.slice(5).trim();
    }
  }
  return result;
};

// Helper function to determine if we should use proxy
const shouldUseProxy = (): boolean => {
  // We'll always use the proxy in this implementation
  return true;
};

// Get the appropriate base URL (direct or proxy)
const getBaseUrl = (): string => {
  // // Check if we're in development or production
  // const isDevelopment = process.env.NODE_ENV === 'development';
  // if (isDevelopment) {
  //   // Use local server in development (matching server/serve.js configuration)
  //   return `${window.location.protocol}//${window.location.hostname}:4001`;
  // }
  // // Use production URL in production
  return 'https://genio-article-translate.canguru.dev';
};

export const qna = {
  sendQNA: (body: QNAForm): Promise<QNAResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.qna.ask, body)
        .then(res => resolve(res as QNAResponse))
        .catch(e => reject(e));
    }),
  getUserThreads: (type: ChatType): Promise<GetUserThreadsResponse> =>
    new Promise((resolve, reject) => {
      const url = `${api.qna.userThreads}?type=${type}`;

      http
        .get(url)
        .then(res => resolve(res as GetUserThreadsResponse))
        .catch(e => reject(e));
    }),
  getThreadMessages: (threadId: string): Promise<GetThreadMessagesResponse> =>
    new Promise((resolve, reject) => {
      const url = `${api.qna.threads}/${threadId}&model_id=2`;
      http
        .get(url)
        .then(res => resolve(res as GetThreadMessagesResponse))
        .catch(e => reject(e));
    }),
  createThread: (title: string): Promise<CreateThreadResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.qna.threads, {title})
        .then(res => resolve(res as CreateThreadResponse))
        .catch(e => reject(e));
    }),
  getQuestions: (): Promise<GetChatQuestionsResponse> =>
    new Promise((resolve, reject) => {
      http
        .get(api.qna.questions)
        .then(res => resolve(res as GetChatQuestionsResponse))
        .catch(e => reject(e));
    }),
  sendStreamingQNA: (
    body: QNAForm,
    setThreadId: (id: string) => void,
    setMessage: (text: string) => void,
  ): Promise<boolean> =>
    new Promise(async (resolve, reject) => {
      try {
        let finalUrl: string;
        const headers = new Headers();

        if (shouldUseProxy()) {
          // Using our backend proxy that will handle AWS signing
          const baseUrl = getBaseUrl();
          const urlParams = new URLSearchParams();
          urlParams.append('question', body.question || '');
          urlParams.append('model_id', '2');
          urlParams.append('speciality', body.speciality_id.toString());
          urlParams.append('user_id', body.user_id.toString());
          if (body.thread_id) {
            urlParams.append('thread_id', body.thread_id);
          }
          if (body.type) {
            urlParams.append('type', body.type + '');
          }
          if (body.question_id) {
            urlParams.append('question_id', body.question_id + '');
          }

          finalUrl = `${baseUrl}/api/proxy/qna?${urlParams.toString()}`;

          // Set basic headers
          headers.append('Accept', 'text/event-stream');
          headers.append('Content-Type', 'application/json');
          headers.append(
            'Cache-Control',
            'no-cache, no-store, must-revalidate',
          );
          headers.append('X-Device-OS', 'web');

          if (process.env.REACT_APP_DEBUG === 'true') {
            console.log('Requesting URL:', finalUrl);
            console.log('Headers:', Object.fromEntries(headers.entries()));
          }
        } else {
          // Direct Lambda access with AWS signing
          // 1) Load AWS credentials
          const AWS_REGION = 'eu-central-1';
          const AWS_SERVICE_NAME = 'lambda';

          // 2) Build the base host in lowercase
          let baseLambdaHost =
            'uviujnr5nbp5gm5shtffg325ky0gqxwl.lambda-url.eu-central-1.on.aws';
          baseLambdaHost = baseLambdaHost.toLowerCase();

          // 3) Build query
          const urlParams = new URLSearchParams();
          urlParams.append('question', body.question || '');
          urlParams.append('model_id', '2');
          urlParams.append('speciality', body.speciality_id.toString());
          urlParams.append('user_id', body.user_id.toString());
          if (body.thread_id) {
            urlParams.append('thread_id', body.thread_id);
          }
          if (body.type) {
            urlParams.append('type', body.type + '');
          }
          if (body.question_id) {
            urlParams.append('question_id', body.question_id + '');
          }

          // 4) Create an unsigned request for SigV4
          const unsignedRequest = {
            protocol: 'https:',
            hostname: baseLambdaHost,
            path: '/',
            method: 'GET',
            query: Object.fromEntries(urlParams.entries()) as Record<
              string,
              string
            >,
            headers: {
              host: baseLambdaHost,
              Accept: 'text/event-stream',
              'Cache-Control': 'no-cache, no-store, must-revalidate',
              Pragma: 'no-cache',
              Expires: '0',
              'X-Device-OS': 'web'
            },
          };

          // 5) Sign the request
          const signer = new SignatureV4({
            credentials: {
              accessKeyId: 'AKIA42ZGWJN7FUE3IQG5',
              secretAccessKey: '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',
            },
            region: AWS_REGION,
            service: AWS_SERVICE_NAME,
            sha256: Sha256,
          });
          const signedRequest = await signer.sign(unsignedRequest);

          // 6) Build final URL
          const searchParams = new URLSearchParams();
          // Add all signed query parameters to the URLSearchParams
          if (signedRequest.query) {
            Object.entries(signedRequest.query).forEach(([key, value]) => {
              searchParams.append(
                key,
                typeof value === 'string' ? value : String(value),
              );
            });
          }

          finalUrl = `${signedRequest.protocol}//${signedRequest.hostname}${
            signedRequest.path
          }${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;

          // Create headers from the signed request
          if (signedRequest.headers) {
            Object.entries(signedRequest.headers).forEach(([key, value]) => {
              if (typeof value === 'string') {
                headers.append(key, value);
              }
            });
          }
        }

        // Stop any existing stream before creating a new one
        stopStreamingSSE();

        // Create abort controller to manage the fetch request
        activeStreamController = new AbortController();

        // Add CORS mode to fetch request
        const response = await fetch(finalUrl, {
          method: 'GET',
          headers,
          signal: activeStreamController.signal,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        if (!response.body) {
          throw new Error('ReadableStream not supported in this browser.');
        }

        // Get a reader from the response body
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = '';

        // Process the stream
        const processStream = async (): Promise<void> => {
          try {
            while (true) {
              const {done, value} = await reader.read();

              if (done) {
                // Handle any remaining buffer data
                if (buffer.trim()) {
                  const parsedData = parseSSEData(buffer);
                  if (parsedData.event === 'threadId' && parsedData.data) {
                    setThreadId?.(parsedData.data);
                  } else if (parsedData.data) {
                    setMessage?.(parsedData.data);
                  }
                }
                break;
              }

              // Decode the chunk and add to buffer
              const chunk = decoder.decode(value, {stream: true});
              buffer += chunk;

              // Process complete SSE messages in the buffer
              const messages = buffer.split('\n\n');
              buffer = messages.pop() || ''; // Keep the last incomplete chunk in the buffer

              for (const message of messages) {
                if (message.trim()) {
                  const parsedData = parseSSEData(message);

                  if (parsedData.event === 'threadId' && parsedData.data) {
                    setThreadId?.(parsedData.data);
                  } else if (parsedData.event === 'finished') {
                    stopStreamingSSE();
                    resolve(true);
                    return;
                  } else if (parsedData.data) {
                    setMessage?.(parsedData.data);
                  }
                }
              }
            }

            // If we reach here, the stream ended normally
            stopStreamingSSE();
            resolve(true);
          } catch (streamError) {
            console.error('Stream processing error:', streamError);
            stopStreamingSSE();
            resolve(false);
          }
        };

        // Start processing the stream
        processStream();
      } catch (error: any) {
        process.env.REACT_APP_DEBUG === 'true' &&
          console.error('Get Streaming QNA Error:', error);
        handleError(error);

        if (error?.response?.status === 401) {
          endSession();
        }
        reject(false);
      }
    }),
};
