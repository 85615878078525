import React from 'react';
import styled, {useTheme} from 'styled-components';
import {ChipProps} from '../../interfaces/components/chip';
import Icon from '../icon';
import {Theme} from '../../theme/lightTheme';

const ChipContainer = styled.button<{$isActive?: boolean}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 16px;
  background: ${({theme, $isActive}) =>
    $isActive ? theme.primary + '33' : theme.background};
  border: 1px solid ${({theme}) => theme.border};
  color: ${({theme}) => theme.text.dark};
  font-size: ${({theme}) => theme.text.s7};
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
  transition: all 0.2s ease;
  white-space: nowrap;
  outline: none;

  &:hover:not(:disabled) {
    background: ${({theme}) => theme.primary + '22'};
    transform: translateY(-1px);
  }

  &:active:not(:disabled) {
    transform: translateY(0);
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px ${({theme}) => theme.primary};
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
`;

/**
 * Chip component that behaves like a button
 */
const Chip: React.FC<ChipProps> = ({
  label,
  onPress,
  onClose,
  style,
  className,
  disabled = false,
  isActive = false,
  rightIconName = 'close',
  rightIconType = 'SVG',
  rightIconColor,
  rightIconSize = 16,
}) => {
  const theme = useTheme() as Theme;

  const handlePress = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!disabled && onPress) {
      onPress();
    }
  };

  const handleClose = () => {
    if (!disabled && onClose) {
      onClose();
    }
  };

  return (
    <ChipContainer
      className={className}
      style={style}
      onClick={handlePress}
      disabled={disabled}
      $isActive={isActive}
      role="button"
      aria-disabled={disabled}
      aria-pressed={isActive}>
      {label}
      {rightIconName && (
        <IconContainer>
          <Icon
            name={rightIconName}
            type={rightIconType}
            color={rightIconColor || theme.text.grey}
            size={rightIconSize}
            onPress={handleClose}
            role="button"
          />
        </IconContainer>
      )}
    </ChipContainer>
  );
};

export default Chip;
