// Neurology Treatment Options
import {basePdfUrl} from '../../../../../api/api';

const treatmentOptionsToNeurology = basePdfUrl + 'to/neurology/';

export const treatmentOptionsNeurologyData = [
  {
    id: 0,
    title: 'Dementia',
    pdf_link: treatmentOptionsToNeurology + '2-Dementia-Treatment-Options.pdf',
  },
  {
    id: 1,
    title: "Parkinson's Disease",
    pdf_link:
      treatmentOptionsToNeurology +
      '2-Parkinsons-Disease-Treatment-Options.pdf',
  },
  {
    id: 2,
    title: 'Autism Spectrum Disorder',
    pdf_link:
      treatmentOptionsToNeurology + 'Autism-Spectrum-Disorder-Treatment.pdf',
  },
  {
    id: 3,
    title: 'Brain Tumor in Adults',
    pdf_link:
      treatmentOptionsToNeurology +
      'Brain-Tumor-in-Adults-Treatment-Option.pdf',
  },
  {
    id: 4,
    title: 'Epilepsy',
    pdf_link: treatmentOptionsToNeurology + 'Epilepsy-Treatment-Options.pdf',
  },
  {
    id: 5,
    title: 'Guillain-Barre Syndrome',
    pdf_link:
      treatmentOptionsToNeurology +
      'Guillain-Barre-Syndrome-Treatment-Options.pdf',
  },
  {
    id: 6,
    title: 'Headaches',
    pdf_link: treatmentOptionsToNeurology + 'Headaches-Treatment-Options.pdf',
  },
  {
    id: 7,
    title: 'Migraine',
    pdf_link: treatmentOptionsToNeurology + 'Migraine-Treatment-Options.pdf',
  },
  {
    id: 8,
    title: 'Multiple Sclerosis',
    pdf_link:
      treatmentOptionsToNeurology + 'Multiple-Sclerosis-Treatment-Options.pdf',
  },
  {
    id: 9,
    title: 'Peripheral Neuropathy',
    pdf_link:
      treatmentOptionsToNeurology +
      'Peripheral-Neuropathy-Treatment-Options.pdf',
  },
];
