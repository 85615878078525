import React, {SVGProps} from 'react';

const SvgComponent = ({
  fill = '#2823F5',
  stroke = '#fff',
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={49}
    height={50}
    viewBox="0 0 49 50"
    {...props}
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill={fill}
      d="M24.39 49.15c13.316 0 24.11-10.795 24.11-24.11C48.5 11.724 37.706.93 24.39.93 11.075.93.28 11.724.28 25.04c0 13.315 10.795 24.11 24.11 24.11Z"
    />
    <path
      fill={stroke}
      d="m33.33 33.5.15-17.55c-4.39.04-13.15.1-17.55.15l-.04 2.2c3.95-.04 9.2-.07 13.82-.11l-14.4 14.4 1.54 1.54 14.4-14.4-.12 13.81 2.21-.02-.01-.02Z"
    />
  </svg>
);

export default SvgComponent;
