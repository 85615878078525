import {store} from '../../../redux/store';
import {
  dismissHomeTabsInstructions,
  setFeatures,
  setMainHomeTabs,
  setTabs,
} from './reducer';
import {TabItemType} from '../../../interfaces/api/content/home';
import isEqual from 'lodash/isEqual';
import {
  HomeTabsState,
  HomeFeatureItem,
} from '../../../interfaces/redux/homeTabs';
import {
  staticCalculatorTab,
  staticLaboratoryValuesTab,
} from '../../../assets/static/tabsData';
import {homePopularTopicsTabs} from '../../../assets/static/homeTabs';
import {showGlobalToast} from '../../../connected-components/toast/actions';
import {tr} from '../../../translation';
import {keys} from '../../../api/keys';
import {storage} from '../../../utils/storage';

export const initHomeTabs = async (tabs: TabItemType[]) => {
  // Retrieve existing tabs data from local storage
  const data = await storage.get<HomeTabsState>(keys.SORT_TABS);
  const existingTabsMap = new Map<number, TabItemType>();

  // Populate the map for quick lookups of existing tabs by id
  (data?.tabs || []).forEach(tab => {
    existingTabsMap.set(tab.id, tab);
  });

  const resultTabs: TabItemType[] = [];

  if (!data?.tabs) {
    tabs = [...tabs, staticLaboratoryValuesTab, staticCalculatorTab];
  }

  // Iterate over the backend tabs
  tabs.forEach(newTab => {
    const existingTab = existingTabsMap.get(newTab.id);

    if (existingTab) {
      if (!isEqual(newTab, existingTab)) {
        // If the backend tab has changes, update it with new properties
        resultTabs.push({
          ...existingTab,
          ...newTab,
          localRank: existingTab.localRank ?? newTab.rank,
        });
      } else {
        // If the backend tab matches the local tab exactly, keep the existing one
        resultTabs.push(existingTab);
      }
    } else {
      // If the tab is new, add it with its default rank as localRank
      resultTabs.push({
        ...newTab,
        localRank: newTab.rank,
      });
    }
  });

  // Include tabs stored in local storage but not received from the backend
  const localTabs = (data?.tabs || []).filter(
    localTab => !tabs.some(backendTab => backendTab.id === localTab.id),
  );

  // Add local custom tabs (e.g., tabs with `customTabBody`) or standalone tabs
  localTabs.forEach(localTab => {
    if (!resultTabs.some(tab => tab.id === localTab.id)) {
      resultTabs.push(localTab);
    }
  });

  // Sort resultTabs based on localRank if present, otherwise by rank
  resultTabs.sort((a, b) => {
    const aOrder = a.localRank !== undefined ? a.localRank : a.rank || 0;
    const bOrder = b.localRank !== undefined ? b.localRank : b.rank || 0;
    return aOrder - bOrder;
  });

  await initHomeFeatures();
  // Dispatch the updated tabs to the store and save to local storage
  store.dispatch(setTabs(resultTabs));
};

/**
 * Adds a new tab item to the existing tabs list.
 */
export const addNewsTab = async (newTab: TabItemType): Promise<void> => {
  const {tabs} = store.getState().homeTabs;

  // Check if the tab with the same ID already exists
  const isDuplicate = tabs.some(tab => tab.id === newTab.id);
  if (isDuplicate) {
      showGlobalToast({
        message: tr('addNewsTab.duplicateError'),
        type: 'error',
      })
    return;
  }

  try {
    // Add the new tab and assign localRank
    const updatedTabs = [...tabs, {...newTab, localRank: tabs.length + 1}];

    // Sort tabs based on localRank or rank
    updatedTabs.sort((a, b) => {
      const aOrder = a.localRank !== undefined ? a.localRank : a.rank || 0;
      const bOrder = b.localRank !== undefined ? b.localRank : b.rank || 0;
      return aOrder - bOrder;
    });

    // Dispatch the updated tabs to the store and save to local storage
    store.dispatch(setTabs(updatedTabs));
    storage.set(keys.SORT_TABS, {tabs: updatedTabs});

      showGlobalToast({
        message: tr('addNewsTab.success'),
        type: 'success',
      });
  } catch (error) {
      showGlobalToast({
        message: tr('addNewsTab.error'),
        type: 'error',
      });
    throw error;
  }
};

/**
 * Delete a tab item from tabs list.
 */
export const deleteNewsTab = (id: number): void => {
  const {tabs} = store.getState().homeTabs;
  const newTabsList = tabs.filter(i => i.id !== id);

  // Dispatch the new tabs to the store and save to local storage
  store.dispatch(setTabs(newTabsList));
  storage.set(keys.SORT_TABS, {tabs: newTabsList});
};

/**
 * Save Tabs Order screen.
 */
export const saveTabsOrder = async (tabs: TabItemType[]) => {
  const data = store.getState().homeTabs;
  storage.set(keys.SORT_TABS, {...data, tabs});

  if (tabs) {
    store.dispatch(setTabs(tabs));
  }
};

/**
 * Initialize Instructions screen state.
 */
export const initInstructionsScreen = async () => {
  const homeTabsData = await storage.get<{isInstructionsSeen: boolean}>(
    keys.SORT_TABS,
  );
  if (homeTabsData?.isInstructionsSeen) {
    store.dispatch(dismissHomeTabsInstructions());
  }
};

/**
 * Hide Instructions screen.
 */
export const hideInstructionsScreen = () => {
  const data = store.getState().homeTabs;
  storage.set(keys.SORT_TABS, {...data, isInstructionsSeen: true});
  store.dispatch(dismissHomeTabsInstructions());
};

/**
 * Initialize home features from localStorage.
 */
export const initHomeFeatures = async (): Promise<void> => {
  const homeFeatures = await storage.get<HomeFeatureItem[]>(keys.HOME_FEATURES);
  if (Array.isArray(homeFeatures)) {
    store.dispatch(setFeatures(homeFeatures));
  }
};

/**
 * Update home features.
 */
export const setNewFeatures = (features: HomeFeatureItem[]): void => {
  store.dispatch(setFeatures(features));
  storage.set(keys.HOME_FEATURES, features);
};

/**
 * Initialize main home tabs.
 */
export const initMainHomeTabs = async () => {
  const mainHomeTabs = await storage.get<TabItemType[]>(keys.HOME_MAIN_TABS);
  if (Array.isArray(mainHomeTabs)) {
    store.dispatch(setMainHomeTabs(mainHomeTabs));
  } else {
    store.dispatch(setMainHomeTabs(homePopularTopicsTabs));
  }
};

/**
 * Save main home tabs.
 */
export const saveMainHomeTabs = async (mainHomeTabs: TabItemType[]) => {
  store.dispatch(setMainHomeTabs(mainHomeTabs));
  await storage.set(keys.HOME_MAIN_TABS, mainHomeTabs);
};
