import Error from './list/error';
import NotFound from './list/notFound';
import HomeIcon from './list/headerTabs/homeIcon';
import MyNewsIcon from './list/headerTabs/myNewsIcon';
import NotesIcon from './list/headerTabs/notesIcon';
import ProfileIcon from './list/headerTabs/profileIcon';
import Check from './list/check';
import ErrorField from './list/errorField';
import Facebook from './list/social/facebook';
import Google from './list/social/google';
import Apple from './list/social/apple';
import Logo from './list/logo';
import Calendar from './list/calendar';
import ArrowDown from './list/arrowDown';
import ViewAll from './list/viewAll';
import Loader from './list/loader';
import Empty from './list/empty';
import Pause from './list/pause';
import Record from './list/record';
import Filters from './list/filters';
import FiltersSolid from './list/filtersSolid';
import ArrowTop from './list/arrowTop';
import DottedLine from './list/dottedLine';
import Close from './list/close';
import ClearFilters from './list/clearFilters';
import TriangleDown from './list/triangleDown';
import MultitrackAudio from './list/multitrackAudio';
import Search from './list/search';
import Heart from './list/heart';
import Hearto from './list/hearto';
import Add from './list/add';
import MiniMenu from './list/miniMenu';
import Languages from './list/drawer/languages';
import Logout from './list/drawer/logout';
import Policy from './list/drawer/policy';
import Privacy from './list/drawer/privacy';
import Settings from './list/drawer/settings';
import Stars from './list/drawer/stars';
import Support from './list/drawer/support';
import Terms from './list/drawer/terms';
import TopicsDrawer from './list/drawer/topicsDrawer';
import Home from './list/drawer/home';
import AiNews from './list/aiNews';
import LogoChar from './list/logoChar';
import Menu from './list/menu';
import BackgroundHeaderTab from './list/headerTabs/backgroundHeaderTab';
import Arrows from './list/arrows';
import PlayerPause from './list/audioPlayer/playerPause';
import PlayerPlay from './list/audioPlayer/playerPlay';
import Info from './list/info';
import Subtract from './list/subtract';
import Share from './list/share';
import Circle from './list/circle';
import CircleProgress from './list/circleProgress';
import SeeAllButton from './list/seeAllButton';
import Publisher from './list/publisher';
import HomeMic from './list/homeMic';
import NavArrow from './list/navArrow';
import User from './list/user';
import CheckCircle from './list/checkCircle';
import Lock from './list/lock';
import OnboardingLogo from './list/onboardingLogo';
import Mic from './list/headerTabs/mic';
import Favorite from './list/headerTabs/favorite';
import Account from './list/headerTabs/account';

const icons = {
  home: HomeIcon,
  myNews: MyNewsIcon,
  notes: NotesIcon,
  profile: ProfileIcon,
  error: Error,
  notFound: NotFound,
  check: Check,
  errorField: ErrorField,
  facebook: Facebook,
  google: Google,
  apple: Apple,
  logo: Logo,
  calendar: Calendar,
  arrowDown: ArrowDown,
  viewAll: ViewAll,
  loader: Loader,
  empty: Empty,
  pause: Pause,
  record: Record,
  filters: Filters,
  filtersSolid: FiltersSolid,
  arrowTop: ArrowTop,
  dottedLine: DottedLine,
  close: Close,
  clearFilters: ClearFilters,
  triangleDown: TriangleDown,
  multitrackAudio: MultitrackAudio,
  search: Search,
  heart: Heart,
  hearto: Hearto,
  add: Add,
  miniMenu: MiniMenu,
  languages: Languages,
  logout: Logout,
  policy: Policy,
  privacy: Privacy,
  settings: Settings,
  stars: Stars,
  support: Support,
  terms: Terms,
  topicsDrawer: TopicsDrawer,
  homeDrawer: Home,
  aiNews: AiNews,
  logoChar: LogoChar,
  menu: Menu,
  tabBackground: BackgroundHeaderTab,
  arrows: Arrows,
  playerPause: PlayerPause,
  playerPlay: PlayerPlay,
  info: Info,
  subtract: Subtract,
  share: Share,
  circle: Circle,
  circleProgress: CircleProgress,
  seeAllButton: SeeAllButton,
  publisher: Publisher,
  homeMic: HomeMic,
  navArrow: NavArrow,
  user: User,
  checkCircle: CheckCircle,
  lock: Lock,
  onboardingLogo: OnboardingLogo,
  mic: Mic,
  favorite: Favorite,
  account: Account,
};

export default icons;
