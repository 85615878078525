// Neurology
import {basePdfUrl} from '../../../../../api/api';

const diagnosticToolsDtNeurology = basePdfUrl + 'dt/neurology/';

export const diagnosticToolsNeurologyData = [
  {
    id: 0,
    title: 'Dementia',
    pdf_link: diagnosticToolsDtNeurology + '1-Dementia-Diagnostic-Tools.pdf',
  },
  {
    id: 1,
    title: 'Parkinsons Disease',
    pdf_link:
      diagnosticToolsDtNeurology + '1-Parkinsons-Disease-Diagnostic-Tools.pdf',
  },
  {
    id: 2,
    title: 'Autism Spectrum Disorder',
    pdf_link:
      diagnosticToolsDtNeurology + 'Autism-spectrum-disorder-Diagnosis.pdf',
  },
  {
    id: 3,
    title: 'Brain Tumor in Adults',
    pdf_link:
      diagnosticToolsDtNeurology + 'Brain-Tumor-In-Adults-Diagnosis.pdf',
  },
  {
    id: 4,
    title: 'Epilepsy',
    pdf_link: diagnosticToolsDtNeurology + 'Epilepsy-Diagnosis.pdf',
  },
  {
    id: 5,
    title: 'Guillain-Barre Syndrome',
    pdf_link:
      diagnosticToolsDtNeurology + 'Guillain-Barre-Syndrome-Diagnosis.pdf',
  },
  {
    id: 6,
    title: 'Headache',
    pdf_link: diagnosticToolsDtNeurology + 'Headache-diagnosis.pdf',
  },
  {
    id: 7,
    title: 'Migraine',
    pdf_link: diagnosticToolsDtNeurology + 'Migraine-Diagnosis.pdf',
  },
  {
    id: 8,
    title: 'Multiple Sclerosis',
    pdf_link: diagnosticToolsDtNeurology + 'Multiple-Sclerosis-Diagnosis.pdf',
  },
  {
    id: 9,
    title: 'Peripheral Neuropathy',
    pdf_link:
      diagnosticToolsDtNeurology + 'Peripheral-Neuropathy-Diagnosis.pdf',
  },
];
