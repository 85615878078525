// Gastroenterology Treatment Options
import {basePdfUrl} from '../../../../../api/api';

const treatmentOptionsToGastroenterology = basePdfUrl + 'to/gastroenterology/';

export const treatmentOptionsGastroenterologyData = [
  {
    id: 0,
    title: 'Diarrhea and Constipation',
    pdf_link:
      treatmentOptionsToGastroenterology +
      '2-Diarrhea-and-constipation-treatment.pdf',
  },
  {
    id: 1,
    title: 'Esophagitis and Gastritis',
    pdf_link:
      treatmentOptionsToGastroenterology +
      '2-Esophagitis-and-Gastritis-Treatment-Options.pdf',
  },
  {
    id: 2,
    title: 'Pancreatitis',
    pdf_link:
      treatmentOptionsToGastroenterology + '2-Pancreatitis-treatment.pdf',
  },
  {
    id: 3,
    title: 'Celiac Disease',
    pdf_link:
      treatmentOptionsToGastroenterology +
      'Celiac-Disease-Treatment-Options.pdf',
  },
  {
    id: 4,
    title: 'Functional Dyspepsia',
    pdf_link:
      treatmentOptionsToGastroenterology +
      'Functional-Dyspepsia-Treatment-Options.pdf',
  },
  {
    id: 5,
    title: 'Gastroesophageal Reflux Disease',
    pdf_link:
      treatmentOptionsToGastroenterology + 'GERD-Treatment-Options-.pdf',
  },
  {
    id: 6,
    title: 'Gallstones and Cholecystitis',
    pdf_link:
      treatmentOptionsToGastroenterology +
      'Gallstones-and-Cholecystitis-Treatment-Options.pdf',
  },
  {
    id: 7,
    title: 'Gastroparesis',
    pdf_link:
      treatmentOptionsToGastroenterology +
      'Gastroparesis-Treatment-Options.pdf',
  },
  {
    id: 8,
    title: 'Hepatitis and Cirrhosis',
    pdf_link:
      treatmentOptionsToGastroenterology +
      'Hepatitis-and-Cirrhosis-Treatment-Options.pdf',
  },
  {
    id: 9,
    title: 'Inflammatory Bowel Disease',
    pdf_link:
      treatmentOptionsToGastroenterology +
      'Inflammatory-Bowel-Disease-Treatment.pdf',
  },
  {
    id: 10,
    title: 'Irritable Bowel Syndrome',
    pdf_link:
      treatmentOptionsToGastroenterology +
      'Irritable-Bowel-Syndrome-Treatment-Options.pdf',
  },
  {
    id: 11,
    title: 'Peptic Ulcer Disease',
    pdf_link:
      treatmentOptionsToGastroenterology +
      'Peptic-Ulcer-Disease-Treatment-Options.pdf',
  },
];
