import React from 'react';
import styled, {useTheme} from 'styled-components';
import {motion} from 'framer-motion';
import {Theme} from '../../theme/lightTheme';
import Icon from '../icon';
import {tr} from '../../translation';
import {AiAssistantProps} from '../../interfaces/components/aiAssistant';

const Container = styled.div<{$showBackground?: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  min-height: ${({$showBackground}) => ($showBackground ? '200px' : 'auto')};
  border-radius: 16px;
  @media (max-width: 768px) {
    padding: 16px;
    min-height: ${({$showBackground}) => ($showBackground ? '150px' : 'auto')};
  }
`;

const SearchIcon = styled.img<{$showBackground?: boolean}>`
  width: ${({$showBackground}) => ($showBackground ? '100px' : '150px')};
  aspect-ratio: 1;
  object-fit: contain;
  transition: transform 0.3s ease;

  @media (max-width: 768px) {
    width: ${({$showBackground}) => ($showBackground ? '80px' : '120px')};
  }
`;

const ImageBackground = styled(motion.img)`
  width: 110%;
  height: 110%;
  position: absolute;
  top: -5%;
  left: -5%;
  object-fit: cover;
  z-index: 0;
  border-radius: 16px;
`;

const AssistantText = styled.p`
  font-size: ${({theme}) => theme.text.s6};
  padding: 0 ${({theme}) => theme.text.s8};
  color: ${({theme}) => theme.text.light};
  margin: 0;
  white-space: pre-line;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
`;

const AssistantContainer = styled.button<{$showBackground?: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${({$showBackground}) => ($showBackground ? '0' : '20px')};
  flex: 1;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.7;
  }
`;

/**
 * AiAssistant component for web interface.
 *
 * @param {AiAssistantProps} props - The component props
 * @returns {JSX.Element} The rendered AiAssistant component
 */
const AiAssistant: React.FC<AiAssistantProps> = ({
  style,
  onPress,
  showBackground,
}: AiAssistantProps): JSX.Element => {
  const theme = useTheme() as Theme;

  return (
    <Container style={style} $showBackground={showBackground}>
      {showBackground && (
        <ImageBackground
          src={require('../../assets/images/homeHeaderBackground.png')}
          alt="Background"
          initial={{scale: 1.2, opacity: 0}}
          animate={{scale: 1, opacity: 1}}
          transition={{duration: 0.5}}
        />
      )}
      <AssistantContainer
        $showBackground={showBackground}
        onClick={onPress}
        aria-label={tr('home.assistant')}>
        <Icon
          type={'SVG'}
          name={'homeMic'}
          size={theme.text.s2}
          color={theme.text.light}
        />
        <AssistantText>{tr('home.assistant')}</AssistantText>
      </AssistantContainer>
      <SearchIcon
        $showBackground={showBackground}
        src={require('../../assets/images/search.png')}
        alt="Search"
      />
    </Container>
  );
};

export default AiAssistant;
