import {store} from '../../../redux/store';
import {
  setChat,
  setChatIsFail,
  setChatIsLoading,
  setChatModels,
  setChatQuestions,
  setChatQuestionsFail,
  setChatQuestionsLoading,
  setCurrentThreadId,
  setMessageIsFail,
  setMessageIsLoading,
  setMessageSuccess,
  setStreamMessageLoadingId,
  setTypingStatus,
  setUserThreads,
  setUserThreadsFail,
  setUserThreadsLoading,
} from './reducer';
import {
  MessageItem,
  SendMessageWithImageParams,
} from '../../../interfaces/reducers/chat';
import {finishLoading} from '../../../connected-components/loading/actions';
import {ChatType, MessageStatus} from '../../../utils/enums';
import {services} from '../../../api';
import {QNAForm, ThreadItemType} from '../../../interfaces/api/qna';
import {throttle} from 'lodash';
import {cleanAndFormatMarkdown} from '../../../utils/markdownHelpers';
import {sleep} from '../../../utils/helper';
import moment from 'moment';

const IS_CHAT_DEBUGGING = false;

export const getUserThreads = async (type: ChatType): Promise<void> => {
  try {
    store.dispatch(setUserThreadsLoading());
    const res = await services.qna.getUserThreads(type);
    if (!res) {
      throw new Error('');
    }
    if (res?.data?.threads) {
      store.dispatch(setUserThreads((res?.data?.threads || []).reverse()));
    }
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true' && IS_CHAT_DEBUGGING) {
      console.info('GET USER THREADS FAIL: ', error);
    }
    store.dispatch(setUserThreadsFail());
  }
};

const removeDuplicates = (array: any[]): any[] => {
  return Object.values(
    array.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {}),
  );
};

export const addUserThread = async (
  newThread: ThreadItemType,
): Promise<void> => {
  try {
    const {threads: oldThreads} = store.getState().chat || {};
    const threads = [newThread, ...oldThreads];
    if (threads) {
      store.dispatch(setUserThreads(removeDuplicates(threads)));
    }
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true' && IS_CHAT_DEBUGGING) {
      console.info('ADD USER THREADS FAIL: ', error);
    }
  }
};
export const getChatQuestions = async (): Promise<void> => {
  try {
    store.dispatch(setChatQuestionsLoading());

    const res = await services.qna.getQuestions();
    if (!res) {
      throw new Error('');
    }
    if (res?.data?.questions) {
      store.dispatch(setChatQuestions(res?.data?.questions || []));
    }
    if (res?.data?.models) {
      store.dispatch(setChatModels(res?.data?.models || []));
    }
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true' && IS_CHAT_DEBUGGING) {
      console.info('GET CHAT QUESTIONS FAIL: ', error);
    }
    store.dispatch(setChatQuestionsFail());
  }
};

export const getThreadMessages = async (
  threadId: string,
  refresh: boolean = false,
): Promise<boolean> => {
  try {
    const {id, first_name, last_name} =
      store.getState()?.auth?.currentUser || {};
    const tempData = store.getState().chat;

    if ((!refresh && tempData?.isLastPage) || tempData.threadId === threadId) {
      return false;
    }

    if (refresh) {
      const isForceReset =
        tempData.data.length > 0 && tempData.data[0].thread_id !== threadId;
      if (isForceReset) {
        store.dispatch(setChat({data: []}));
      }
    }
    store.dispatch(setChatIsLoading());
    const res = await services.qna.getThreadMessages(threadId);
    if (!res) {
      throw new Error('');
    }
    if (res?.data?.thread?.id) {
      store.dispatch(setCurrentThreadId(res?.data?.thread?.id));
    }

    let data = [...(tempData?.data || [])];
    const newData = [...(res?.data?.messages || [])]
      .map(i => {
        return {
          message: i.content || '',
          sender_id: i.user_id + '',
          sender_name:
            i.user_id === id ? first_name + ' ' + last_name : 'GENIO',
          thread_id: i.thread_id || '',
          status: MessageStatus.ReadByEveryone,
          created_at: new Date(i.created_at).toISOString(),
        };
      })
      .reverse();

    if (refresh) {
      data = newData;
    } else {
      data = [...data, ...newData];
    }

    store.dispatch(setChat({data}));
    store.dispatch(setMessageSuccess());

    return true;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true' && IS_CHAT_DEBUGGING) {
      console.info('GET THREAD MESSAGES FAIL: ', error);
    }

    store.dispatch(setChatIsFail());
    return false;
  }
};

export const getLastMessageByUser = (
  messages: MessageItem[],
): MessageItem | null => {
  const {id: userId} = store.getState()?.auth?.currentUser || {};
  if (!userId || !messages || messages.length === 0) {
    return null;
  }
  const userMessages = messages
    .filter(message => message.sender_id + '' == userId + '')
    .sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );

  return userMessages.length > 0 ? userMessages[0] : null;
};

export const sendMessage = async ({
  question,
  question_id,
  tryAgain = false,
  scrollToEnd,
  type,
}: SendMessageWithImageParams): Promise<boolean> => {
  try {
    const {
      id: userId,
      first_name,
      last_name,
      speciality_id,
    } = store.getState()?.auth?.currentUser || {};
    const {threadId, data} = store.getState().chat;
    let tempData = [...(data || [])];

    store.dispatch(setMessageIsLoading());
    if (!question) {
      throw new Error('Question text must be provided');
    }

    const newMessage: MessageItem = {
      id: new Date().toISOString(),
      message: question || '',
      sender_id: userId + '',
      sender_name: first_name + ' ' + last_name,
      thread_id: threadId + '',
      status: MessageStatus.ReadByEveryone,
      created_at: new Date().toISOString(),
    };

    if (!tryAgain) {
      tempData = [newMessage, ...tempData];
      store.dispatch(setChat({data: tempData}));
    }
    scrollToEnd?.();
    store.dispatch(
      setTypingStatus({status: {userId: 'genio', isTyping: true}}),
    );

    const body: QNAForm = {
      question: question || '',
      question_id: question_id,
      speciality_id: speciality_id || -1,
      type,
      user_id: userId || -1,
      thread_id: threadId,
    };

    const messageId = moment().add(1, 'minute').toISOString();
    store.dispatch(setStreamMessageLoadingId(messageId));

    // Throttle chat store updates
    const throttledDispatch = throttle(
      (updatedMessages: string) => {
        store.dispatch(
          setChat({
            data: [
              {
                message: cleanAndFormatMarkdown(updatedMessages || ''),
                sender_id: 'genio',
                sender_name: 'GENIO',
                thread_id: threadId + '',
                status: MessageStatus.ReadByEveryone,
                created_at: new Date().toISOString(),
                id: messageId,
              },
              ...tempData,
            ],
          }),
        );
      },
      100,
      {
        leading: true,
        trailing: true,
      },
    );

    if (process.env.REACT_APP_DEBUG !== 'true') {
      await sleep(1500);
    }
    let prevText = '';
    let accumulatedText = '';
    let num = 0;

    const res = await services.qna.sendStreamingQNA(
      body,
      newThreadId => {
        if (!threadId && newThreadId) {
          addUserThread({
            id: newThreadId,
            title: question || '',
            created_at: new Date().toISOString(),
            user_id: userId,
            creator_username: '',
            updated_at: new Date().toISOString(),
          });
          store.dispatch(setCurrentThreadId(newThreadId));
        }
      },
      async accumulatedAnswer => {
        if (prevText.length - accumulatedText.length > 40) {
          accumulatedText = accumulatedAnswer;
          num += 1;
          store.dispatch(
            setChat({
              data: [
                {
                  message: cleanAndFormatMarkdown(accumulatedAnswer || ''),
                  sender_id: 'genio',
                  sender_name: 'GENIO',
                  thread_id: threadId + '',
                  status: MessageStatus.ReadByEveryone,
                  created_at: new Date().toISOString(),
                  id: messageId,
                },
                ...tempData,
              ],
            }),
          );
          if (num === 1) {
            store.dispatch(
              setTypingStatus({status: {userId: 'genio', isTyping: false}}),
            );
          }
        }
        prevText = accumulatedAnswer;
      },
    );
    store.dispatch(
      setChat({
        data: [
          {
            message: cleanAndFormatMarkdown(prevText || ''),
            sender_id: 'genio',
            sender_name: 'GENIO',
            thread_id: threadId + '',
            status: MessageStatus.ReadByEveryone,
            created_at: new Date().toISOString(),
            id: messageId,
          },
          ...tempData,
        ],
      }),
    );

    // Flush any remaining updates after the loop
    throttledDispatch.flush();

    if (!res) {
      store.dispatch(setMessageIsFail());
      throw new Error('Error inserting message: ');
    }
    store.dispatch(setMessageSuccess());
    return true;
  } catch (error) {
    if (process.env.REACT_APP_DEBUG === 'true' && IS_CHAT_DEBUGGING) {
      console.log('ERROR SENDING MESSAGE', error);
    }
    return false;
  } finally {
    store.dispatch(
      setTypingStatus({status: {userId: 'genio', isTyping: false}}),
    );
    finishLoading();
  }
};

export const onStreamingMessageEndAnimation = () => {
  const {streamMessageLoadingId} = store.getState().chat;
  if (streamMessageLoadingId) {
    store.dispatch(setStreamMessageLoadingId(null));
  }
};

export const resetChat = () => {
  store.dispatch(setChat({data: []}));
  store.dispatch(setCurrentThreadId(undefined));
  store.dispatch(setMessageSuccess());
};

export const updateChatWithAdjustedStatuses = (messages?: MessageItem[]) => {
  const clonedMessages = [...(messages || [])];
  let currentStatus: MessageStatus | null = null;

  for (let i = 0; i < clonedMessages.length; i++) {
    const message = clonedMessages[i];
    if (message.status === MessageStatus.ReadByEveryone) {
      currentStatus = MessageStatus.ReadByEveryone;
    } else if (
      message.status === MessageStatus.ReceivedNotRead &&
      !currentStatus
    ) {
      currentStatus = MessageStatus.ReceivedNotRead;
    }

    if (currentStatus) {
      clonedMessages[i] = {
        ...message,
        status: currentStatus,
      };
    }
  }

  store.dispatch(setChat({data: clonedMessages}));
};
