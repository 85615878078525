import React, {useEffect, useRef, useState} from 'react';
import styled, {useTheme} from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import {MdSend} from 'react-icons/md';
import {motion} from 'framer-motion';
import {Theme} from '../../theme/lightTheme';
import AudioMotionAnalyzer from 'audiomotion-analyzer';
import {tr} from '../../translation';
import {ChatBottomBarProps} from '../../interfaces/components/chatBottomBar';
import Icon from '../icon';

// Updated Styled Components

const Container = styled.div`
  padding: 1rem;
  width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  gap: 0.75rem;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  overflow: hidden;
  padding: 0.5rem 0.75rem;
  background: linear-gradient(
    135deg,
    ${({theme}) => theme.voiceQuestionInput.background + 'CC'} 0%,
    ${({theme}) => theme.voiceQuestionInput.background + '88'} 100%
  );
  border: 1px solid ${({theme}) => theme.text.grey + '22'};
  box-shadow: 0 4px 10px ${({theme}) => theme.shadow};
  transition:
    background 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;

  &:focus-within {
    background: linear-gradient(
      135deg,
      ${({theme}) => theme.voiceQuestionInput.background + 'EE'} 0%,
      ${({theme}) => theme.voiceQuestionInput.background + '99'} 100%
    );
    box-shadow: 0 8px 16px ${({theme}) => theme.shadow};
  }
`;

const Textarea = styled(TextareaAutosize)<{}>`
  border: none;
  max-height: 6rem;
  padding: 0.75rem 1rem;
  font-size: ${({theme}) => theme.text.s7};
  color: ${({theme}) => theme.voiceQuestionInput.text || '#333'};
  width: 100%;
  resize: none;
  overflow-y: auto;
  background-color: transparent;
  transition: border-color 0.2s ease-in-out;
  box-shadow: none;
  font-family: 'Poppins', sans-serif !important;

  &::placeholder {
    color: ${({theme}) => theme.text.grey + 'BB'};
  }

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const SendIconContainer = styled(motion.button)<{disabled: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: ${({theme}) => theme.text.s3};
  height: ${({theme}) => theme.text.s3};
  border: none;
  background: ${({theme, disabled}) =>
    theme.chatBottomBar.background + (disabled ? '44' : 'FF')};
  transition: all 0.2s ease-in-out;

  &:disabled {
    opacity: 0.5;
  }
`;

const ChatBottomBar = ({
  className,
  input,
  handleInputChange,
  handleSubmit,
  isLoading,
  isRecording,
  disabled,
  onRecordPress,
  ...props
}: ChatBottomBarProps) => {
  const theme = useTheme() as Theme;
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const audioVisualizerRef = useRef<HTMLDivElement>(null);
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null);
  const [audioMotion, setAudioMotion] = useState<AudioMotionAnalyzer | null>(
    null,
  );

  useEffect(() => {
    if (audioVisualizerRef.current) {
      const analyzer = new AudioMotionAnalyzer(audioVisualizerRef.current, {
        height:
          2 * parseFloat(getComputedStyle(document.documentElement).fontSize),
        showBgColor: true,
        volume: 0,
        bgAlpha: 0,
        overlay: true,
        reflexRatio: 0.5,
        reflexAlpha: 1,
        fillAlpha: 1,
        lineWidth: 0,
        mode: 10,
        mirror: -1,
        showScaleX: false,
      });
      analyzer.registerGradient('pacific', {
        bgColor: 'transparent',
        colorStops: [
          {color: '#00d3d3', pos: 0.8},
          {color: theme.text.light, pos: 1},
        ],
      });
      analyzer.gradientLeft = 'pacific';
      setAudioMotion(analyzer);

      return () => {
        analyzer?.disconnectInput();
        analyzer?.destroy();
      };
    }
  }, [audioVisualizerRef.current]);

  useEffect(() => {
    toggleMicrophone();

    if (!isRecording && audioStream)
      audioStream.getTracks().forEach(track => track.stop());

    return () => {
      if (audioStream) audioStream.getTracks().forEach(track => track.stop());
    };
  }, [isRecording, audioMotion, audioStream]);

  const toggleMicrophone = async () => {
    if (
      !audioMotion?.audioCtx.createMediaStreamSource ||
      !navigator.mediaDevices ||
      !isRecording
    ) {
      return;
    }
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    const micStreamSource =
      audioMotion.audioCtx.createMediaStreamSource(stream);
    audioMotion.connectInput(micStreamSource);
    setAudioStream(stream);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Only submit when Enter is pressed without Shift
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (isLoading || !(input || '').trim()) {
        return;
      }
      onSubmit();
    }
  };

  const onSubmit = (e?: React.FormEvent) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (textAreaRef?.current?.value) {
      handleSubmit && handleSubmit(textAreaRef.current.value || '');
    }
  };

  return (
    <Container className={className}>
      <FormContainer>
        <SendIconContainer
          whileTap={{
            opacity: 0.8,
            scale: 0.96,
          }}
          style={{
            backgroundColor: theme.chatBottomBar.background + '11',
          }}
          onClick={onRecordPress}
          disabled={isLoading}>
          <Icon
            name={isRecording ? 'pause' : 'record'}
            size="80%"
            color={theme.chatBottomBar.background}
          />
        </SendIconContainer>
        <Textarea
          ref={textAreaRef}
          autoComplete="off"
          autoFocus
          value={isLoading ? '' : input}
          onChange={handleInputChange}
          readOnly={isLoading}
          onKeyDown={handleKeyDown}
          placeholder={tr('voiceInteraction.placeholderIsClosed')}
          spellCheck="false"
          {...props}
        />

        <SendIconContainer
          title={
            disabled
              ? tr('voiceInteraction.disableTitleClinical')
              : !input.trim()
                ? tr('voiceInteraction.disableTitle')
                : ''
          }
          whileTap={{
            opacity: 0.8,
            scale: 0.96,
          }}
          onClick={onSubmit}
          disabled={disabled || isLoading || !input.trim()}>
          <MdSend size="80%" color={theme.chatBottomBar.sendIcon} />
        </SendIconContainer>
      </FormContainer>
    </Container>
  );
};

export default ChatBottomBar;
