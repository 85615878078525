import React, {useEffect, useState, ButtonHTMLAttributes} from 'react';
import styled, {useTheme} from 'styled-components';
import {MyPressableProps} from '../../interfaces/components/myPressable';
import {Theme} from '../../theme/lightTheme';

interface ContainerProps {
  $opacity: number;
  $borderRadius: number;
}

const Container = styled.div<ContainerProps>`
  overflow: hidden;
  opacity: ${({$opacity}) => $opacity || 1};
  border-radius: ${({$borderRadius}) => $borderRadius}px;
  display: flex;
`;

interface CustomPressableProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'style'> {
  $backgroundColor: string;
  $pressed: boolean;
  $disableOpacity: boolean;
  $disableScale: boolean;
  $disableRipple: boolean;
}

const Pressable = styled.button<CustomPressableProps>`
  background-color: ${({$backgroundColor, $pressed, $disableRipple}) =>
    $pressed && !$disableRipple ? `${$backgroundColor}2F` : $backgroundColor};
  opacity: ${({$disableOpacity, $pressed}) =>
    !$disableOpacity && $pressed ? 0.5 : 1};
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${({$pressed, $disableScale}) =>
    $pressed && !$disableScale ? 'scale(0.95)' : 'scale(1)'};
  transition: transform 0.2s;

  &:disabled {
    cursor: not-allowed;
  }
`;

const RippleEffect = styled.div<{$rippleColor: string}>`
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 0.6s linear;
  background-color: ${({$rippleColor}) => $rippleColor};
  pointer-events: none;

  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }
`;

const MyPressable: React.FC<MyPressableProps> = ({
  style,
  children,
  disableOpacity = true,
  containerStyle,
  borderRadius = 0,
  secondaryRipple = false,
  primaryRipple = false,
  disabled = false,
  disabledNoOpacity = false,
  backgroundColor = 'transparent',
  disableOpacityAnimation,
  onPress,
  disableRipple,
  disableScale,
  ...props
}) => {
  const theme = useTheme() as Theme;
  const [opacity, setOpacity] = useState<number>(1);
  const [pressed, setPressed] = useState<boolean>(false);
  const [rippleStyles, setRippleStyles] = useState<{
    top: number;
    left: number;
    color: string;
  } | null>(null);

  useEffect(() => {
    if (disabled && !disabledNoOpacity) {
      setOpacity(0.4);
    } else {
      setOpacity(1);
    }
  }, [disabled, disabledNoOpacity]);

  const getRippleColor = () => {
    if (primaryRipple) {
      return `${theme.ripple}2F`;
    } else if (secondaryRipple) {
      return `${theme.secondary}2F`;
    }
    return `${theme.primary}2F`;
  };

  const handleMouseDown = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const rippleColor = getRippleColor();
    const rect = e.currentTarget.getBoundingClientRect();
    const top = e.clientY - rect.top - rect.height / 2;
    const left = e.clientX - rect.left - rect.width / 2;
    setRippleStyles({top, left, color: rippleColor});
    setPressed(true);
  };

  const handleMouseUp = () => {
    setPressed(false);
    setTimeout(() => setRippleStyles(null), 600); // Remove ripple effect after animation
  };

  const onPressHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (onPress) {
      e.preventDefault();
      onPress && onPress();
    } else {
      props?.onClick && props?.onClick(e);
    }
  };

  return (
    <Container
      style={containerStyle}
      $opacity={opacity}
      $borderRadius={borderRadius}>
      <Pressable
        $disableRipple={disableRipple}
        $disableScale={disableScale}
        onClick={onPressHandler}
        style={style as React.CSSProperties}
        $backgroundColor={backgroundColor}
        $pressed={pressed}
        $disableOpacity={disableOpacity}
        disabled={disabled || disabledNoOpacity}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        {...(props as Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'style'>)}>
        {children}
        {rippleStyles && !disableRipple && (
          <RippleEffect
            $rippleColor={rippleStyles.color}
            style={{top: rippleStyles.top, left: rippleStyles.left}}
          />
        )}
      </Pressable>
    </Container>
  );
};

export default MyPressable;
