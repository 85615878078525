import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  FiltersModalMessage,
  FiltersModalState,
} from '../../interfaces/connected-components/filtersModal';

const initialState: FiltersModalState = {
  isVisible: false,
  onSubmit: undefined,
  filters: [],
  filtersValues: {},
};

const filtersModalSlice = createSlice({
  name: 'filtersModal',
  initialState,
  reducers: {
    openFiltersModal: (state, action: PayloadAction<FiltersModalMessage>) => {
      state.isVisible = true;
      if (action.payload.onSubmit) {
        state.onSubmit = action.payload.onSubmit;
      }
      state.filters = action.payload.filters || [];
      state.filtersValues = action.payload.filtersValues || {};
    },
    hideFiltersModal: state => {
      state.isVisible = false;
      state.onSubmit = undefined;
      state.filters = [];
      state.filtersValues = {};
    },
  },
});

export const {openFiltersModal, hideFiltersModal} = filtersModalSlice.actions;
export default filtersModalSlice.reducer;
