// Cardiology
import {basePdfUrl} from '../../../../../api/api';

const diagnosticToolsDtCardiology = basePdfUrl + 'dt/cardiology/';

export const diagnosticToolsCardiologyData = [
  {
    id: 0,
    title: 'Acute Coronary Syndrome',
    pdf_link: diagnosticToolsDtCardiology + 'ACS-Diagnosis.pdf',
  },
  {
    id: 1,
    title: 'Atrial Fibrillation',
    pdf_link: diagnosticToolsDtCardiology + 'Atrial-Fibrillation-Diagnosis.pdf',
  },
  {
    id: 2,
    title: 'Dyslipidemia',
    pdf_link: diagnosticToolsDtCardiology + 'Dyslipidemia-Diagnosis.pdf',
  },
  {
    id: 3,
    title: 'Heart Failure',
    pdf_link: diagnosticToolsDtCardiology + 'HF-Diagnosis.pdf',
  },
  {
    id: 4,
    title: 'Hypertension',
    pdf_link: diagnosticToolsDtCardiology + 'Hypertention-Diagnosis.pdf',
  },
  {
    id: 5,
    title: 'Pulmonary Arterial Hypertension',
    pdf_link:
      diagnosticToolsDtCardiology +
      'PULMONARY-ARTERIAL-HYPERTENSION-DIAGNOSIS.pdf',
  },
  {
    id: 6,
    title: 'Rheumatic Heart Disease',
    pdf_link: diagnosticToolsDtCardiology + 'RHD-diagnosis.pdf',
  },
  {
    id: 7,
    title: 'Stroke',
    pdf_link: diagnosticToolsDtCardiology + 'Stroke-Diagnosis.pdf',
  },
  {
    id: 8,
    title: 'Thrombosis',
    pdf_link: diagnosticToolsDtCardiology + 'Thrombosis-Diagnosis.pdf',
  },
];
