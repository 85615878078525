import {StackItem} from '../../interfaces/router/stackItem';
import paths from '../../config/paths';
import NotFound from '../../screens/content/notFound';
import SharedArticle from '../../screens/content/sharedArticle';

/**
 * An array of shared routes for a navigation stack.
 * @type {StackItem[]}
 */
const sharedRoutes: StackItem[] = [
  {
    path: paths.shared.sharedArticle,
    component: SharedArticle,
  },
  {
    path: paths.shared.notFound,
    component: NotFound,
  },
];

export default sharedRoutes;
