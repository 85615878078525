import {createSlice} from '@reduxjs/toolkit';
import {DrawerState} from '../../interfaces/connected-components/drawer';

const initialState: DrawerState = {
  isVisible: false,
};

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    showDrawer: state => {
      state.isVisible = true;
    },
    hideDrawer: state => {
      state.isVisible = false;
    },
  },
});

export const {showDrawer, hideDrawer} = drawerSlice.actions;
export default drawerSlice.reducer;
