import axios, {AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import {store} from '../redux/store';
import {api, baseUrl} from './api';
import {endSession} from '../screens/auth/login/actions';
import {LanguageState} from '../interfaces/redux/language';
import {cacheKey, getCache, setCache} from '../utils/axiosCache';

// Create a cache using a Map
const cache = new Map();

/**
 * create new axios instance.
 * @type {AxiosInstance}
 */
const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    // 'Cache-Control': 'no-cache, no-store, must-revalidate',
    // Pragma: 'no-cache',
    // Expires: '0',
    // 'Content-Type': 'application/x-www-form-urlencoded',
    // 'X-Device-OS': 'web',
  },
});
axiosInstance.defaults.timeout = 30000;
axiosInstance.defaults.timeoutErrorMessage = 'timeout';
/**
 * create interceptor for request to add token.
 */
axiosInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig<any>) => {
    const tempConfig = {...config};

    const {currentUser} = store.getState().auth || {};
    const language: LanguageState = store.getState().lang;
    let languageCode = 'en';
    if (language?.languageCode) {
      languageCode = language?.languageCode;
    }
    tempConfig.headers['Accept-Language'] = languageCode;
    if (currentUser?.token) {
      tempConfig.headers.Authorization = `Bearer ${currentUser.token}`;
      const cachedResponse = getCache(cacheKey(tempConfig), cache);
      if (cachedResponse) {
        return Promise.reject({cachedResponse});
      }
    }
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info(tempConfig.url);
    }

    if ((tempConfig?.url || '').split('/')[0] === api.news.shareNews) {
      tempConfig.baseURL = 'https://genio-backend.canguru.dev/api/v1/';
    }

    return tempConfig;
  },
  (error: any) => {
    Promise.reject(error);
  },
);
/**
 * create axios interceptor to response for refresh token.
 */
axiosInstance.interceptors.response.use(
  async (response: AxiosResponse<any, any>) => {
    setCache(cacheKey(response?.config), response, cache);
    return response;
  },
  async (error: any) => {
    if (error?.cachedResponse) {
      return Promise.resolve(error?.cachedResponse);
    }
    if (error?.response?.status === 401) {
      await endSession();
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
