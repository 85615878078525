// Urology
import {basePdfUrl} from '../../../../../api/api';

const diagnosticToolsDtUrology = basePdfUrl + 'dt/urology/';

export const diagnosticToolsUrologyData = [
  {
    id: 0,
    title: 'Male Infertility and ED',
    pdf_link:
      diagnosticToolsDtUrology +
      '1-Male-Infertility-and-ED-Diagnostic-Tools.pdf',
  },
  {
    id: 1,
    title: 'Pyelonephritis and Nephrolithiasis',
    pdf_link:
      diagnosticToolsDtUrology +
      '1-Pyelonpehritis-and-Nephrolithiasis-Diagnostic-Tools.pdf',
  },
  {
    id: 2,
    title: 'Benign Prostatic Hyperplasia',
    pdf_link:
      diagnosticToolsDtUrology + 'Benign-Prostatic-Hyperplasia-Diagnosis.pdf',
  },
  {
    id: 3,
    title: 'Epididymitis',
    pdf_link: diagnosticToolsDtUrology + 'Epididymitis-Diagnosis.pdf',
  },
  {
    id: 4,
    title: 'Overactive Bladder',
    pdf_link: diagnosticToolsDtUrology + 'Overactive-Bladder-Diagnosis.pdf',
  },
  {
    id: 5,
    title: 'Prostatitis',
    pdf_link: diagnosticToolsDtUrology + 'Prostatitis-Diagnosis.pdf',
  },
  {
    id: 6,
    title: 'Urethritis and Cystitis',
    pdf_link:
      diagnosticToolsDtUrology + 'Urethritis-and-Cystitis-Diagnosis.pdf',
  },
  {
    id: 7,
    title: 'Urinary Incontinence',
    pdf_link: diagnosticToolsDtUrology + 'Urinary-Incontinence-Diagnosis.pdf',
  },
  {
    id: 8,
    title: 'Urinary Retention',
    pdf_link: diagnosticToolsDtUrology + 'Urinary-Retention-Diagnosis.pdf',
  },
];
