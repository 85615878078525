// Cardiology Treatment Options
import {basePdfUrl} from '../../../../../api/api';

const treatmentOptionsToCardiology = basePdfUrl + 'to/cardiology/';

export const treatmentOptionsCardiologyData = [
  {
    id: 0,
    title: 'Acute Coronary Syndrome',
    pdf_link: treatmentOptionsToCardiology + 'ASC-Treatment-Option.pdf',
  },
  {
    id: 1,
    title: 'Atrial Fibrillation',
    pdf_link:
      treatmentOptionsToCardiology +
      'Atrial-fibrillation-treatment-options-.pdf',
  },
  {
    id: 2,
    title: 'Dyslipidemia',
    pdf_link: treatmentOptionsToCardiology + '2-Dyslipidemia-Treatment.pdf',
  },
  {
    id: 3,
    title: 'Heart Failure',
    pdf_link: treatmentOptionsToCardiology + 'HF-Treatment.pdf',
  },
  {
    id: 4,
    title: 'Hypertension',
    pdf_link: treatmentOptionsToCardiology + 'Hypertention-Treatment.pdf',
  },
  {
    id: 5,
    title: 'Pulmonary Arterial Hypertension',
    pdf_link:
      treatmentOptionsToCardiology +
      'PULMONARY-ARTERIAL-HYPERTENSION-TREATMENT.pdf',
  },
  {
    id: 6,
    title: 'Rheumatic Heart Disease',
    pdf_link:
      treatmentOptionsToCardiology +
      'Rheumatic-Heart-Disease-Treatment-Option.pdf',
  },
  {
    id: 7,
    title: 'Stroke',
    pdf_link: treatmentOptionsToCardiology + 'Stroke-Treatment.pdf',
  },
  {
    id: 8,
    title: 'Thrombosis',
    pdf_link: treatmentOptionsToCardiology + 'Thrombosis-Treatment.pdf',
  },
];
