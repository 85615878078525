import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {OnboardingState} from '../../../interfaces/reducers/onboarding';

const initialState: OnboardingState = {};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setIsOnboardingSeen: (state, action: PayloadAction<OnboardingState>) => {
      state.isSeen = action.payload.isSeen;
    },
  },
});

// Action creators are generated for each case reducer function
export const {setIsOnboardingSeen} = onboardingSlice.actions;

export default onboardingSlice.reducer;
