import React from 'react';
import styled, {useTheme} from 'styled-components';
import Icon from '../icon';
import {Theme} from '../../theme/lightTheme';
import {EmptyListProps} from '../../interfaces/components/emptyList';
import {tr} from '../../translation';
import GenericButton from '../button';

const Container = styled.div<{inverted?: boolean}>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 2rem;
  border-radius: 16px;
  overflow: hidden;
  transform: ${({inverted}) => (inverted ? 'rotate(180deg)' : 'none')};
  @media (min-width: 1201px) {
    width: 50%;
  }

  @media (max-width: 1200px) {
    width: 50%;
  }

  @media (max-width: 900px) {
    width: 90%;
  }
`;

const Title = styled.p`
  font-size: ${({theme}) => theme.text.s6};
  color: ${({theme}) => theme.text.dark};
  text-align: center;
  margin-top: 5px;
`;

const EmptyList: React.FC<EmptyListProps> = ({
  iconName,
  iconType = 'SVG',
  iconSize,
  iconColor,
  title,
  showRefresh = false,
  onRefreshPress,
  inverted = false,
  containerStyle,
  titleStyle,
  buttonStyle,
}) => {
  const theme = useTheme() as Theme;

  return (
    <Container style={containerStyle} inverted={inverted}>
      {!!iconName && !!iconType && (
        <Icon
          name={iconName}
          type={iconType}
          size={iconSize || theme.text.s1}
          color={iconColor || theme.icon}
        />
      )}
      <Title style={titleStyle}>{tr(title)}</Title>
      {showRefresh && (
        <GenericButton
          isSmall
          style={{
            backgroundColor: theme.icon,
            marginTop: '5%',
            ...buttonStyle,
          }}
          backgroundColor={theme.icon}
          dynamicWidth
          label={tr('app.tryAgain')}
          labelStyle={{
            fontSize: theme.text.s9,
            minWidth: '40%',
            textAlign: 'center',
          }}
          onPress={onRefreshPress}
        />
      )}
    </Container>
  );
};

export default EmptyList;
