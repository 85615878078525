// Respiratory Guidelines
import {basePdfUrl} from '../../../../../api/api';

const guidelinesGlRespiratory = basePdfUrl + 'gl/respiratory/';

export const guidelinesRespiratoryData = [
  {
    id: 0,
    title: 'Pharyngitis',
    pdf_link: guidelinesGlRespiratory + '3-Pharyngitis-guidelines.pdf',
  },
  {
    id: 1,
    title: 'Pneumonia',
    pdf_link: guidelinesGlRespiratory + '3-Pneumonia-Guidelines.pdf',
  },
  {
    id: 2,
    title: 'Tuberculosis',
    pdf_link: guidelinesGlRespiratory + '3-TB-Guidelines.pdf',
  },
  {
    id: 3,
    title: 'Asthma',
    pdf_link: guidelinesGlRespiratory + 'Asthma-Guideline.pdf',
  },
  {
    id: 4,
    title: 'Bronchitis',
    pdf_link: guidelinesGlRespiratory + 'BRONCHITIS-GUIDELINES-DOCUMENT-3.pdf',
  },
  {
    id: 5,
    title: 'COPD',
    pdf_link: guidelinesGlRespiratory + 'COPD-guidelines.pdf',
  },
  {
    id: 6,
    title: 'Common Cold',
    pdf_link: guidelinesGlRespiratory + 'Common-Cold-Guidelines.pdf',
  },
  {
    id: 7,
    title: 'Lung Cancer',
    pdf_link: guidelinesGlRespiratory + 'Lung-Cancer-Guidelines.pdf',
  },
  {
    id: 8,
    title: 'Otitis Media',
    pdf_link: guidelinesGlRespiratory + 'OM-Guidelines.pdf',
  },
  {
    id: 9,
    title: 'Sinusitis',
    pdf_link: guidelinesGlRespiratory + 'SINUSITIS-GUIDELINES.pdf',
  },
];
