import React, {useCallback, useEffect, useState} from 'react';
import styled, {css, useTheme} from 'styled-components';
import {Theme} from '../../../theme/lightTheme';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../redux/store';
import useScrollTop from '../../../utils/hooks/useScrollTop';
import {useLocation, useNavigate} from 'react-router-dom';
import {TabItemType} from '../../../interfaces/api/content/home';
import {
  DrugItemDetailsType,
  DrugItemType,
  FilterData,
  FiltersType,
  GetTabNewsData,
  NewsItemType,
} from '../../../interfaces/api/tabs';
import {
  defaultImagesDrugsData,
  drugInfoData,
  staticDrugsFilters,
} from '../../../assets/static/drugs';
import {searchNews} from '../search/actions';
import {getTabNews} from '../tabNews/actions';
import Icon from '../../../components/icon';
import {tr} from '../../../translation';
import SkeletonPlaceholder from '../../../components/skeletonPlaceholder';
import {showGlobalToast} from '../../../connected-components/toast/actions';
import {useGlobalAudioPlayer} from 'react-use-audio-player';
import {useSpeechRecognition} from 'react-speech-kit';
import {getDrugDetails} from './actions';
import {setIsVoiceTabNewsPlayed} from '../../auth/login/reducer';
import {
  finishLoading,
  startLoading,
} from '../../../connected-components/loading/actions';
import {submitQNA} from '../voiceInteraction/actions';
import {
  getUrlParts,
  isMp3Available,
  isUrl,
  openUrl,
  sleep,
} from '../../../utils/helper';
import Tooltip from '../../../components/tooltip';
import GenericButton from '../../../components/button';
import {TooltipContainer} from '../article';
import AnswerItem from '../../../components/answerItem';
import drugsBackground from '../../../assets/images/drugsBackground.jpg';
import VoiceQuestionInput from '../../../components/voiceQuestionInput';
import DrugItem from '../../../components/drugItem';
import CustomGrid from '../../../components/customGrid';
import DrugFilterItem from '../../../components/drugFilterItem';
import {motion} from 'framer-motion';
import SearchDrugs from '../../../components/searchDrugs';
import {useSetMainLayoutProps} from '../../../route/routeMainLayout/context';
import AnimatedRouterLayout from '../../../components/animatedRouterLayout';
import {isSmallHeight} from '../../../utils/responsive';
import {ChatType, TabIds} from '../../../utils/enums';
import {GenioLatestNewsItem} from '../home';
import {renderArticleContent} from '../../../utils/html';
import Paths from '../../../config/paths';
import {resetChat, sendMessage} from '../chat/actions';
import ChatBottomBar from '../../../components/chatBottomBar';
import RecordingView from '../../../components/recordingView';

const Container = styled(AnimatedRouterLayout)`
  display: flex;
  position: relative;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding: ${isSmallHeight ? 1 : 3}% 15% ${isSmallHeight ? 1 : 3}% 15%;
  @media (max-width: 550px) {
    padding: 3%;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
  }
`;
export const FiltersContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: ${({theme}: {theme: Theme}) => theme.homeBackground};
  border-radius: 40px;
  z-index: 22;
`;
const DrugDetailsFragment = styled.div`
  align-self: center;
  background-color: ${({theme}: {theme: Theme}) => theme.homeBackground};
  border-radius: 16px;
  margin-top: ${isSmallHeight ? 1 : 3}rem;
  padding: ${isSmallHeight ? 1 : 1.5}rem ${isSmallHeight ? 1.5 : 2}rem;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const DrugItemFragment = styled(DrugItem)`
  max-width: unset;
  @media (max-width: 768px) {
    max-width: 80%;
  }
  margin: -${isSmallHeight ? 2.5 : 3}rem auto ${isSmallHeight ? 2 : 3}rem auto;
`;
const ClosedVoiceQuestionInputItem = styled(VoiceQuestionInput)`
  width: ${isSmallHeight ? 90 : 100}%;
  margin-top: ${isSmallHeight ? 1 : 2}rem;
`;
const DrugDetailsContainer = styled(motion.div)`
  align-self: center;
  border-radius: 40px;
  z-index: 1;
  width: 100%;
  padding: ${isSmallHeight ? 0.5 : 1}rem;
  @media (max-width: 550px) {
    padding: 0.2rem;
  }
`;
export const FilterItemContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: ${isSmallHeight ? 0.5 : 1}rem;
  z-index: 2;
  width: 100%;
`;
export const FilterValuesContainer = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
`;
export const FilterLabel = styled.p`
  font-size: ${({theme}: {theme: Theme}) => theme.text.s6};
  color: ${({theme}: {theme: Theme}) => theme.text.dark};
  letter-spacing: 1px;
  padding-bottom: ${isSmallHeight ? 1 : 2}rem;
  text-align: center;
  text-shadow: 0 1px 3px ${({theme}: {theme: Theme}) => theme.text.dark + '55'};
  font-weight: 500;
`;
const DrugDescriptionText = styled.p<{isLink?: boolean}>`
  font-size: ${({theme}) => theme.text.s8};
  color: ${({theme, isLink}) => (isLink ? theme.secondary : theme.text.title)};
  ${({isLink}) =>
    isLink &&
    css`
      text-decoration: underline;
    `};
`;
const CloseAnswerIconContainer = styled.div`
  display: flex;
  align-self: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.2rem;
  z-index: 99;
  border-radius: 50%;
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding: 1rem;
  width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    max-width: 1200px;
    margin: 0 auto;
  }
`;

const DrugDescriptionContent = styled.div`
  font-size: ${({theme}) => theme.text.s8};
  color: ${({theme}) => theme.text.title};

  a {
    color: ${({theme}) => theme.text.light};
    font-weight: bold;
    word-break: break-all;
    outline: none;
  }

  /* Style for Markdown-rendered tables */
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
    font-size: ${({theme}) => theme.text.s8};
    text-align: left;
    border: 1px solid ${({theme}) => theme.border}; /* Outer border */
  }

  thead {
    background-color: ${({theme}) => theme.table.thead};
  }

  th {
    padding: 12px 16px;
    text-align: left;
    font-weight: 600;
    border: 1px solid ${({theme}) => theme.border}; /* Grid lines for header */
    color: ${({theme}) => theme.table.th};
  }

  tr {
    transition: background-color 0.2s ease;
  }

  /* Alternate row background for even rows */
  tr:nth-child(even) {
    background-color: ${({theme}) => theme.table.evenRow};
  }

  /* Hover effect for rows */
  tr:hover {
    background-color: ${({theme}) => theme.table.trHover};
  }

  td {
    padding: 12px 16px;
    border: 1px solid ${({theme}) => theme.border}; /* Grid lines for cells */
    color: ${({theme}) => theme.table.text};
  }
`;
const ChatBottomBarContainer = styled(ChatBottomBar)`
  width: 100%;
  margin-top: ${isSmallHeight ? 1 : 1.5}rem;
  z-index: 2;
`;

/**
 * Drugs screen component.
 */
const Drugs: React.FC<any> = () => {
  useSetMainLayoutProps({
    showHeader: true,
    showBack: true,
    showSearch: false,
    showBackgroundImage: false,
  });
  useScrollTop();
  const pageSize = 30;
  const theme = useTheme() as Theme;
  const location = useLocation();
  const {
    id: tabId,
    tab_name: tabName,
    isDiseases,
  } = location?.state || ({} as TabItemType);
  const navigate = useNavigate();

  const {languageCode, countryCode} = useSelector(
    (state: RootState) => state.lang,
  ) as any;
  const {currentUser} = useSelector((state: RootState) => state.auth);
  const [filtersResult, setFiltersResult] = useState<any>({});
  const [tabFilters, setTabFilters] = useState<FiltersType | null>(
    staticDrugsFilters,
  );
  ////////
  const [data, setData] = useState<NewsItemType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isErrorVoice, setIsErrorVoice] = useState(false);
  const [page, setPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const [selectedDrug, setSelectedDrug] = useState<any>({});
  const [isFilterModalShouldClose, setIsFilterModalShouldClose] =
    useState<any>(false);
  // selected drug item Details
  const [isDrugDetailsLoading, setIsDrugDetailsLoading] = useState(false);
  const [isDrugDetailsError, setIsDrugDetailsError] = useState(false);
  const [drugDetailsData, setDrugDetailsData] = useState<DrugItemDetailsType[]>(
    [],
  );
  const [drugDetailsSection, setDrugDetailsSection] = useState<any>(null);
  //search
  const [search, setSearch] = useState<any>(null);
  const [selectedDrugSearch, setSelectedDrugSearch] = useState<any>({});
  // recording
  const [isVoiceQuestionClosed, setIsVoiceQuestionClosed] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const {isVoiceTabNewsPlayed} = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const [isErrorAnswer, setIsErrorAnswer] = useState(false);
  const [isLoadingAnswer, setIsLoadingAnswer] = useState(false);
  // sound
  const audio = useGlobalAudioPlayer();
  const {listen, listening, stop, supported} = useSpeechRecognition({
    onResult: result => !!result && setQuestion(result || ''),
    interimResults: false,
    onError: event => {
      if (event.error === 'not-allowed') {
        showGlobalToast({
          message: 'The microphone is blocked for this site in your browser.',
          type: 'warning',
        });
      }
    },
  });

  const loadMore = async (refresh = false) => {
    if (isLoading) {
      return;
    }
    setIsError(false);
    if (!isLastPage || refresh) {
      if (refresh) {
        setData([]);
      }
      setIsLoading(true);
      let result: GetTabNewsData | null = null;
      if ((search || '').trim()) {
        result = await searchNews({
          keywords: search,
          tabId,
          isDiseases,
          page: refresh ? 1 : page,
          pageSize,
        });
      } else {
        result = await getTabNews({
          tabId: isDiseases ? TabIds.Diseases : TabIds.Drugs,
          page: refresh ? 1 : page,
          pageSize,
          ...filtersResult,
        });
      }

      if (!result) {
        setIsError(true);
        setIsLoading(false);
        return;
      }
      if (!(search || '').trim()) {
        setTabFilters(result?.filters);
      }
      setData(prev => {
        if (refresh) {
          return [...result?.news!];
        } else {
          return [...prev, ...result?.news!].filter((value, index, self) => {
            return (
              self.findIndex(obj =>
                //@ts-ignore
                ['id'].every(key => obj[key] === value[key]),
              ) === index
            );
          });
        }
      });

      setPage(prev => (refresh ? 2 : prev + 1));
      const totalPageCount = Math.ceil(
        // @ts-ignore
        parseFloat(result?.totalCount || result?.total || '2') / pageSize,
      );
      setIsLastPage((refresh ? 1 : page) >= totalPageCount);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadMore(true);
  }, [filtersResult, tabId, currentUser, search]);

  useEffect(() => {
    if (drugDetailsSection) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [drugDetailsSection]);

  const initDrugDetails = async () => {
    const drugItem = Object.values(selectedDrug || {})[0] as DrugItemType;
    if (!drugItem) {
      setDrugDetailsData([]);
      setIsDrugDetailsError(false);
      setIsDrugDetailsLoading(false);
      return;
    }

    setIsDrugDetailsLoading(true);
    setIsDrugDetailsError(false);
    const res = await getDrugDetails(+drugItem?.id, isDiseases);
    if (!res) {
      setIsDrugDetailsError(true);
      setIsDrugDetailsLoading(false);
      return;
    }
    setDrugDetailsData(res || []);
    setIsDrugDetailsLoading(false);
  };

  useEffect(() => {
    initDrugDetails();
  }, [selectedDrug, tabId, currentUser]);

  const onConfirmSelection = useCallback((selectedItem: any) => {
    setSelectedDrug(selectedItem);
    setDrugDetailsSection(null);
  }, []);

  const onFilterItemPress = useCallback((itemKey: string, id: number) => {
    setIsFilterModalShouldClose(false);
    setFiltersResult({[itemKey]: id});
  }, []);

  const _renderFilterItem = (item: FilterData) => {
    return (
      <FilterItemContainer key={item?.key}>
        <FilterLabel>
          {`Find ${isDiseases ? 'Diseases' : 'Drugs'} by Alphabet` ||
            item?.label ||
            ''}
        </FilterLabel>
        <FilterValuesContainer>
          {(item?.items || []).map(filterItem => (
            <DrugFilterItem
              key={filterItem?.id + filterItem?.name}
              name={filterItem?.name}
              itemKey={item?.key}
              id={filterItem?.id}
              isLastPage={isLastPage}
              isSkeleton={item?.static}
              data={data || []}
              onPress={onFilterItemPress}
              onConfirmSelection={onConfirmSelection}
              selectedDrug={selectedDrug}
              isLoading={isLoading}
              isError={isError}
              loadMore={loadMore}
              isFilterModalShouldClose={isFilterModalShouldClose}
            />
          ))}
        </FilterValuesContainer>
      </FilterItemContainer>
    );
  };

  const _renderDrugDetailsItem = (item: DrugItemDetailsType, index: number) => {
    let drugItem: any = item;
    const idx = drugInfoData.findIndex(
      i =>
        (i.label || '').toLowerCase() ===
        (item?.section_name || '').toLowerCase(),
    );
    if (idx > -1) {
      drugItem = {...item, ...drugInfoData[idx]};
    }
    return (
      <DrugItem
        key={item?.section_name}
        imageUri={item?.img || ''}
        defaultImage={drugItem?.img || defaultImagesDrugsData[index % 9]}
        name={drugItem?.label || item?.section_name || ''}
        onPress={() => {
          setDrugDetailsSection({...item, index});
        }}
        style={{
          margin: '1%',
        }}
      />
    );
  };

  const _renderDrugDetails = () => {
    const drugItem = Object.values(selectedDrug || {})[0] as DrugItemType;
    if (!drugItem) return null;
    return (
      <DrugDetailsContainer layout={'preserve-aspect'}>
        {drugItem?.name && (
          <FilterLabel style={{fontWeight: 500}}>
            <span style={{fontWeight: 'bold', color: theme.primary}}>
              {`${drugItem?.name || 'Drug'}`}
            </span>
            {' OverView'}
          </FilterLabel>
        )}
        {isDrugDetailsError ? null : isDrugDetailsLoading ? (
          <GridContainer>
            {Array(6)
              .fill(null)
              .map((_, index) => (
                <motion.div
                  key={index}
                  initial={{opacity: 0, y: 20}}
                  animate={{opacity: 1, y: 0}}
                  transition={{delay: index * 0.1}}>
                  <SkeletonPlaceholder
                    key={index}
                    width="100%"
                    height={50}
                    borderRadius={4}
                    marginBottom={8}
                  />
                </motion.div>
              ))}
          </GridContainer>
        ) : (
          <>
            <GridContainer>
              {(drugDetailsData || []).map((item, index) => {
                return (
                  <motion.div
                    key={item?.section_name || index}
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{delay: index * 0.1 + 0.5}}>
                    <GenioLatestNewsItem
                      isSmall
                      title={item?.label || item?.section_name || ''}
                      onPress={() => {
                        setDrugDetailsSection({...item, index});
                      }}
                      style={{
                        margin: '1%',
                        height: '100%',
                      }}
                    />
                  </motion.div>
                );
              })}
            </GridContainer>
          </>
        )}
      </DrugDetailsContainer>
    );
  };

  useEffect(() => {
    return () => {
      audio.stop();
      stop();
      setDrugDetailsSection(null);
      setQuestion('');
    };
  }, []);

  /**
   * Starts listening for voice input from the user.
   * It sets the recording state to true and initiates the voice recognition process
   * with the specified locale, defaulting to 'en-US' if none is provided.
   */
  const startListening = async () => {
    try {
      if (!isVoiceTabNewsPlayed) {
        dispatch(setIsVoiceTabNewsPlayed());
      }
      showGlobalToast({
        message: tr('record.startRecord'),
        type: 'info',
      });
      let lang = 'en-US';
      if (languageCode && countryCode) {
        lang = `${languageCode}-${countryCode}`;
      }
      if (listening) {
        stop();
        setTimeout(() => {
          listen({lang});
        }, 500);
      } else {
        listen({lang});
      }
    } catch (e) {
      if (process.env.REACT_APP_DEBUG === 'true')
        console.log('start voice recognition error: ', e);
    }
  };

  const onRecordHandler = async () => {
    if (!supported) {
      return;
    }
    if (listening) {
      audio.stop();
      stop();
    } else {
      if (isVoiceTabNewsPlayed || !currentUser?.voiceUrl) {
        startListening();
      } else {
        audio.load(currentUser?.voiceUrl || '', {
          autoplay: true,
          onend: startListening,
        });
      }
    }
  };

  const onCheckAnswerPressed = async () => {
    resetChat();
    stop();
    sendMessage({question, type: ChatType.Drugs});
    setQuestion('');
    navigate(Paths.content.qna, {
      state: {
        drugQuestion: question,
        isDrugs: true,
      },
    });
  };

  return (
    <Container>
      <RecordingView isRecording={listening} stopListening={onRecordHandler} />
      <SearchDrugs
        search={search}
        setSearch={setSearch}
        isLoading={isLoading}
        isError={isError}
        data={data || []}
        isLastPage={isLastPage}
        onConfirmSelection={onConfirmSelection}
        selectedDrug={selectedDrug}
        loadMore={loadMore}
      />
      {!drugDetailsSection && (
        <FiltersContainer>
          {Object.values(tabFilters || {}).map(_renderFilterItem)}
          {_renderDrugDetails()}
        </FiltersContainer>
      )}
      {drugDetailsSection && (
        <DrugDetailsFragment>
          <DrugItemFragment
            imageUri={drugDetailsSection?.img || ''}
            defaultImage={
              defaultImagesDrugsData[(drugDetailsSection?.index || 0) % 9]
            }
            name={drugDetailsSection?.section_name || ''}
            borderRadius={100}
            isDynamicRatio
          />
          <CloseAnswerIconContainer>
            <Icon
              name={'close'}
              size={theme.text.s8}
              role={'button'}
              color={theme.text.grey}
              onPress={() => setDrugDetailsSection(null)}
            />
          </CloseAnswerIconContainer>
          <DrugDescriptionContent>
            {renderArticleContent(drugDetailsSection?.section_value || '', {
              color: theme.voiceQuestionInput.text,
            })}
          </DrugDescriptionContent>
        </DrugDetailsFragment>
      )}

      {!!answer && (
        <AnswerItem
          style={{marginTop: isSmallHeight ? '1rem' : '2rem'}}
          onClosePressed={() => {
            setAnswer('');
            setIsVoiceQuestionClosed(true);
          }}
          content={answer || ''}
        />
      )}
      <ChatBottomBarContainer
        input={question}
        errorText={isError ? tr('app.error') : ''}
        handleInputChange={e => setQuestion(e.target.value)}
        isLoading={isLoading}
        isRecording={listening}
        placeholder={tr('voiceInteraction.placeholder')}
        onRecordPress={onRecordHandler}
        handleSubmit={onCheckAnswerPressed}
      />
      <TooltipContainer>
        {!isLoading && (
          <Tooltip text={tr('drugs.tip')}>
            <GenericButton
              style={{borderRadius: 8}}
              disabledNoOpacity
              isSmall
              label={tr('latest.disclaimer')}
              showRightIcon
              rightIconType={'SVG'}
              rightIconName={'info'}
              rightIconSize={theme.text.s55}
            />
          </Tooltip>
        )}
      </TooltipContainer>
    </Container>
  );
};

export default Drugs;
