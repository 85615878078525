import {TabItemType} from '../../interfaces/api/content/home';

export const homePopularTopicsTabs: TabItemType[] = [
  {
    id: 2002,
    label: 'qna',
    img: require('../../assets/images/homeTabs/qna.png'),
  },
  {
    id: 2001,
    label: 'medicalUpdates',
    img: require('../../assets/images/homeTabs/medicalUpdates.png'),
  },
  {
    id: 2000,
    label: 'clinicalCase',
    img: require('../../assets/images/homeTabs/clinicalCase.png'),
  },
  {
    id: 2003,
    label: 'drugsAndDiseases',
    img: require('../../assets/images/homeTabs/drugsAndDiseases.png'),
  },
];
