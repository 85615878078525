import {colors} from './colors';
import useWindowDimensions from '../utils/hooks/useWindowDimensions';
import {getByScreenSize, hdp, wdp} from '../utils/responsive';

/**
 * Defines the light theme for the application.
 *
 * This function returns a comprehensive object containing styles and colors for the light theme.
 *
 * @returns - An object representing the light theme, including color palettes, fonts, text styles, and component-specific styles.
 */
const height = window.innerHeight;
const lightTheme = () => {
  const {isMobile} = useWindowDimensions();
  return {
    primary: colors.primary,
    secondary: colors.secondary,
    ripple: '#D9D9D9',
    overlay: 'rgba(0,0,0,0.3)',
    headerBackground: '#FFFFFF',
    homeBackground: '#EDEFFF',
    cardBackground: 'rgba(255,255,255,0.95)',
    cardBackgroundEnd: 'rgba(249,248,255,0.95)',
    icon: colors.secondary,
    skeletonBackground: '#DCDCDC',
    border: '#D9D9D9',
    primaryOverlay: 'rgba(40, 35, 245, 0.3)',
    backgroundOverlay: 'rgba(255,255,255,0.1)',
    imageBackground: '#F8FDFF',
    background: '#FFFFFF',
    overlayScreen: 'rgba(0,0,0,0.5)',
    defaultOverlayScreen: 'rgba(0,0,0,0.1)',
    shadow: 'rgba(0,0,0,0.4)',
    dimensions: {
      hdp: (percent: number) => hdp(percent),
      wdp: (percent: number) => wdp(percent),
      getByScreenSize: (smValue: any, lgValue: any, xsmValue: any = smValue) =>
        getByScreenSize(smValue, lgValue, xsmValue),
    },
    isMobile,
    alert: {
      backgroundPrimary: '#3d3d3d',
      textPrimary: '#FFFFFF',
    },
    fonts: {
      ar: '"BBC Reith Sans", Arial, sans-serif',
      en: '"BBC Reith Sans", Arial, sans-serif',
    },
    text: {
      dark: '#181818',
      black: '#000000',
      border: '#CCCCCC',
      icon: '#A1A1A1',
      error: '#da3c3c',
      success: '#71f186',
      grey: '#707070',
      greyLight: '#DEDBDB',
      title: '#000000',
      description: '#707070',
      light: '#ffffff',
      optionIcon: '#646460',
      s1: height >= 900 ? '4.5rem' : '4rem',
      s2: height >= 900 ? '4rem' : '3.25rem',
      s3: height >= 900 ? '2.75rem' : '2rem',
      s35: height >= 900 ? '1.8rem' : '1.4rem',
      s4: height >= 900 ? '1.5rem' : '1.2rem',
      s5: height >= 900 ? '1.4rem' : '1rem',
      s55: height >= 900 ? '1.2rem' : '0.8rem',
      s6: height >= 900 ? '1.2rem' : '0.8rem',
      s7: height >= 900 ? '1rem' : '0.75rem',
      s8: height >= 900 ? '0.875rem' : '0.7rem',
      s85: height >= 900 ? '0.875rem' : '0.7rem',
      s89: height >= 900 ? '0.825rem' : '0.675rem',
      s9: height >= 900 ? '0.8rem' : '0.65rem',
      s10: height >= 900 ? '0.7rem' : '0.6rem',
      s11: height >= 900 ? '0.65rem' : '0.55rem',
      s12: height >= 900 ? '0.5rem' : '0.4rem',
    },
    button: {
      text: '#FFFFFF',
      lightColor: '#FFFFFF',
      outline: colors.primary,
    },
    textInput: {
      cursorColor: colors.primary,
      placeholder: '#777777',
      border: '#CCCCCC',
      required: '#ffd2d2',
      background: '#FFFFFF',
    },
    fab: {
      backgroundColor: '#F5F6F7',
    },
    header: {
      primary: colors.secondary,
      startLinearGradient: 'rgba(223,223,223,0.8)',
      endLinearGradient: 'rgba(255,255,255,0.8)',
    },
    bottomTabs: {
      barBackground: 'rgba(236,236,236,0.9)',
      activeTabBackground: colors.secondary + 'EE',
      label: '#FFFFFF',
      icon: colors.secondary + 'EE',
      iconFocus: '#FFFFFF',
    },
    activityListItem: {
      startColor: '#E5E5E5',
      endColor: '#f1f1f1',
    },
    drawer: {
      bgColor1: colors.primary + 'AA',
      bgColor2: colors.secondary + 'AA',
      fontColor: '#ffffff',
      fontColorSelected: '#D9D9D9',
      dividerColor: '#CCCCCC',
      selectedBackgroundCollapsedMode: 'light',
    },
    switch: {
      trackOff: '#aaaaaa',
      trackOn: colors.secondary,
      thumb: '#F5F6F7',
    },
    search: {
      placeholder: '#181818',
    },
    questionInput: {
      text: '#ffffff',
      placeholder: '#D9D9D9',
      startColor: 'rgba(9,7,71,0.7)',
      endColor: 'rgba(42,44,112,0.4)',
    },
    voiceQuestionInput: {
      text: '#181818',
      background: '#f5f5f5',
      inputPlaceholder: 'rgba(0,0,0,0.1)',
      iosEndColor: 'rgba(0,0,0,0)',
      mainButtonStartColor: colors.primary,
      mainButtonEndColor: colors.secondary,
    },
    afterLogin: {
      contentBackground: '#f5f5f5',
      text: '#181818',
      label: '#5d5d5d',
      line: '#999',
      selected: 'rgba(0,0,0,0.1)',
      checkIcon: '#e7e6e6',
      checkIconBackground: colors.primary,
    },
    chatBubble: {
      backgroundSend: colors.secondary,
      backgroundReceived: '#ffffff',
      selected: 'rgba(11, 13, 111, 0.2)',
      date: colors.secondary,
      error: '#ffdfdf',
      favorite: '#FFD707',
      sendText: '#ffffff',
      receivedText: colors.secondary,
      online: '#52bd57',
      offline: '#9E9E9E',
    },
    onboarding: {
      text: '#FFFFFF',
      background: '#0B0D6F',
      textSecondary: colors.tertiary,
    },
    home: {
      cardBackground: '#3C3D8C',
    },
    link: {
      background: 'rgba(135,135,135,0.8)',
      color: colors.secondary,
    },
    chatBottomBar: {
      sendIcon: '#ffffff',
      background: colors.primary,
    },
    chatList: {
      text: '#1c1c1c',
    },
    table: {
      thead: '#f7f7f7',
      text: '#333333',
      tr: '#f2f2f2',
      trHover: '#e6f7ff',
      evenRow: '#f9f9f9',
    },
    scrollbar: {
      track: 'transparent',
      thumb: 'rgba(26,115,232,0.5)',
      thumbHover: 'rgba(26,115,232,0.7)',
    },
    dropdown: {
      background: '#ffffff',
      color: '#e3e3e3',
      delete: '#f93a37',
    },
    sidebar: {
      background: '#f3f3ef',
      shadow: '#f3f3ef',
      hover: '#f3f3ef',
    },
  };
};

/**
 * The type representing the structure of the light theme.
 * @type {Theme}
 */
type Theme = ReturnType<typeof lightTheme>;

export type {Theme};
export default lightTheme;
