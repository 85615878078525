import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styled, {useTheme} from 'styled-components';
import {Theme} from '../../theme/lightTheme';
import HomeTab from '../homeTab';

const Container = styled.div`
  display: grid;
  gap: 16px;
  margin: 0 auto;
  width: 90%;
  align-self: center;
    grid-template-columns: repeat(2, 1fr);

`;

const SkeletonCard = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
  display: flex;
  flex: 1;
  background: ${({theme}) => theme.home.cardBackground};
  border-radius: 30px;
`;
const HomeTabItemSkeleton = styled(HomeTab)`
  opacity: 0;
  pointer-events: none;
`;

interface HomeTabsSkeletonProps {
  number?: number;
}

const HomeTabsSkeleton: React.FC<HomeTabsSkeletonProps> = ({number = 4}) => {
  const theme = useTheme() as Theme;

  return (
    <Container>
      {Array(number)
        .fill(0)
        .map((_, index) => (
          <SkeletonCard key={index}>
            <Skeleton
              baseColor={'transparent'}
              highlightColor={theme.text.light + '33'}
              height={'100%'}
              width={'100%'}
              style={{
                position: 'absolute',
                inset: 0,
                borderRadius: 30,
                zIndex: 22,
              }}
            />
            <HomeTabItemSkeleton />
          </SkeletonCard>
        ))}
    </Container>
  );
};

export default HomeTabsSkeleton;
