// Neurology Guidelines
import {basePdfUrl} from '../../../../../api/api';

const guidelinesGlNeurology = basePdfUrl + 'gl/neurology/';

export const guidelinesNeurologyData = [
  {
    id: 0,
    title: 'Dementia',
    pdf_link: guidelinesGlNeurology + '3-Dementia-Guidelines.pdf',
  },
  {
    id: 1,
    title: "Parkinson's Disease",
    pdf_link: guidelinesGlNeurology + '3-Parkinsons-Disease-Guidelines.pdf',
  },
  {
    id: 2,
    title: 'Autism Spectrum Disorder',
    pdf_link: guidelinesGlNeurology + 'Autism-Spectrum-Disorder-Guidelines.pdf',
  },
  {
    id: 3,
    title: 'Brain Tumor in Adults',
    pdf_link: guidelinesGlNeurology + 'Brain-Tumor-in-Adults-Guidelines.pdf',
  },
  {
    id: 4,
    title: 'Epilepsy',
    pdf_link: guidelinesGlNeurology + 'Epilepsy-Guidelines.pdf',
  },
  {
    id: 5,
    title: 'Guillain-Barre Syndrome',
    pdf_link: guidelinesGlNeurology + 'Guillain-Barre-Syndrome-Guidelines.pdf',
  },
  {
    id: 6,
    title: 'Headaches',
    pdf_link: guidelinesGlNeurology + 'Headaches-Guidelines-.pdf',
  },
  {
    id: 7,
    title: 'Migraine',
    pdf_link: guidelinesGlNeurology + 'Migraine-Guidelines.pdf',
  },
  {
    id: 8,
    title: 'Multiple Sclerosis',
    pdf_link: guidelinesGlNeurology + 'Multiple-Sclerosis-Guidelines.pdf',
  },
  {
    id: 9,
    title: 'Peripheral Neuropathy',
    pdf_link: guidelinesGlNeurology + 'Peripheral-Neuropathy-Guidelines.pdf',
  },
];
