// Respiratory Treatment Options
import {basePdfUrl} from '../../../../../api/api';

const treatmentOptionsToRespiratory = basePdfUrl + 'to/respiratory/';

export const treatmentOptionsRespiratoryData = [
  {
    id: 0,
    title: 'Pharyngitis',
    pdf_link: treatmentOptionsToRespiratory + '2-Pharyngitis-treatment.pdf',
  },
  {
    id: 1,
    title: 'Pneumonia',
    pdf_link: treatmentOptionsToRespiratory + '2-Pneumonia-Treatment.pdf',
  },
  {
    id: 2,
    title: 'Tuberculosis',
    pdf_link: treatmentOptionsToRespiratory + '2-TB-Treatment-Options.pdf',
  },
  {
    id: 3,
    title: 'Asthma',
    pdf_link: treatmentOptionsToRespiratory + 'Asthma-Treatment-Option.pdf',
  },
  {
    id: 4,
    title: 'Bronchitis',
    pdf_link:
      treatmentOptionsToRespiratory + 'BRONCHITIS-TREATMENT-DOCUMENT-2.pdf',
  },
  {
    id: 5,
    title: 'COPD',
    pdf_link:
      treatmentOptionsToRespiratory + 'Common-Cold-Treatment-Options.pdf',
  },
  {
    id: 6,
    title: 'Common Cold',
    pdf_link:
      treatmentOptionsToRespiratory + 'Common-Cold-Treatment-Options.pdf',
  },
  {
    id: 7,
    title: 'Lung Cancer',
    pdf_link: treatmentOptionsToRespiratory + 'Lung-Cancer-Treatment.pdf',
  },
  {
    id: 8,
    title: 'Otitis Media',
    pdf_link: treatmentOptionsToRespiratory + 'OM-Treatment.pdf',
  },
  {
    id: 9,
    title: 'Sinusitis',
    pdf_link: treatmentOptionsToRespiratory + 'SINUSITIS-TREATMENT-OPTION.pdf',
  },
  {
    id: 10,
    title: 'COPD Treatment Options',
    pdf_link: treatmentOptionsToRespiratory + 'Treatment-Options-for-COPD.pdf',
  },
];
