// Endocrinology Guidelines
import {basePdfUrl} from '../../../../../api/api';

const guidelinesGlEndocrinology = basePdfUrl + 'gl/endocrinology/';

export const guidelinesEndocrinologyData = [
  {
    id: 0,
    title: 'Adrenal Disorders',
    pdf_link: guidelinesGlEndocrinology + 'Adrenal-Disorders-Guidelines.pdf',
  },
  {
    id: 1,
    title: 'Diabetes',
    pdf_link: guidelinesGlEndocrinology + 'Diabetes-Guidelines.pdf',
  },
  {
    id: 2,
    title: 'Gonadal Disorders',
    pdf_link: guidelinesGlEndocrinology + 'Gonadal-Disorders-Guidelines.pdf',
  },
  {
    id: 3,
    title: 'Metabolic Disorders',
    pdf_link: guidelinesGlEndocrinology + 'Metabolic-Disorders-Guidelines.pdf',
  },
  {
    id: 4,
    title: 'Parathyroid',
    pdf_link: guidelinesGlEndocrinology + '3-Parathyroid-Guidelines.pdf',
  },
  {
    id: 5,
    title: 'Pituitary Disorders',
    pdf_link: guidelinesGlEndocrinology + 'Pituitary-Disorders-Guidelines-.pdf',
  },
  {
    id: 6,
    title: 'Thyroid Disorders',
    pdf_link: guidelinesGlEndocrinology + '3-Thyroid-disorders-guidelines.pdf',
  },
];
