// Rheumatology
import {basePdfUrl} from '../../../../../api/api';

const diagnosticToolsDtRheumatology = basePdfUrl + 'dt/rheumatology/';

export const diagnosticToolsRheumatologyData = [
  {
    id: 0,
    title: 'Osteoporosis',
    pdf_link:
      diagnosticToolsDtRheumatology + '1-Osteoporosis-Diagnostic-Tools.pdf',
  },
  {
    id: 1,
    title: 'Rheumatoid Arthritis',
    pdf_link:
      diagnosticToolsDtRheumatology +
      '1-Rheumatoid-Arthritis-Diagnostic-Tools.pdf',
  },
  {
    id: 2,
    title: 'Sports Medicine',
    pdf_link:
      diagnosticToolsDtRheumatology + '1-Sports-Medicine-Diagnostic-Tools.pdf',
  },
  {
    id: 3,
    title: 'Ankylosing Spondylitis',
    pdf_link:
      diagnosticToolsDtRheumatology + 'Ankylosing-Spondylitis-Diagnosis.pdf',
  },
  {
    id: 4,
    title: 'Bone Fractures',
    pdf_link: diagnosticToolsDtRheumatology + 'Bone-Fractures-Diagnosis.pdf',
  },
  {
    id: 5,
    title: 'Gout',
    pdf_link: diagnosticToolsDtRheumatology + 'Gout-Diagnosis-.pdf',
  },
  {
    id: 6,
    title: 'Osteoarthritis',
    pdf_link: diagnosticToolsDtRheumatology + 'Osteoarthritis-Diagnosis.pdf',
  },
  {
    id: 7,
    title: 'Psoriatic Arthritis',
    pdf_link:
      diagnosticToolsDtRheumatology + 'Psoriatic-Arthritis-Diagnosis-.pdf',
  },
  {
    id: 8,
    title: 'Spinal Disorders',
    pdf_link: diagnosticToolsDtRheumatology + 'Spinal-Disorders-Diagnosis.pdf',
  },
];
