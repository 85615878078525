import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={200}
    strokeWidth={0}
    viewBox="0 0 17 17"
    {...props}
    fill="#0B0D6F"
    stroke="#0B0D6F">
    <path d="M14 16h-3v-6H6v6H3V7H2v10h13V7h-1v9zm-7 0v-5h3v5H7zm9.796-9.527-.592.807L8.5 1.62.796 7.278l-.592-.806L8.5.38l8.296 6.093z" />
  </svg>
);
export default SvgComponent;
