// Respiratory
import {basePdfUrl} from '../../../../../api/api';

const diagnosticToolsDtRespiratory = basePdfUrl + 'dt/respiratory/';

export const diagnosticToolsRespiratoryData = [
  {
    id: 0,
    title: 'Pharyngitis',
    pdf_link: diagnosticToolsDtRespiratory + '1-Pharyngitits-diagnosis.pdf',
  },
  {
    id: 1,
    title: 'Pneumonia',
    pdf_link: diagnosticToolsDtRespiratory + '1-Pneumonia-Diagnosis.pdf',
  },
  {
    id: 2,
    title: 'Tuberculosis',
    pdf_link: diagnosticToolsDtRespiratory + '1-TB-Diagnostic-Tools.pdf',
  },
  {
    id: 3,
    title: 'Asthma',
    pdf_link: diagnosticToolsDtRespiratory + 'Asthma-Diagnostic-Tool.pdf',
  },
  {
    id: 4,
    title: 'Bronchitis',
    pdf_link:
      diagnosticToolsDtRespiratory + 'BRONCHITIS-DIAGNOSIS-DOCUMENT-1.pdf',
  },
  {
    id: 5,
    title: 'COPD',
    pdf_link: diagnosticToolsDtRespiratory + 'COPD-Diagnosis.pdf',
  },
  {
    id: 6,
    title: 'Common Cold',
    pdf_link: diagnosticToolsDtRespiratory + 'Common-Cold-Diagnosis.pdf',
  },
  {
    id: 7,
    title: 'Lung Cancer',
    pdf_link: diagnosticToolsDtRespiratory + 'Lung-Cancer-Diagnosis.pdf',
  },
  {
    id: 8,
    title: 'Otitis Media',
    pdf_link: diagnosticToolsDtRespiratory + 'OM-Diagnosis.pdf',
  },
  {
    id: 9,
    title: 'Sinusitis',
    pdf_link: diagnosticToolsDtRespiratory + 'SINUSITIS-DIAGNOSIS.pdf',
  },
];
