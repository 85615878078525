// Endocrinology
import {basePdfUrl} from '../../../../../api/api';

const diagnosticToolsDtEndocrinology = basePdfUrl + 'dt/endocrinology/';

export const diagnosticToolsEndocrinologyData = [
  {
    id: 0,
    title: 'Adrenal Disorders',
    pdf_link:
      diagnosticToolsDtEndocrinology + 'Adrenal-Disorders-Diagnosis.pdf',
  },
  {
    id: 1,
    title: 'Diabetes',
    pdf_link: diagnosticToolsDtEndocrinology + 'Diabetes-Diagnosis.pdf',
  },
  {
    id: 2,
    title: 'Gonadal Disorders',
    pdf_link:
      diagnosticToolsDtEndocrinology +
      'GONADAL-DISORDERS-DIAGNOSTIC-CRITERIA.pdf',
  },
  {
    id: 3,
    title: 'Metabolic Disorder',
    pdf_link:
      diagnosticToolsDtEndocrinology + 'Metabolic-Disorder-Diagnosis.pdf',
  },
  {
    id: 4,
    title: 'Parathyroid',
    pdf_link: diagnosticToolsDtEndocrinology + 'Parathyrpid-Diagnosis.pdf',
  },
  {
    id: 5,
    title: 'Pituitary Disorders',
    pdf_link:
      diagnosticToolsDtEndocrinology + 'Pituitary-Disorders-Diagnosis.pdf',
  },
  {
    id: 6,
    title: 'Thyroid Disorders',
    pdf_link:
      diagnosticToolsDtEndocrinology + 'Thyroid-disorders-diagnosis.pdf',
  },
];
