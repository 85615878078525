import React, {useEffect, useRef, useState} from 'react';
import styled, {css, useTheme} from 'styled-components';
import {Theme} from '../../theme/lightTheme';
import TextInput from '../textInput';
import {motion} from 'framer-motion';
import DrugFilterSkeleton from '../drugFilterSkeleton';
import {AnimatedDiv, BlurContainer} from '../../screens/content/news';
import Icon from '../icon';
import {tr} from '../../translation';
import ErrorView from '../errorView';
import GenericButton from '../button';
import {
  FilterContainer,
  FilterItem,
  FilterItemContainer,
  FilterItemLabel,
} from '../drugFilterItem';
import {DrugItemType} from '../../interfaces/api/tabs';
import {SearchDrugProps} from '../../interfaces/components/searchDrug';
import {isSmallHeight} from '../../utils/responsive';

const zoomInOut = {
  zoomIn: {scale: 35, opacity: 1},
  zoomOut: {scale: 0, opacity: 0},
};

const Container = styled.div<{isOpen: boolean}>`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: ${isSmallHeight ? 1 : 2}rem;
  width: 100%;
  z-index: ${({isOpen}) => (isOpen ? 999 : 1)};
`;
const SearchInputContainer = styled(motion.div)`
  display: flex;
  align-self: flex-end;
  position: relative;
  z-index: 2;
  border-radius: 16px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  box-shadow: 0 2px 4px ${({theme}) => theme.shadow};
  backdrop-filter: blur(6px);
`;
const SearchInput = styled(TextInput)<{isOpen: boolean; $hideSearch: boolean}>`
  font-style: italic;
  font-size: ${({theme}) => theme.text.s8};
  color: ${({theme}) => theme.text.light};

  .input-container {
    height: ${isSmallHeight ? 2.5 : 3}rem;
    border-radius: ${({isOpen}) => (isOpen ? '8px 8px 0 0' : '8px')};
    min-width: ${isSmallHeight ? 2.5 : 3}rem;
  }
  .input {
    display: ${({$hideSearch}) => ($hideSearch ? 'flex' : 'none')};
  }
  &::placeholder {
    color: ${({theme}) => theme.text.light};
  }
`;
const Overlay = styled(motion.div)`
  width: 12vh;
  aspect-ratio: 1;
  border-radius: 50%;
  position: absolute;
  background-color: ${({theme}) => theme.primary + 'AA'};
  top: -2.5vh;
  right: -2.5vh;
  z-index: 1;
`;
const ContentContainer = styled(motion.div)<{isOpen: boolean}>`
  position: absolute;
  top: ${isSmallHeight ? 2.5 : 3}rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 40vh;
  width: 100%;
  background: ${({theme}) =>
    `linear-gradient(to bottom, ${theme.homeBackground + 'CC'}, ${theme.homeBackground + 'BB'})`};
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
  box-shadow: 0 8px 16px ${({theme}) => theme.shadow};
  backdrop-filter: blur(6px);
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  z-index: 2;
  ${({isOpen}) =>
    !isOpen &&
    css`
      pointer-events: none;
    `};
`;
const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
`;
const ConfirmSelection = styled(GenericButton)`
  display: flex;
  align-self: center;
`;
const EmptyText = styled.p`
  font-size: ${({theme}) => theme.text.s9};
  color: ${({theme}) => theme.text.light};
  width: 80%;
  padding-vertical: 4%;
  text-align: center;
`;
const ResultsBadgeContainer = styled(motion.div)`
  position: absolute;
  display: flex;
  right: ${({theme}) => `calc(-${theme.text.s6} / 2)`};
  bottom: ${({theme}) => `calc(-${theme.text.s6} / 2)`};
  height: ${({theme}) => theme.text.s6};
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.text.light};
`;
const ResultsBadge = styled.p`
  font-size: ${({theme}) => theme.text.s10};
  padding: ${({theme}) => theme.text.s10};
  color: ${({theme}) => theme.secondary};
  text-align: center;
`;
const root = document.getElementById('root');

/**
 * Search Drug Item component.
 *
 * @param {any} props - The Search Drug Item component props.
 * @returns {JSX.Element} The rendered Search Drug Item component.
 */
const SearchDrugs: React.FC<SearchDrugProps> = ({
  style,
  className,
  search,
  setSearch,
  isLoading,
  data,
  isError,
  isLastPage,
  selectedDrug,
  onConfirmSelection,
  loadMore,
  hideMenu,
  resultsNum,
}: SearchDrugProps): JSX.Element => {
  const theme = useTheme() as Theme;
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(selectedDrug || {});
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [positionY, setPositionY] = useState(0);

  const handleLayout = () => {
    if (wrapperRef.current) {
      const layout = wrapperRef.current.getBoundingClientRect();
      const offsetTop =
        window.innerHeight * (theme.isMobile ? 0.83 : 0.97) -
        (layout.y || 0) -
        (wrapperRef.current.offsetHeight || 0);
      setPositionY(offsetTop);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleLayout);
    handleLayout();
    return () => {
      window.removeEventListener('resize', handleLayout);
    };
  }, []);

  useEffect(() => {
    root.style.overflow = isOpen ? 'hidden' : 'auto';
    if (!isOpen) {
      setSearch('');
    }
  }, [isOpen]);

  const _renderFilterItem = (item: DrugItemType, index: number) => {
    const value = Object.values(selected || {})[0] as any;

    const isSelected = value?.id === item?.id;
    return (
      <FilterItemContainer
        key={index}
        $isSelected={isSelected}
        $isLeft={index % 2 === 0}>
        <FilterItem
          whileTap={{
            opacity: 0.9,
            scale: 0.9,
            shadow: `0 8px 16px ${theme.shadow}`,
          }}
          onClick={() => {
            const letter = (item?.name || '').slice(1).toUpperCase();
            if (letter) {
              setSelected({[letter]: item});
            }
          }}>
          <FilterItemLabel isSelected={isSelected}>
            {item?.name || ''}
          </FilterItemLabel>
        </FilterItem>
      </FilterItemContainer>
    );
  };

  const submit = () => {
    onConfirmSelection && onConfirmSelection(selected);
    setIsOpen(false);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const {scrollHeight, scrollTop, clientHeight} = e.currentTarget;
    if (
      scrollHeight - scrollTop <= clientHeight * 1.5 &&
      !isLoading &&
      !isLastPage &&
      !isError
    ) {
      loadMore();
    }
  };
  return (
    <Container
      ref={wrapperRef}
      isOpen={isOpen}
      style={style}
      className={className}>
      {!hideMenu && (
        <Overlay
          variants={zoomInOut}
          initial={'zoomOut'}
          animate={isOpen ? 'zoomIn' : 'zoomOut'}
          transition={{
            duration: 0.3,
          }}
          onClick={() => setIsOpen(false)}
        />
      )}
      <SearchInputContainer
        initial={{
          width: `calc(${theme.text.s55} * 3.6)`,
          backgroundColor: theme.secondary + 'AA',
        }}
        animate={{
          width: isOpen
            ? '100%'
            : `calc(${theme.text.s55} * ${theme.isMobile ? '3.6' : '2.8'})`,
          backgroundColor: isOpen
            ? hideMenu
              ? theme.secondary + '44'
              : theme.homeBackground + '11'
            : 'transparent',
        }}
        transition={{
          duration: 0.3,
          ease: 'easeInOut',
        }}
        onClick={isOpen ? undefined : () => setIsOpen(true)}>
        <SearchInput
          $hideSearch={isOpen}
          isOpen={!hideMenu && isOpen && (search || '').trim().length > 0}
          placeholder={isOpen ? 'Search ...' : ''}
          value={search || ''}
          onChange={e => setSearch && setSearch(e.target.value)}
          showRightIcon
          rightIconName={hideMenu && isOpen ? 'close' : 'search'}
          onRightIconPress={
            hideMenu && isOpen
              ? () => {
                  setIsOpen(false);
                }
              : undefined
          }
          rightIconType={'SVG'}
          rightIconSize={theme.text.s55}
        />
        {Number(resultsNum || 0) > 0 && (
          <ResultsBadgeContainer
            initial={{scale: 0.8, opacity: 0}}
            animate={{scale: 1, opacity: 1}}
            exit={{scale: 0.8, opacity: 0}}>
            <ResultsBadge>{resultsNum}</ResultsBadge>
          </ResultsBadgeContainer>
        )}
      </SearchInputContainer>
      {!hideMenu && (
        <ContentContainer
          isOpen={isOpen}
          initial={{height: 0, opacity: 0}}
          animate={{
            height: isOpen && (search || '').trim().length > 0 ? positionY : 0,
            opacity: isOpen && (search || '').trim().length > 0 ? 1 : 0,
          }}
          transition={{duration: isOpen ? 0.3 : 0.1, ease: 'easeInOut'}}>
          <FilterContainer
            onScroll={handleScroll}
            flex={!(!isLoading && !isError && (data || []).length === 0)}>
            {!isError &&
              data?.length > 0 &&
              (data || []).map(_renderFilterItem)}
            {isLoading && !isError && (data || []).length === 0 && (
              <DrugFilterSkeleton />
            )}
            <AnimatedDiv
              isZoomedIn={!isLoading && !isError && (data || []).length === 0}>
              <BlurContainer
                style={{
                  minWidth: '30%',
                  aspectRatio: 1,
                }}>
                <Icon
                  name={'empty'}
                  type={'SVG'}
                  color={theme.text.light}
                  size={theme.text.s3}
                />
                <EmptyText>{tr('drugs.empty')}</EmptyText>
              </BlurContainer>
            </AnimatedDiv>
            <AnimatedDiv isZoomedIn={isError}>
              <BlurContainer style={{minWidth: '30%', aspectRatio: 1}}>
                <ErrorView onRefresh={() => loadMore(true)} />
              </BlurContainer>
            </AnimatedDiv>
            <AnimatedDiv
              style={{
                display: data?.length > 0 ? 'flex' : 'none',
                marginTop: '1rem',
              }}
              isZoomedIn={
                isLoading && !isError && !isLastPage && data?.length > 0
              }>
              <BlurContainer>
                <Icon
                  name={'loader'}
                  type={'SVG'}
                  color={theme.text.light}
                  size={theme.text.s4}
                />
              </BlurContainer>
            </AnimatedDiv>
          </FilterContainer>
          <BottomContainer>
            <ConfirmSelection
              onPress={submit}
              disabled={
                !selected ||
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                Object.values(selectedDrug || {})[0]?.id ===
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  Object.values(selected || {})[0]?.id
              }
              labelStyle={{
                paddingLeft: theme.text.s6,
              }}
              style={{
                opacity:
                  !selected ||
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  Object.values(selectedDrug || {})[0]?.id ===
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    Object.values(selected || {})[0]?.id
                    ? 0.4
                    : 1,
              }}
              isSmall
              label={tr('drugs.confirmSelection')}
              showRightIcon
              rightIconName={'arrowDown'}
              rightIconType={'SVG'}
              rightIconSize={theme.text.s55}
              rightIconContainerStyle={{transform: 'rotate(-90deg)'}}
            />
          </BottomContainer>
        </ContentContainer>
      )}
    </Container>
  );
};

export default SearchDrugs;
