import Paths from '../../config/paths';

export const headerTabsData = [
  {
    label: 'home',
    iconName: 'home',
    pathname: Paths.content.home,
  },
  {
    label: 'favorite',
    iconName: 'favorite',
    pathname: Paths.content.favorite,
  },
  {
    label: 'voiceSearch',
    iconName: 'mic',
    isMainIcon: true,
    pathname: Paths.content.qna,
  },
  {
    label: 'myNotes',
    iconName: 'notes',
    pathname: Paths.content.notes,
  },
  {
    label: 'profile',
    iconName: 'account',
    pathname: Paths.content.profile,
  },
];
