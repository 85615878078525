// Ophthalmology Treatment Options
import {basePdfUrl} from '../../../../../api/api';

const treatmentOptionsToOphthalmology = basePdfUrl + 'to/ophthalmology/';

export const treatmentOptionsOphthalmologyData = [
  {
    id: 0,
    title: 'Age-Related Macular Degeneration',
    pdf_link: treatmentOptionsToOphthalmology + '2-AMD-Treatment-Options.pdf',
  },
  {
    id: 1,
    title: 'Eye Infections',
    pdf_link:
      treatmentOptionsToOphthalmology +
      '2-Eye-infections-treatment-options.pdf',
  },
  {
    id: 2,
    title: 'Cataract',
    pdf_link:
      treatmentOptionsToOphthalmology + 'Cataract-Treatment-Options.pdf',
  },
  {
    id: 3,
    title: 'Diabetic Retinopathy',
    pdf_link:
      treatmentOptionsToOphthalmology +
      'Diabetic-Retinopathy-Treatment-options.pdf',
  },
  {
    id: 4,
    title: 'Dry Eye Syndrome',
    pdf_link:
      treatmentOptionsToOphthalmology +
      'Dry-Eye-Syndrome-Treatment-Options.pdf',
  },
  {
    id: 5,
    title: 'Glaucoma',
    pdf_link:
      treatmentOptionsToOphthalmology + 'Glaucoma-Treatment-Options.pdf',
  },
  {
    id: 6,
    title: 'Graves Eye Disease',
    pdf_link:
      treatmentOptionsToOphthalmology +
      'Graves-Eye-Disease-Treatment-Options.pdf',
  },
  {
    id: 7,
    title: 'Idiopathic Intracranial Hypertension',
    pdf_link:
      treatmentOptionsToOphthalmology +
      'Idiopathic-Intracranial-Hypertension-Treatment-Options.pdf',
  },
  {
    id: 8,
    title: 'Refractive Eye Errors',
    pdf_link:
      treatmentOptionsToOphthalmology + 'Refractive-Eye-Errors-Treatment.pdf',
  },
  {
    id: 9,
    title: 'Retinoblastoma',
    pdf_link:
      treatmentOptionsToOphthalmology + 'Retinoblastoma-Treatment-Options.pdf',
  },
];
