// Pediatrics
import {basePdfUrl} from '../../../../../api/api';

const diagnosticToolsDtPediatrics = basePdfUrl + 'dt/pediatrics/';

export const diagnosticToolsPediatricsData = [
  {
    id: 0,
    title: 'Pediatric Pneumonia',
    pdf_link:
      diagnosticToolsDtPediatrics +
      '1-Pediatric-Pneumonia-Diagnostic-Tools.pdf',
  },
  {
    id: 1,
    title: 'Gastroenteritis and Constipation',
    pdf_link:
      diagnosticToolsDtPediatrics +
      '1-gastroenteritis-and-constipation-diagnosis.pdf',
  },
  {
    id: 2,
    title: 'Asthma',
    pdf_link: diagnosticToolsDtPediatrics + 'Asthma-Diagnostic-Tool.pdf',
  },
  {
    id: 3,
    title: 'Croup',
    pdf_link: diagnosticToolsDtPediatrics + 'Croup-Diagnosis.pdf',
  },
  {
    id: 4,
    title: 'Diagnosis of Allergic Rhinitis',
    pdf_link:
      diagnosticToolsDtPediatrics + 'Diagnosis-of-Allergic-Rhinitis.pdf',
  },
  {
    id: 5,
    title: 'Influenza',
    pdf_link: diagnosticToolsDtPediatrics + 'Influenza-Diagnosis.pdf',
  },
  {
    id: 6,
    title: 'Measles, Mumps, Rubella',
    pdf_link:
      diagnosticToolsDtPediatrics + 'Measles-Mumps-Rubella-Diagnosis.pdf',
  },
  {
    id: 7,
    title: 'Pediatric Hepatitis',
    pdf_link: diagnosticToolsDtPediatrics + 'Pediatric-Hepatitis-Diagnosis.pdf',
  },
  {
    id: 8,
    title: 'Pediatric Urinary Tract Infection',
    pdf_link:
      diagnosticToolsDtPediatrics +
      'Pediatric-Urinary-Tract-Infection-Diagnosis.pdf',
  },
];
