import * as React from 'react';

interface SvgProps extends React.SVGProps<SVGSVGElement> {
  fill?: string;
}

const SvgComponent: React.FC<SvgProps> = ({fill = '#0B0D6F', ...props}) => (
  <svg width={800} height={800} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fill={fill}
      d="m12.672 2.76 2.581 5.239h-.015l.002.003h6.008a.75.75 0 0 1 .743.648l.007.102a.75.75 0 0 1-.648.743l-.102.007h-6.48a.75.75 0 0 1-.623-.332l-.053-.093-.798-1.659L12 4.788 9.814 9.225a.75.75 0 0 1-.566.411l-4.895.707 3.545 3.45a.75.75 0 0 1 .216.665l-.84 4.873 4.339-2.286a2.248 2.248 0 0 0 .093 1.647l-5.08 2.676a.75.75 0 0 1-1.089-.79l1.031-5.986-4.352-4.236a.75.75 0 0 1 .416-1.28l6.01-.868 2.684-5.448a.75.75 0 0 1 1.346 0ZM21.25 17a.75.75 0 0 1 .102 1.493l-.102.007h-7.5a.75.75 0 0 1-.102-1.493L13.75 17h7.5Zm-.002-4.49a.75.75 0 0 1 .102 1.492l-.102.007h-7.5a.75.75 0 0 1-.102-1.493l.102-.007h7.5Z"
    />
  </svg>
);

export default SvgComponent;
