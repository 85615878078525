import {store} from '../../redux/store';
import {showToast, hideToast} from './reducer';
import {ToastMessage} from '../../interfaces/connected-components/toast';

/**
 * Shows a global modal toast with the specified message.
 *
 * @param {Object} toast - The toast message object.
 * @param {string} toast.message - The message to be displayed in the toast.
 * @returns {void}
 */
export const showGlobalToast = ({message, type}: any): void => {
  store.dispatch(
    showToast({
      message,
      type: type || 'info',
    }),
  );
};

/**
 * Closes the global modal toast.
 *
 * @returns {void}
 */
export const closeGlobalToast = () => {
  store.dispatch(hideToast());
};
