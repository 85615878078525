// Anti-Infective Treatment Options
import {basePdfUrl} from '../../../../../api/api';

const treatmentOptionsToAnti = basePdfUrl + 'to/anti/';

export const treatmentOptionsAntiData = [
  {
    id: 0,
    title: 'Amebiasis',
    pdf_link: treatmentOptionsToAnti + 'Amebiasis-Treatment-Options.pdf',
  },
  {
    id: 1,
    title: 'Aspergillosis',
    pdf_link: treatmentOptionsToAnti + 'Aspergillosis-Treatment-Options.pdf',
  },
  {
    id: 2,
    title: 'Coronavirus Disease',
    pdf_link:
      treatmentOptionsToAnti + 'Coronavirus-Disease-19-Treatment-Options.pdf',
  },
  {
    id: 3,
    title: 'Candidiasis',
    pdf_link: treatmentOptionsToAnti + '2-Candidiasis-Treatment-Options.pdf',
  },
  {
    id: 4,
    title: 'Leishmaniasis',
    pdf_link: treatmentOptionsToAnti + 'Leishmaniasis-Treatment-Options-.pdf',
  },
  {
    id: 5,
    title: 'Lyme Disease',
    pdf_link: treatmentOptionsToAnti + 'Lyme-disease-Treatment-options-.pdf',
  },
  {
    id: 6,
    title: 'Malaria',
    pdf_link: treatmentOptionsToAnti + 'Malaria-Treatment-Options-.pdf',
  },
  {
    id: 7,
    title: 'Meningitis',
    pdf_link: treatmentOptionsToAnti + 'Meningitis-Treatment-Option.pdf',
  },
  {
    id: 8,
    title: 'Respiratory Syncytial Virus',
    pdf_link:
      treatmentOptionsToAnti + 'Respiratory-Syncytial-Virus-Treatment.pdf',
  },
  {
    id: 9,
    title: 'Syphilis',
    pdf_link: treatmentOptionsToAnti + '2-Syphilis-Treatment-Options.pdf',
  },
];
