/**
 * Mapping of screen names to their corresponding page names.
 * @type {object}
 */
const paths = {
  auth: {
    login: '/login',
    onboarding: '/intro',
    register: '/register',
    forgetPassword: '/forgot-password',
    afterLogin: '/fill-your-information',
  },
  content: {
    home: '/',
    medicalUpdates: '/medical-updates',
    afterLogin: '/after-login',
    calculators: '/calculators',
    pdfViewer: '/pdf',
    tabNews: '/tab-news',
    article: '/article',
    news: '/news',
    search: '/search',
    drugs: '/drugs',
    diseases: '/diseases',
    summaries: '/summaries',
    favorite: '/favorite',
    contactUs: '/contact-us',
    viewNote: '/view-note',
    editNote: '/edit-note',
    notes: '/notes',
    chooseYourSpeciality: '/choose-your-speciality',
    settings: '/settings',
    genioChat: '/genio-chat',
    qna: '/QNA',
    enterClinicalCase: '/clinical-case-details',
    profile: '/profile',
    privacyPolicy: '/privacy-policy',
    privacyPolicyForm: '/privacy-policy-form',
    termsConditions: '/terms-and-conditions',
    clinicalCase: '/clinical-case',
    logout: '/logout',
  },
  shared: {
    sharedArticle: 'shared-article/:token',
    notFound: '*',
  },
};

export default paths;
