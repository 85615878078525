import React, {FC, SVGProps} from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  fill?: string;
}

const SvgComponent: FC<Props> = ({fill = '#000', ...props}) => {
  return (
    <svg
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      stroke="none"
      fill="none"
      {...props}>
      <path
        fill={fill}
        d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 0 1-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"
      />
    </svg>
  );
};

export default SvgComponent;
