import {services} from '../../../api';
import {showGlobalToast} from '../../../connected-components/toast/actions';
import {
  GetSummariesByDateBody,
  SummariesByDateData,
  SummaryDetailsItemType,
} from '../../../interfaces/api/summaries';
import {
  GetPredictionsByDateBody,
  PredictionDetailsItemType,
  PredictionsByDateData,
} from '../../../interfaces/api/predictions';
import {
  AnalysisByDateData,
  AnalysisDetailsItemType,
  GetAnalysisByDateBody,
} from '../../../interfaces/api/analysis';
import {tr} from '../../../translation';

/**------------ Summaries ------------**/

/**
 * this function to get Summary Details By id
 *
 * @return {SummaryDetailsItemType | null} result of Summary Details
 */
export const getSummaryDetails = async (
  id: number,
): Promise<SummaryDetailsItemType | null> => {
  try {
    if (!id) {
      throw new Error('id is required');
    }
    const res = await services.summaries.getById(id);

    if (Array.isArray(res?.data)) {
      return res?.data.length > 0 ? res?.data[0] : null;
    }

    return res?.data;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Get Summary Details By Id FAIL: ', error);
    }
    const message = error?.response?.data?.error?.message
      ? error?.response?.data?.error?.message
      : tr('app.error');

    showGlobalToast({
      message: message,
      type: 'error',
    });
    return null;
  }
};

/**
 * this function to get Summaries List By Date and tab id
 *
 * @return {SummariesByDateData | null} result of Summaries List
 */
export const getSummariesByDate = async (
  body: GetSummariesByDateBody,
): Promise<SummariesByDateData | null> => {
  try {
    if (!body?.tabId || !body?.dateFrom) {
      throw new Error('tabId and dateFrom are required');
    }
    const res = await services.summaries.getByDate(body);

    return res?.data;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Get Summaries By Tab Id And Date FAIL: ', error);
    }
    const message = error.response?.data?.error?.message
      ? error.response?.data?.error?.message
      : tr('app.error');

    showGlobalToast({
      message: message,
      type: 'error',
    });
    return null;
  }
};

/**
 * this function to get Summary voice url
 *
 * @return {{voiceURL: string }  | null} result of Summary voice url
 */
export const getSummaryVoiceUrl = async (
  id: number,
): Promise<{voiceURL: string} | null> => {
  try {
    if (!id) {
      throw new Error('Id is required');
    }
    const res = await services.summaries.getVoiceUrl(id);
    return res?.data;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Get Summary Voice Url FAIL: ', error);
    }
    const message = error.response?.data?.error?.message
      ? error.response?.data?.error?.message
      : tr('app.error');

    showGlobalToast({
      message: message,
      type: 'error',
    });
    return null;
  }
};

/**------------ Predictions ------------**/

/**
 * This function retrieves Prediction Details by ID.
 *
 * @return {PredictionDetailsItemType | null} Result of Prediction Details.
 */
export const getPredictionDetails = async (
  id: number,
): Promise<PredictionDetailsItemType | null> => {
  try {
    if (!id) {
      throw new Error('id is required');
    }
    const res = await services.predictions.getById(id);
    let predictItem = null;
    if (Array.isArray(res?.data)) {
      predictItem = res?.data.length > 0 ? res?.data[0] : null;
    } else {
      predictItem = res?.data;
    }
    if (predictItem) {
      predictItem = {
        summary: predictItem?.predict,
        summarydate: predictItem.predictdate,
        ...predictItem,
      };
    }

    return predictItem;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Get Prediction Details By Id FAIL: ', error);
    }
    const message = error?.response?.data?.error?.message
      ? error?.response?.data?.error?.message
      : tr('app.error');

    showGlobalToast({
      message: message,
      type: 'error',
    });
    return null;
  }
};

/**
 * This function retrieves Predictions List by Date and Tab ID.
 *
 * @return {PredictionsByDateData | null} Result of Predictions List.
 */
export const getPredictionsByDate = async (
  body: GetPredictionsByDateBody,
): Promise<PredictionsByDateData | null> => {
  try {
    if (!body?.tabId || !body?.dateFrom) {
      throw new Error('tabId and dateFrom are required');
    }
    const res = await services.predictions.getByDate(body);

    const news = (res?.data?.news || [])?.map(i => {
      return {
        summary: i?.predict,
        summarydate: i.predictdate,
        ...(i || {}),
      };
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return {...(res?.data || {}), news};
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Get Predictions By Tab Id And Date FAIL: ', error);
    }
    const message = error.response?.data?.error?.message
      ? error.response?.data?.error?.message
      : tr('app.error');

    showGlobalToast({
      message: message,
      type: 'error',
    });
    return null;
  }
};

/**------------ Analysis ------------**/

/**
 * This function retrieves Analysis Details by ID.
 *
 * @return {AnalysisDetailsItemType | null} Result of Analysis Details.
 */
export const getAnalysisDetails = async (
  id: number,
): Promise<AnalysisDetailsItemType | null> => {
  try {
    if (!id) {
      throw new Error('id is required');
    }
    const res = await services.analysis.getById(id);

    if (Array.isArray(res?.data)) {
      return res?.data.length > 0 ? res?.data[0] : null;
    }
    let analysisItem = null;
    if (Array.isArray(res?.data)) {
      analysisItem = res?.data.length > 0 ? res?.data[0] : null;
    } else {
      analysisItem = res?.data;
    }
    if (analysisItem) {
      analysisItem = {
        summary: analysisItem?.analysis,
        summarydate: analysisItem.analysisdate,
        ...analysisItem,
      };
    }
    return analysisItem;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Get Analysis Details By Id FAIL: ', error);
    }
    const message = error?.response?.data?.error?.message
      ? error?.response?.data?.error?.message
      : tr('app.error');

    showGlobalToast({
      message: message,
      type: 'error',
    });
    return null;
  }
};

/**
 * This function retrieves Analysis List by Date and Tab ID.
 *
 * @return {AnalysisByDateData | null} Result of Analysis List.
 */
export const getAnalysisByDate = async (
  body: GetAnalysisByDateBody,
): Promise<AnalysisByDateData | null> => {
  try {
    if (!body?.tabId || !body?.dateFrom) {
      throw new Error('tabId and dateFrom are required');
    }
    const res = await services.analysis.getByDate(body);
    const news = (res?.data?.news || [])?.map(i => {
      return {
        summary: i?.analysis,
        summarydate: i.analysisdate,
        ...(i || {}),
      };
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    return {...(res?.data || {}), news};
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Get Analysis By Tab Id And Date FAIL: ', error);
    }
    const message = error.response?.data?.error?.message
      ? error.response?.data?.error?.message
      : tr('app.error');

    showGlobalToast({
      message: message,
      type: 'error',
    });
    return null;
  }
};
