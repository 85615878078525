import React, {useEffect, useRef, useState} from 'react';
import styled, {createGlobalStyle, css, useTheme} from 'styled-components';
import {useLocation, useNavigate} from 'react-router-dom';
import {Theme} from '../../../theme/lightTheme';
import {
  GenerateSharedLinkBody,
  NewsItemDetailsType,
} from '../../../interfaces/api/tabs';
import {getRecommendationDetails} from '../home/actions';
import {getNewsDetails} from '../tabNews/actions';
import {SlArrowDown} from 'react-icons/sl';
import {showGlobalToast} from '../../../connected-components/toast/actions';
import {isLanguageRTL, isMp3Available, sleep} from '../../../utils/helper';
import {checkIfFavorite, toggleFavorite} from '../favorite/actions';
import Icon from '../../../components/icon';
import {BottomSheet, BottomSheetRef} from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import TableView from '../../../components/tableView';
import {tr} from '../../../translation';
import GenericButton from '../../../components/button';
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions';
import backgroundImage from '../../../assets/images/background.jpg';

import Paths from '../../../config/paths';
import {PiTranslate} from 'react-icons/pi';
import {openLanguagePickerModal} from '../../../connected-components/languagePickerModal/actions';
import ArticleSkeleton from '../../../components/articleSkeleton';
import Tooltip from '../../../components/tooltip';
import PlayerIcon from '../../../components/playerIcon';
import {useGlobalAudioPlayer} from 'react-use-audio-player';
import {useSelector} from 'react-redux';
import {RootState, store} from '../../../redux/store';
import {translateTableArticle} from '../../../utils/translation';
import {useSetMainLayoutProps} from '../../../route/routeMainLayout/context';
import useScrollTop from '../../../utils/hooks/useScrollTop';
import AnimatedRouterLayout from '../../../components/animatedRouterLayout';
import {
  ObjectFunction,
  useUrlSearchParams,
} from '../../../utils/hooks/useURLSearchParams';
import {
  getAnalysisDetails,
  getPredictionDetails,
  getSummaryDetails,
  getSummaryVoiceUrl,
} from '../summaries/actions';
import {NewsOptionTab} from '../../../utils/enums';
import {login} from '../../auth/login/reducer';
import {showGlobalModal} from '../../../connected-components/globalModal/actions';
import {renderArticleContent} from '../../../utils/html';
import {generateShareLink} from './actions';
import {RWebShare} from '../../../components/share';
import {ShareData} from '../../../components/share/interfaces';

const Container = styled(AnimatedRouterLayout)`
  display: flex;
  flex: 1;
  position: relative;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0 15% 5% 15%;
  @media (max-width: 550px) {
    padding: 5%;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const ContentContainer = styled.div`
  width: 95%;
  margin: 2% auto;
  user-select: text;
`;

const Title = styled.p<{isLanguageRTL: boolean}>`
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s7};
  line-height: ${({theme}) => `calc(${theme.text.s7} * 1.4)`};
  font-weight: bold;
  padding-bottom: ${({theme}) => (theme.isMobile ? '5%' : '3%')};
  ${({isLanguageRTL}) =>
    isLanguageRTL &&
    css`
      direction: rtl;
    `};
`;
const BottomSheetTitle = styled.p<{customFontSize: number}>`
  color: ${({theme}) => theme.text.light};
  font-size: ${({customFontSize}) => customFontSize + 2}px;
  line-height: ${({customFontSize}) => (customFontSize + 2) * 1.4}px;
  font-weight: bold;
  padding: ${({theme}) => (theme.isMobile ? '5%' : '2%')};
`;
const BottomSheetDescription = styled.p`
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s7};
  line-height: ${({theme}) => `calc(${theme.text.s7} * 1.4)`};
  padding: 0 ${({theme}) => (theme.isMobile ? '5%' : '2%')};
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: ${({theme}) => (theme.isMobile ? '5%' : '3%')};
`;
const RightIconsContainer = styled(IconsContainer)`
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  width: ${({theme}) => `calc(${theme.text.s6}* 2)`};
  height: ${({theme}) => `calc(${theme.text.s6}* 2)`};
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    ${({theme}) => theme.primary},
    ${({theme}) => theme.secondary}
  );
  border-radius: 8px;
  &:active {
    scale: 0.95;
    opacity: 0.7;
  }
`;

const BottomSheetHeaderContainer = styled.div`
  background-color: ${({theme}: {theme: Theme}) => theme.secondary};
  width: 100%;
  height: ${({theme}: {theme: Theme}) => `calc(${theme.text.s55}* 1.7)`};
  align-items: center;
  justify-content: center;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;

  &:active {
    background-color: ${({theme}: {theme: Theme}) => theme.primary};
  }
`;

const BottomSheetView = styled(BottomSheet)`
  color: ${({theme}) => theme.text.light};
  --rsbs-bg: transparent;
  [data-rsbs-header] {
    padding: 0;
    &::before {
      content: '';
      margin: 0;
      opacity: 0;
    }
  }
`;
const BottomSheetContent = styled.div`
  width: ${({theme}) => theme.dimensions.wdp(100)}px;
  height: ${({theme}) => theme.dimensions.hdp(90)}px;
  color: ${({theme}) => theme.text.light};
  background-image: url(${backgroundImage}),
    linear-gradient(
      to bottom,
      ${({theme}) => theme.primary},
      ${({theme}) => theme.secondary}
    );
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  background-attachment: fixed;
  user-select: text;
`;
const BottomSheetContentContainer = styled.div`
  width: 100%;
  height: 100%;
  align-self: center;
  z-index: 2;
  background-color: ${({theme}) => theme.overlayScreen};
  overflow-y: scroll;
  padding-bottom: ${({theme}) => (theme.isMobile ? '7rem' : 0)};
`;
const GlobalLinkStyle = createGlobalStyle`
    a {
        color: ${({theme}) => theme.text.light};
        font-weight: bold;
        word-break: break-all;
    }
`;
export const TooltipContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: 2rem;
  align-self: flex-start;
`;

const tableTranslations = {
  speciality: 'Specialty',
  subspeciality: 'Topic',
  uri: 'Link /\nReference',
  publisher: 'Publisher',
  resource: 'Resource',
  origin: 'Origin',
  readabilityscore: 'Readability score',
  sentiment: 'Sensation',
  accesslevel: 'Access Level',
  date: 'Date',
  typeofstudiesandreaserch: 'Type of the study',
  summary: 'Summary',
  viewMore: 'View More',
  successMessage:
    'The content has been translated for your convenience using Google Translate',
};

const loginRequiredHandler = () => {
  return showGlobalModal({
    title: tr('article.loginRequiredTitle'),
    message: tr('article.loginRequiredMessage'),
    onConfirm: () => store.dispatch(login(null)),
    confirmText: tr('article.loginRequiredConfirm'),
    type: 'question',
  });
};

const Article = () => {
  const theme = useTheme() as Theme;
  const userStatus = useSelector((state: RootState) => state?.auth);
  const isSharedArticle =
    !((userStatus?.currentUser || {}).token || '') ||
    ((userStatus?.currentUser || {}).token || '') ===
      process.env.REACT_APP_OPEN_ACCESS_ARTICLE_TOKEN;

  useSetMainLayoutProps({
    overlayColor: theme.overlayScreen,
    showHeader: true,
    showHeaderTabs: !isSharedArticle,
    showMenu: !isSharedArticle,
    showBack: true,
    onBackPress: isSharedArticle ? loginRequiredHandler : undefined,
    showSearch: false,
  });

  useScrollTop();
  const navigation = useNavigate();
  const location = useLocation();
  const {font} = userStatus?.config || {};
  const {height} = useWindowDimensions();
  const [searchParams] = useUrlSearchParams(
    location.state,
    {
      item: ObjectFunction,
      isSummary: String,
      isRecommendation: ObjectFunction,
      isRecommendationByTab: ObjectFunction,
    },
    true,
  ) as any;
  const {id, title, isRecommendation, isRecommendationByTab, isSummary} =
    searchParams || {};
  const item = searchParams?.item || {id, title};
  const [language, setLanguage] = useState<any>('en');
  const [isLoading, setIsLoading] = useState(true);
  const [isVoiceLoading, setIsVoiceLoading] = useState(false);
  const [isVoiceError, setIsVoiceError] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<NewsItemDetailsType>({});
  const [isFavorite, setFavorite] = useState(false);
  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
  const focusRef = useRef<HTMLButtonElement>();
  const sheetRef = useRef<BottomSheetRef>();
  const [usedLanguagesData, setUsedLanguagesData] = useState<string[]>([
    'English',
  ]);
  const usedLanguagesRef = useRef<string[]>(usedLanguagesData);
  // sound
  const audio = useGlobalAudioPlayer();

  useEffect(() => {
    usedLanguagesRef.current = usedLanguagesData;
  }, [usedLanguagesData]);

  const initPage = async () => {
    checkFavorite();
    if (!item?.id) {
      return;
    }
    setIsLoading(true);
    setIsError(false);
    let res = null;
    if (isRecommendationByTab) {
      res = await getRecommendationDetails(item?.id);
    } else if (isSummary) {
      if (isSummary.replaceAll('"', '') === NewsOptionTab.Analysis) {
        res = await getAnalysisDetails(item?.id);
      } else if (isSummary.replaceAll('"', '') === NewsOptionTab.Predictions) {
        res = await getPredictionDetails(item?.id);
      } else {
        res = await getSummaryDetails(item?.id);
      }
    } else {
      res = await getNewsDetails(item?.id);
    }
    if (!res) {
      setIsError(true);
      setIsLoading(false);
      return;
    }
    setData(res || {});
    setIsLoading(false);
  };

  useEffect(() => {
    const token = new URL(window.location.href || '').searchParams.get('token');
    if (!!userStatus?.currentUser?.token && !token) {
      initPage();
    }
    return () => {
      audio.stop();
    };
  }, [searchParams, userStatus]);

  const [tableTranslationData, setTableTranslationData] = useState<any>({
    ...(item || {}),
  });

  const changeLanguageHandler = async (): Promise<any> => {
    try {
      setIsLoading(true);
      const [res, resLabels] = await Promise.all([
        translateTableArticle(
          language,
          {
            ...(data || {}),
            title: isSummary
              ? `Genio ${
                  isSummary.replaceAll('"', '') === NewsOptionTab.Predictions
                    ? 'Future Outcomes'
                    : isSummary.replaceAll('"', '') === NewsOptionTab.Analysis
                      ? 'Analysis'
                      : 'Briefs'
                }`
              : data.title || item?.title,
          },
          [
            'id',
            'uri',
            'publishdate',
            'readabilityscore',
            'creationdate',
            'speciality_id',
            'subspeciality_id',
            'type_of_study_id',
            'resource_id',
            'tab_id',
            'img',
            'sentiment_id',
            'winid',
            'normalsummarization',
          ],
        ),
        translateTableArticle(language, {
          ...(tableTranslations || {}),
        }),
      ]);
      if (res && resLabels) {
        setTableTranslationData({
          ...res,
          tableTranslations: resLabels,
          title: res?.title || item?.title,
        });
        if (language !== 'en') {
          showGlobalToast({
            message:
              resLabels?.successMessage ||
              'The content has been translated for your convenience using Google Translate.',
            type: 'info',
          });
        }
      }
      setIsLoading(false);
    } catch (error) {
      if (process.env.REACT_APP_DEBUG === 'true') {
        console.error('Error in translateTableArticle calls:', error);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      if (isSharedArticle) {
        store.dispatch(login(null));
      }
    };
  }, []);

  useEffect(() => {
    if (!!userStatus?.currentUser?.token && Object.keys(data).length > 0) {
      changeLanguageHandler();
    }
  }, [language, data, userStatus]);

  const initVoice = async () => {
    if (!item?.id) {
      setIsVoiceError(true);
      return;
    }
    setIsVoiceError(false);
    setIsVoiceLoading(true);
    const res = await getSummaryVoiceUrl(item?.id);
    if (res && res?.voiceURL) {
      let retires = 10;
      await sleep(2000);
      let available = await isMp3Available(res?.voiceURL);

      while (!available) {
        if (retires <= 0) {
          setIsVoiceLoading(false);
          setIsVoiceError(true);
          return;
        }
        retires -= 1;
        await sleep(2000);
        available = await isMp3Available(res?.voiceURL);
      }
      audio.load(res?.voiceURL || '');
    } else {
      setIsVoiceError(true);
    }
    setIsVoiceLoading(false);
  };

  useEffect(() => {
    if (
      isSummary &&
      isSummary.replaceAll('"', '') === NewsOptionTab.Summaries &&
      userStatus?.currentUser?.token
    ) {
      initVoice();
    }
  }, [isSummary, data, userStatus]);

  const onFavoritePressHandler = () => {
    setFavorite(!isFavorite);
    toggleFavorite({
      ...item,
      isSummary,
      isRecommendation,
      isRecommendationByTab,
    });
  };

  const checkFavorite = async () => {
    const check = await checkIfFavorite(item);
    setFavorite(check);
  };

  const _renderBottomSheetHeader = () => {
    return (
      <BottomSheetHeaderContainer onClick={() => setBottomSheetOpen(false)}>
        <SlArrowDown size={theme.text.s4} color={theme.text.light} />
      </BottomSheetHeaderContainer>
    );
  };

  const shareDetails = async (): Promise<ShareData> => {
    try {
      let url = `${process.env.REACT_APP_DOMAIN}${Paths.content.article}?`;
      const body: GenerateSharedLinkBody = {
        article_id: item?.id,
        shared_via: 'website',
      };
      if (isRecommendation) {
        body.isRecommendation = isRecommendation;
      }
      if (isRecommendationByTab) {
        body.isRecommendationByTab = isRecommendationByTab;
      }
      if (isSummary) {
        body.isSummary = isSummary;
      }
      const token = await generateShareLink(body);
      if (token) {
        url += `token=${token}`;
        // Trigger the share dialog
        return {
          title: 'Check news details',
          url,
        };
      }
      return null;
    } catch (e) {
      if (process.env.REACT_APP_DEBUG === 'true') {
        console.log('e', e);
      }
      return null;
    }
  };

  const renderSeeMore = () => (
    <BottomSheetView
      ref={sheetRef}
      open={bottomSheetOpen}
      header={_renderBottomSheetHeader()}
      onDismiss={() => setBottomSheetOpen(false)}
      skipInitialTransition
      // sibling={
      //   <div style={{width: '100%', height: '100%', backgroundColor: 'red'}} />
      // }
      initialFocusRef={focusRef}
      defaultSnap={({maxHeight}) => {
        return maxHeight * 0.9;
      }}
      snapPoints={({maxHeight}) => [maxHeight * 0.9]}
      maxHeight={height}
      expandOnContentDrag>
      <BottomSheetContent>
        <BottomSheetContentContainer>
          <BottomSheetTitle customFontSize={font.fontSize}>
            {tableTranslations?.summary || 'Summary'}:
          </BottomSheetTitle>
          <BottomSheetDescription>
            {renderArticleContent(
              tableTranslationData?.paraphrasesummarization ||
                tableTranslationData?.summary ||
                '',
              {
                isRTL: isLanguageRTL(language),
                color: theme.text.light,
                fontSize: font.fontSize,
                fontWeight: font.fontWeight,
              },
            )}
          </BottomSheetDescription>
        </BottomSheetContentContainer>
      </BottomSheetContent>
    </BottomSheetView>
  );
  return (
    <>
      <Container>
        <ContentContainer>
          {!!(tableTranslationData?.title || item?.title) && (
            <Title isLanguageRTL={!isLoading && isLanguageRTL(language)}>
              {tableTranslationData?.title || item?.title || ''}
            </Title>
          )}
          <IconsContainer>
            <GenericButton
              isSmall
              style={{
                padding: '0 0.5rem',
                borderRadius: 8,
                height: `calc(${theme.text.s6}* 2)`,
                opacity: isSharedArticle ? 0 : 1,
                pointerEvents: isSharedArticle ? 'none' : 'auto',
              }}
              onPress={() => {
                navigation(Paths.content.editNote, {
                  state: {
                    news: {
                      ...item,
                      isSummary,
                      isRecommendation,
                      isRecommendationByTab,
                    },
                  },
                });
              }}
              label={tr('screens.addNotePage')}
              showRightIcon
              rightIconType={'SVG'}
              rightIconName={'add'}
              rightIconSize={theme.text.s4}
              labelStyle={{
                padding: '0 1rem',
              }}
            />
            <RightIconsContainer>
              {isSummary &&
                language === 'en' &&
                isSummary.replaceAll('"', '') === NewsOptionTab.Summaries && (
                  <PlayerIcon
                    onClick={audio.togglePlayPause}
                    iconName={audio?.playing ? 'play' : 'pause'}
                    isError={isVoiceError}
                    isReady={
                      audio.isReady && !audio.isLoading && !isVoiceLoading
                    }
                  />
                )}
              <RWebShare generateData={shareDetails}>
                <IconContainer
                  style={{
                    marginLeft: theme.text.s55,
                    marginRight: theme.text.s55,
                  }}>
                  <Icon
                    type={'SVG'}
                    name={'share'}
                    color={theme.text.light}
                    size={theme.text.s5}
                  />
                </IconContainer>
              </RWebShare>
              <IconContainer
                style={{
                  marginRight: theme.text.s55,
                }}
                onClick={() =>
                  openLanguagePickerModal({
                    onSelectionChange: lang => {
                      setLanguage(lang?.code);
                      setUsedLanguagesData(prev => [...prev, lang?.name]);
                    },
                    currentValue: language,
                  })
                }>
                <PiTranslate color={theme.text.light} size={theme.text.s55} />
              </IconContainer>
              {!isSharedArticle && (
                <IconContainer onClick={() => onFavoritePressHandler()}>
                  <Icon
                    type={'SVG'}
                    name={isFavorite ? 'heart' : 'hearto'}
                    color={theme.text.light}
                    size={theme.text.s5}
                  />
                </IconContainer>
              )}
            </RightIconsContainer>
          </IconsContainer>
          {isLoading || isError ? (
            <ArticleSkeleton isSummary={isSummary} />
          ) : item?.id && !isSummary ? (
            <TableView
              isLanguageRTL={!isLoading && isLanguageRTL(language)}
              onCustomSeeMorePress={() => {
                setBottomSheetOpen(true);
                // sheetRef?.current?.snapToIndex(0);
              }}
              {...tableTranslationData}
            />
          ) : (
            renderArticleContent(
              tableTranslationData?.description ||
                tableTranslationData?.summary ||
                item?.description ||
                item?.summary,
              {
                isRTL: isLanguageRTL(language),
                color: theme.text.light,
                fontSize: font.fontSize,
                fontWeight: font.fontWeight,
              },
            )
          )}

          <TooltipContainer>
            {!isLoading && (
              <Tooltip
                text={userStatus?.currentUser?.disclaimer || tr('latest.tip')}>
                <GenericButton
                  style={{borderRadius: 8}}
                  disabledNoOpacity
                  isSmall
                  label={tr('latest.disclaimer')}
                  showRightIcon
                  rightIconType={'SVG'}
                  rightIconName={'info'}
                  rightIconSize={theme.text.s55}
                />
              </Tooltip>
            )}
          </TooltipContainer>
        </ContentContainer>
      </Container>
      {renderSeeMore()}
      <GlobalLinkStyle />
    </>
  );
};

export default Article;
