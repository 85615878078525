import {createGlobalStyle} from 'styled-components';
import {getFontFamily} from './translation/actions';

export const GlobalStyle = createGlobalStyle`
   @font-face {
     font-family: 'BBC Reith Sans';
     src: url('/fonts/BBCReithSans-Regular.ttf') format('truetype');
     font-weight: 400;
     font-style: normal;
     font-display: fallback;
   }

   @font-face {
     font-family: 'BBC Reith Sans';
     src: url('/fonts/BBCReithSans-Medium.ttf') format('truetype');
     font-weight: 500;
     font-style: normal;
     font-display: fallback;
   }

   @font-face {
     font-family: 'BBC Reith Sans';
     src: url('/fonts/BBCReithSans-Bold.ttf') format('truetype');
     font-weight: 700;
     font-style: normal;
     font-display: fallback;
   }

   ::view-transition-old(root),
   ::view-transition-new(root) {
       animation: none;
       mix-blend-mode: normal;
   }
   
   * {
       //border-inline: 1px solid red;
       box-sizing: border-box;
   }
   
   body {
     box-sizing: border-box;
     margin: 0;
     padding: 0;
     font-family: ${({theme}) => getFontFamily(theme)};
     user-select: none;
   }

   input {
       &:-webkit-autofill,
       &:-webkit-autofill:focus {
           transition:
                   background-color 0s 600000s,
                   color 0s 600000s !important;
       }
   }

   img {
    pointer-events: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
   }
   
   p {
       margin: 0;
       white-space: pre-line;
   }
   
   h1,
   h2,
   h3,
   h4,
   h5,
   h6 {
       margin: 0;
   }
  
`;
