// Rheumatology Guidelines
import {basePdfUrl} from '../../../../../api/api';

const guidelinesGlRheumatology = basePdfUrl + 'gl/rheumatology/';

export const guidelinesRheumatologyData = [
  {
    id: 0,
    title: 'Osteoporosis',
    pdf_link: guidelinesGlRheumatology + '3-Osteoporosis-Guidelines.pdf',
  },
  {
    id: 1,
    title: 'Rheumatoid Arthritis',
    pdf_link:
      guidelinesGlRheumatology + '3-Rheumatoid-Arthritis-Guidelines.pdf',
  },
  {
    id: 2,
    title: 'Sports Medicine',
    pdf_link: guidelinesGlRheumatology + '3-Sports-Medicine-Guidelines.pdf',
  },
  {
    id: 3,
    title: 'Ankylosing Spondylitis',
    pdf_link:
      guidelinesGlRheumatology + 'Ankylosing-Spondylitis-Guidelines.pdf',
  },
  {
    id: 4,
    title: 'Bone Fracture',
    pdf_link: guidelinesGlRheumatology + 'Bone-Fracture-Guidelines.pdf',
  },
  {
    id: 5,
    title: 'Gout',
    pdf_link: guidelinesGlRheumatology + 'Gout-Guidelines.pdf',
  },
  {
    id: 6,
    title: 'Osteoarthritis',
    pdf_link: guidelinesGlRheumatology + 'Osteoarthritis-Guidelines.pdf',
  },
  {
    id: 7,
    title: 'Psoriatic Arthritis',
    pdf_link: guidelinesGlRheumatology + 'Psoriatic-Arthritis-Guidelines.pdf',
  },
  {
    id: 8,
    title: 'Spinal Disorders',
    pdf_link: guidelinesGlRheumatology + 'Spinal-Disorders-Guidelines.pdf',
  },
];
