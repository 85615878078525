// Anti-Infective Guidelines
import {basePdfUrl} from '../../../../../api/api';

const guidelinesGlAnti = basePdfUrl + 'gl/anti/';

export const guidelinesAntiData = [
  {
    id: 0,
    title: 'Amebiasis',
    pdf_link: guidelinesGlAnti + 'Amebiasis-Guidelines.pdf',
  },
  {
    id: 1,
    title: 'Aspergillosis',
    pdf_link: guidelinesGlAnti + 'Aspergillosis-Guidelines.pdf',
  },
  {
    id: 2,
    title: 'COVID-19',
    pdf_link: guidelinesGlAnti + 'COVID-19-Guidelines.pdf',
  },
  {
    id: 3,
    title: 'Candidiasis',
    pdf_link: guidelinesGlAnti + '3-Candidiasis-Guidelines.pdf',
  },
  {
    id: 4,
    title: 'Leishmaniasis',
    pdf_link: guidelinesGlAnti + 'Leishmaniasis-Guidelines.pdf',
  },
  {
    id: 5,
    title: 'Lyme Disease',
    pdf_link: guidelinesGlAnti + 'Lyme-disease-Guidelines.pdf',
  },
  {
    id: 6,
    title: 'Malaria',
    pdf_link: guidelinesGlAnti + 'Malaria-Guidelines.pdf',
  },
  {
    id: 7,
    title: 'Meningitis',
    pdf_link: guidelinesGlAnti + 'Meningitis-Guidelines.pdf',
  },
  {
    id: 8,
    title: 'Respiratory Syncytial Virus',
    pdf_link: guidelinesGlAnti + 'Respiratory-syncytial-virus-GUIDELINES.pdf',
  },
  {
    id: 9,
    title: 'Syphilis',
    pdf_link: guidelinesGlAnti + '3-Syphilis-Guidelines.pdf',
  },
];
