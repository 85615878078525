import React from 'react';
import styled, {useTheme} from 'styled-components';
import {motion} from 'framer-motion';
import Icon from '../icon';
import {Theme} from '../../theme/lightTheme';
import {GenioNewsProps} from '../../interfaces/components/genioNews';
import {removeHtmlTags} from '../../utils/helper';

const Container = styled(motion.button)<{$isSmall?: boolean}>`
  width: 100%;
  height: ${({theme, $isSmall}) => theme.dimensions.hdp($isSmall ? 5 : 12)}px;
  border-radius: ${({$isSmall}) => ($isSmall ? 8 : 10)}px;
  overflow: hidden;
  background-color: ${({theme}) => theme.text.light + 'DD'};
  border: none;
  cursor: pointer;
  text-align: left;
  padding: 0;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1%;
  padding-right: 0;
  height: 100%;
  width: 100%;
`;

const ImageContainer = styled.div`
  height: 100%;
  aspect-ratio: 1;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 3%;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const DetailsContainer = styled.div<{
  $isExtraPadding?: boolean;
  $hideIcon?: boolean;
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({$isExtraPadding}) =>
    $isExtraPadding &&
    `
    padding: 0 3%;
    `}
  ${({$hideIcon}) =>
    $hideIcon &&
    `
    align-items: center;
    `}
`;

const TitleContainer = styled.div<{$isSmall?: boolean; $hideIcon?: boolean}>`
  margin-right: 5%;
  ${({$isSmall}) =>
    $isSmall &&
    `
     flex: 1;
     display: flex;
     align-items: center;
     justify-content: center;
    `};
  ${({$hideIcon}) =>
    $hideIcon &&
    `
     flex: 1;
     display: flex;
     align-items: center;
     justify-content: center;
     width: 100%;
     margin-right: 0;
    `};
`;

const Title = styled.span<{$hideIcon?: boolean}>`
  font-size: ${({theme}) => theme.text.s7};
  color: ${({theme}) => theme.secondary};
  width: 95%;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: end;
  ${({$hideIcon}) =>
    $hideIcon &&
    `
     width: auto;
    `};
`;

const IconContainer = styled.div`
  padding: 0 3%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Animation variants
const containerVariants = {
  initial: {
    boxShadow: '0px 0px 0px rgba(0,0,0,0)',
  },
  hover: {
    boxShadow: '0px 4px 15px rgba(0,0,0,0.1)',
    scale: 1.01,
  },
  tap: {
    scale: 0.98,
  },
};

/**
 * GenioNews Component
 *
 * The `GenioNews` component represents a card-style view for displaying news items.
 * It includes an image, a title, and a navigation arrow. The component is designed to be interactive
 * and supports an onPress callback for navigation.
 *
 * @component
 */
const GenioNews: React.FC<GenioNewsProps> = ({
  imageUri,
  title,
  titleStyle,
  style,
  onPress,
  isSmall,
  className,
  hideIcon,
}) => {
  const theme = useTheme() as Theme;

  const handleClick = () => {
    if (onPress) {
      onPress();
    }
  };

  return (
    <Container
      $isSmall={isSmall}
      onClick={handleClick}
      style={style}
      className={className}
      disabled={!onPress}
      variants={containerVariants}
      initial="initial"
      whileHover="hover"
      whileTap="tap">
      <ContentContainer>
        {imageUri && (
          <ImageContainer>
            <Image
              src={imageUri}
              alt={title || 'News image'}
              onError={e => {
                const target = e.target as HTMLImageElement;
                target.src = require('../../assets/images/newsCardBackground.png');
              }}
            />
          </ImageContainer>
        )}
        <DetailsContainer $hideIcon={hideIcon} $isExtraPadding={!imageUri}>
          {!!title && (
            <TitleContainer $hideIcon={hideIcon} $isSmall={isSmall}>
              <Title $hideIcon={hideIcon} style={titleStyle as any}>
                {removeHtmlTags(title)}
              </Title>
            </TitleContainer>
          )}
        </DetailsContainer>
        {!hideIcon && (
          <IconContainer>
            <Icon
              type="SVG"
              name="navArrow"
              size={isSmall ? theme.text.s35 : theme.text.s3}
              color={theme.secondary + '22'}
              stroke={theme.secondary}
            />
          </IconContainer>
        )}
      </ContentContainer>
    </Container>
  );
};

export default GenioNews;
