// Urology Treatment Options
import {basePdfUrl} from '../../../../../api/api';

const treatmentOptionsToUrology = basePdfUrl + 'to/urology/';

export const treatmentOptionsUrologyData = [
  {
    id: 0,
    title: 'Male Infertility and ED',
    pdf_link:
      treatmentOptionsToUrology +
      '2-Male-Infertility-and-ED-Treatment-Options.pdf',
  },
  {
    id: 1,
    title: 'Pyelonephritis and Nephrolithiasis',
    pdf_link:
      treatmentOptionsToUrology +
      '2-Pyelonpehritis-and-Nephrolithiasis-Treatment-Options.pdf',
  },
  {
    id: 2,
    title: 'Benign Prostatic Hyperplasia',
    pdf_link:
      treatmentOptionsToUrology +
      'Benign-Prostatic-Hyperplasia-Treatment-Options.pdf',
  },
  {
    id: 3,
    title: 'Epididymitis',
    pdf_link: treatmentOptionsToUrology + 'Epididymitis-Treatment-Option.pdf',
  },
  {
    id: 4,
    title: 'Overactive Bladder',
    pdf_link:
      treatmentOptionsToUrology + 'Overactive-Bladder-Treatment-Option.pdf',
  },
  {
    id: 5,
    title: 'Prostatitis',
    pdf_link: treatmentOptionsToUrology + 'Prostatitis-Treatment-Options.pdf',
  },
  {
    id: 6,
    title: 'Urethritis and Cystitis',
    pdf_link:
      treatmentOptionsToUrology +
      'Urethritis-and-Cystitis-Treatment-Options.pdf',
  },
  {
    id: 7,
    title: 'Urinary Incontinence',
    pdf_link:
      treatmentOptionsToUrology + 'Urinary-Incontinence-Treatment-Options.pdf',
  },
  {
    id: 8,
    title: 'Urinary Retention',
    pdf_link:
      treatmentOptionsToUrology + 'Urinary-Retention-Treatment-Option.pdf',
  },
];
