// Endocrinology Treatment Options
import {basePdfUrl} from '../../../../../api/api';

const treatmentOptionsToEndocrinology = basePdfUrl + 'to/endocrinology/';

export const treatmentOptionsEndocrinologyData = [
  {
    id: 0,
    title: 'Parathyroid',
    pdf_link: treatmentOptionsToEndocrinology + '1-Parathyrpid-Diagnosis.pdf', // Should likely be 'Parathyroid-Treatment-Options.pdf'
  },
  {
    id: 1,
    title: 'Thyroid Disorders',
    pdf_link:
      treatmentOptionsToEndocrinology + '1-Thyroid-disorders-diagnosis.pdf', // Should likely be 'Thyroid-disorders-Treatment-Options.pdf'
  },
  {
    id: 2,
    title: 'Adrenal Disorders',
    pdf_link:
      treatmentOptionsToEndocrinology + 'Adrenal-Disorders-Diagnosis.pdf', // Should likely be 'Adrenal-Disorders-Treatment-Options.pdf'
  },
  {
    id: 3,
    title: 'Diabetes',
    pdf_link: treatmentOptionsToEndocrinology + 'Diabetes-Diagnosis.pdf', // Should likely be 'Diabetes-Treatment-Options.pdf'
  },
  {
    id: 4,
    title: 'Gonadal Disorders',
    pdf_link:
      treatmentOptionsToEndocrinology +
      'GONADAL-DISORDERS-DIAGNOSTIC-CRITERIA.pdf', // Should likely be 'Gonadal-Disorders-Treatment-Options.pdf'
  },
  {
    id: 5,
    title: 'Metabolic Disorders',
    pdf_link:
      treatmentOptionsToEndocrinology + 'Metabolic-Disorder-Diagnosis.pdf', // Should likely be 'Metabolic-Disorder-Treatment-Options.pdf'
  },
  {
    id: 6,
    title: 'Pituitary Disorders',
    pdf_link:
      treatmentOptionsToEndocrinology + 'Pituitary-Disorders-Diagnosis.pdf', // Should likely be 'Pituitary-Disorders-Treatment-Options.pdf'
  },
];
