import {store} from '../../../redux/store';
import {setDark} from './reducer';
import {keys} from '../../../api/keys';
import {flushSync} from 'react-dom';
import {storage} from '../../../utils/storage';

/**
 * Toggles the dark mode theme and applies a transition animation if supported.
 *
 * Note: Ensure that the following CSS is added to your global stylesheet
 * to properly handle view transitions:
 *
 * ```css
 * ::view-transition-old(root),
 * ::view-transition-new(root) {
 *   animation: none;
 *   mix-blend-mode: normal;
 * }
 * ```
 *
 * @param {Object} params - The parameters object.
 * @param {boolean} [params.isDarkMode] - Flag indicating if dark mode is currently active.
 * @param {React.RefObject} [params.switchThumbRef] - Reference to the thumb element of the switch toggle, used for animation calculations.
 * @param {boolean} [params.isInitialize] - Flag indicating if the function is being called for initialization, to retrieve saved dark mode state.
 *
 * @returns {Promise<void>} Toggles the dark mode and triggers a view transition if applicable.
 */
export const toggleDarkMode = async ({
  isDarkMode,
  switchThumbRef,
  isInitialize,
}: {
  isDarkMode?: boolean;
  switchThumbRef?: any;
  isInitialize?: boolean;
}): Promise<void> => {
  let transition = null;
  try {
    // Determine the dark mode state: if initializing, fetch from local storage
    let tempIsDarkMode = isDarkMode;
    if (isInitialize) {
      tempIsDarkMode = await storage.get(keys.DARK_MODE);
    } else {
      if (isDarkMode === undefined) {
        tempIsDarkMode = !store.getState().settings.isDarkMode;
      }
      await storage.set(keys.DARK_MODE, tempIsDarkMode);
    }
    /**
     * Exit early if:
     * 1. The switch thumb reference is not available,
     * 2. The View Transition API is not supported, or
     * 3. The user prefers reduced motion, which disables animations.
     */
    if (
      !switchThumbRef?.current?.getBoundingClientRect()?.top ||
      !document?.startViewTransition ||
      window.matchMedia('(prefers-reduced-motion: reduce)').matches
    ) {
      store.dispatch(setDark(tempIsDarkMode));
      return;
    }

    // Trigger a view transition animation for toggling dark mode
    transition = await document.startViewTransition(() => {
      flushSync(() => {
        store.dispatch(setDark(tempIsDarkMode));
      });
    }).ready;

    // Get the position and dimensions of the switch thumb for animation purposes
    const {top, left, width, height} =
      switchThumbRef?.current?.getBoundingClientRect() || {};
    const x = left + width / 2;
    const y = top + height / 2;
    const right = window.innerWidth - left;
    const bottom = window.innerHeight - top;
    const maxRadius = Math.hypot(Math.max(left, right), Math.max(top, bottom));

    // Apply a circular clip path animation to smoothly transition between light and dark mode
    document.documentElement.animate(
      {
        clipPath: [
          `circle(0px at ${x}px ${y}px)`,
          `circle(${maxRadius}px at ${x}px ${y}px)`,
        ],
      },
      {
        duration: 500, // Animation duration in milliseconds
        easing: 'ease-in-out', // Smooth ease-in-out animation
        pseudoElement: '::view-transition-new(root)', // Apply the animation to the new root element
      },
    );
  } catch (error) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.log('error toggle dark mode ', error);
    }
    // skip the animation and just update the DOM
    if (transition) transition.skipTransition();
  }
};
