import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import styled, {css, keyframes, useTheme} from 'styled-components';
import {RootState} from '../../redux/store';
import {Theme} from '../../theme/lightTheme';
import {FiltersModalProps} from '../../interfaces/connected-components/filtersModal';
import Text from '../../components/text';
import {closeFiltersModal} from './actions';
import MyPressable from '../../components/myPressable';
import Icon from '../../components/icon';
import {tr} from '../../translation';
import {handleChange} from '../../utils/form';
import Picker from '../../components/picker';
import GenericButton from '../../components/button';
import {FilterData} from '../../interfaces/api/tabs';
import {findFilterKey, generateExclusiveDateRange} from '../../utils/helper';
import {RiDeleteBin6Line} from 'react-icons/ri';
import {zoomIn, zoomOut} from '../../screens/content/news';
import {isSmallHeight} from '../../utils/responsive';
import moment from 'moment/moment';
import DatePicker from '../../components/datePicker';

const hide = num => keyframes`
    0% {
        height: ${((num || 3) + 2) * 50}px;
        opacity: 1;
        overflow: hidden;
    }
    99% {
        overflow: hidden;
    }
    100% {
        height: 0;
        opacity: 0;
        overflow: visible;
    }
`;

const show = num => keyframes`
    0% {
        height: 0;
        opacity: 0;
        overflow: hidden;
    }
    99% {
        overflow: hidden;
    }
    100% {
        height: ${((num || 3) + 2) * 50}px;
        opacity: 1;
        overflow: visible;
    }
`;

const Container = styled.div<{isVisible?: boolean}>`
  width: 100%;
  position: relative;
  ${({isVisible}) => !isVisible && 'display: none;'}
`;
const ContentContainer = styled.div<{
  isVisible: boolean;
  isBottom?: boolean;
  num?: number;
}>`
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    360deg,
    ${({theme}) => theme.text.light + 'EE'} -68.52%,
    ${({theme}) => theme.text.light + 'DD'} 122.02%
  );
  backdrop-filter: blur(16px);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: absolute;
  padding: 1rem;
  z-index: 999;
  min-width: ${({theme}) =>
          theme.isMobile ? theme.dimensions.hdp(35):
    window.innerHeight > 900
      ? theme.dimensions.hdp(40)
      : theme.dimensions.hdp(50)}px;
  ${({isBottom, theme}) =>
    isBottom
      ? `position: absolute;  min-width: 650%;right: calc(${theme.text.s5} + 10px);top: calc(${theme.text.s5} + 10px)`
      : `right: calc(${theme.text.s2} + 10px);top: 0;`};
  ${({isVisible, num}) =>
    isVisible
      ? css`
          animation: ${show(num)} 0.3s linear;
          opacity: 1;
        `
      : css`
          animation: ${hide(num)} 0.3s linear;
          pointer-events: none;
          opacity: 0;
        `};
  @media (max-width: 550px) {
    width: 90%;
    right: 0;
  }
`;
const Overlay = styled.div<{isVisible: boolean}>`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 200vw;
  height: 200vh;
  z-index: 100;
  overflow: hidden;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  background-color: ${({theme}) => theme.overlay};
  ${({isVisible}) =>
    !isVisible &&
    `
      pointer-events:none;
    `};
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
`;
const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const PickerItemContainer = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 1rem;
`;
const SaveButtonContainer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const TriangleShape = styled.div<{isRotate?: boolean}>`
  transform: rotate(${({isRotate}) => (isRotate ? '0deg' : '180deg')});
  width: 0;
  height: 0;
  background-color: transparent;
  color: transparent;
  border-style: solid;
  border-left-width: 0.4rem;
  border-right-width: 0.4rem;
  border-bottom-width: 0.5rem;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: ${({theme}) => theme.secondary};
`;
const PickerItem = styled(Picker)<any>`
  border: 1px solid ${({theme}) => theme.primary} !important;
  border-radius: 8px !important;
  min-height: ${isSmallHeight ? 34 : 40}px !important;
  color: ${({theme}) => theme.text.dark}!important;
  .react-dropdown-select-input {
    color: ${({theme}) => theme.text.dark};
  }
`;
const AnimatedDiv = styled.div<{isZoomedIn?: boolean}>`
  animation: ${({isZoomedIn}) => (isZoomedIn ? zoomIn : zoomOut)} 0.3s
    ease-in-out forwards;
  transform-origin: center;
  display: ${({isZoomedIn}) => (isZoomedIn ? 'flex' : 'none')};
`;
const DateView = styled(DatePicker)`
  border: 1px solid ${({theme}) => theme.primary} !important;
  border-radius: 8px !important;
  height: ${isSmallHeight ? 34 : 40}px !important;
  overflow: hidden;
  .input {
    color: ${({theme}) => theme.text.dark};
    font-size: ${({theme}) => theme.text.s8} !important;
    &::placeholder {
      color: ${({theme}) => theme.text.grey};
      opacity: ${({value}) => (value ? 0.5 : 1)};
      transition: opacity 0.3s ease;
    }
  }
  .input-container {
    min-height: ${isSmallHeight ? 34 : 40}px !important;
    border-radius: 8px !important;
    background-color: ${({theme}) => theme.border + '11'};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  &:focus-within {
    border-color: ${({theme}) => theme.textInput.border + '99'};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

export const _renderClearPicker = (
  label: string,
  form: any,
  updateForm: (props: any) => void,
  onClick?: () => void,
) => {
  const theme = useTheme() as Theme;

  return (
    <AnimatedDiv isZoomedIn={label && (form?.[label] || [])?.length > 0}>
      <MyPressable
        onClick={event => {
          event.stopPropagation();
          onClick && onClick();
          handleChange(label, [], updateForm);
        }}
        disableRipple>
        <RiDeleteBin6Line
          size={theme.text.s6}
          color={theme.text.error + 'AA'}
        />
      </MyPressable>
    </AnimatedDiv>
  );
};

const FiltersModal = ({filtersModal, isBottom}: FiltersModalProps) => {
  const theme = useTheme() as Theme;
  const {isVisible, onSubmit, filters, filtersValues} = filtersModal || {};
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form, updateForm] = useState<any>({});

  useEffect(() => {
    return () => {
      return closeFiltersModal();
    };
  }, []);

  useEffect(() => {
    const root = document.getElementById('root');
    root.style.overflowX = isModalVisible || isVisible ? 'hidden' : '';
    return () => {
      root.style.overflow = '';
    };
  }, [isVisible, isModalVisible]);

  useEffect(() => {
    let timeout = null;
    if (isVisible) {
      setIsModalVisible(true);
      const tempFilters = {};
      Object.keys(filtersValues || {}).forEach(key => {
        const filterKey = findFilterKey(filters, key);
        if (filterKey) {
          const idx = filters[filterKey]?.items?.findIndex(
            i => i?.id === filtersValues[key],
          );
          if (idx > -1) {
            tempFilters[key] = [filters[filterKey]?.items[idx]];
          }
        }
      });
      updateForm(tempFilters);
    } else {
      timeout = setTimeout(() => setIsModalVisible(false), 300);
      updateForm({});
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isVisible]);

  const getValue = (filterItem: FilterData) => {
    if (!filterItem || !filterItem.key || !form || !form[filterItem?.key]) {
      return '';
    }
    return form[filterItem?.key];
  };

  const submitHandler = (isReset = false) => {
    if (onSubmit) {
      const tempForm = {};
      Object.keys(form).forEach(i => {
        if (i === 'creationdate') {
          tempForm[i] = form[i];
        } else if (form[i].length > 0 && form[i][0]) {
          tempForm[i] = form[i][0]?.id;
        }
      });
      onSubmit(isReset ? {} : tempForm);
    }
    closeFiltersModal();
    updateForm({});
  };

  const renderPickerItem = (filterItem: FilterData) => {
    return (
      <PickerItem
        dropdownHandleRenderer={({state}) => (
          <TriangleShape isRotate={state.dropdown}></TriangleShape>
        )}
        clearRenderer={() =>
          _renderClearPicker(filterItem?.key, form, updateForm)
        }
        clearable
        placeholder={filterItem?.label}
        onChange={val => {
          if (val) handleChange(filterItem?.key, val, updateForm);
        }}
        values={getValue(filterItem) || []}
        list={filterItem?.items || []}
        label={''}
        labelKey={'name'}
        valueKey={'id'}
      />
    );
  };

  return (
    <Container isVisible={isModalVisible}>
      {/*<Overlay onClick={closeFiltersModal} isVisible={isVisible} />*/}
      <ContentContainer
        num={Object.keys(filters || {}).length}
        isBottom={isBottom}
        isVisible={isVisible}>
        <Row>
          <Text bold withGradient>
            Filters
          </Text>
          <IconsContainer>
            <MyPressable
              onPress={() => submitHandler(true)}
              style={{padding: 10, borderRadius: 100}}
              containerStyle={{overflow: 'visible'}}>
              <Icon
                type={'SVG'}
                name={'clearFilters'}
                color={theme.secondary}
                size={theme.text.s5}
              />
            </MyPressable>
            <MyPressable
              onPress={() => closeFiltersModal()}
              style={{
                padding: 10,
                borderRadius: 100,
              }}
              containerStyle={{overflow: 'visible'}}>
              <div
                style={{
                  width: theme.text.s5,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Icon
                  type={'SVG'}
                  name={'close'}
                  color={theme.text.grey}
                  size={theme.text.s9}
                />
              </div>
            </MyPressable>
          </IconsContainer>
        </Row>
        {Object.values(filters || {}).map((filterItem: any, index) => {
          if ((filterItem?.items || []).length == 1) {
            return null;
          }
          let dates: any = null;
          if (filterItem.key === 'creationdate') {
            const tempDates = (filterItem?.items || []).map(date =>
              moment(date.name, 'YYYY-MM-DD').toISOString(),
            );
            dates = generateExclusiveDateRange(tempDates);
          }

          return (
            <PickerItemContainer
              style={{
                zIndex: Object.values(filters || {}).length - index,
              }}
              key={filterItem?.key + isVisible}>
              {filterItem.key === 'creationdate' ? (
                <DateView
                  key={getValue(filterItem) || ''}
                  onChange={val => {
                    if (val) {
                      handleChange(
                        filterItem?.key,
                        moment(val, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                        updateForm,
                      );
                    }
                  }}
                  placeholder={filterItem?.label || ''}
                  value={
                    moment(getValue(filterItem)).isValid()
                      ? moment(getValue(filterItem))
                      : ''
                  }
                  disabledDates={dates?.completeDateRange || []}
                  minDate={dates?.minDate}
                  maxDate={dates?.maxDate}
                  rightIconComponent={isVisible => (
                    <TriangleShape isRotate={isVisible}></TriangleShape>
                  )}
                  clearRenderer={() =>
                    _renderClearPicker(
                      filterItem?.key,
                      form,
                      () => {},
                      () => {
                        handleChange(filterItem?.key, '', updateForm);
                      },
                    )
                  }
                  initialMax
                />
              ) : (
                renderPickerItem(filterItem)
              )}
            </PickerItemContainer>
          );
        })}
        <SaveButtonContainer>
          <GenericButton
            label={tr('app.ok')}
            onPress={() => submitHandler()}
            radius={8}
            style={{
              marginTop: '1rem',
              width: '100%',
            }}
          />
          <GenericButton
            label={tr('filtersModal.allFilters')}
            onPress={() => submitHandler(true)}
            outline
            radius={8}
            style={{
              marginTop: '0.5rem',
              width: '100%',
            }}
          />
        </SaveButtonContainer>
      </ContentContainer>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  filtersModal: state.filtersModal,
});

export default connect(mapStateToProps, null)(FiltersModal);
