import styled, {useTheme} from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import NewsCard from '../newsCard';
import React from 'react';
import {Theme} from '../../theme/lightTheme';
import {motion} from 'framer-motion';
import {isSmallHeight} from '../../utils/responsive';
import {tr} from '../../translation';
import Paths from '../../config/paths';
import {GenioLatestNewsItem} from '../../screens/content/home';

const SkeletonWrapper = styled(motion.div)`
  position: relative;
  align-self: center;
  margin-bottom: ${isSmallHeight ? 1 : 1.5}rem;
  width: 48%;
  @media (max-width: 550px) {
    width: 100%;
  }
  background: linear-gradient(
    360deg,
    ${({theme}) => theme.secondary + '77'} -68.52%,
    rgba(0, 159, 156, 0) 122.02%
  );
  backdrop-filter: blur(8px);
  border-radius: 10px;
`;
const NewsSkeleton = ({
  newsNum,
  className,
}: {
  newsNum?: number;
  className?: string;
}) => {
  const theme = useTheme() as Theme;
  return Array(newsNum || 10)
    .fill(0)
    .map((i, index) => (
      <SkeletonWrapper className={className} key={index}>
        <Skeleton
          baseColor={'transparent'}
          highlightColor={theme.text.light + '33'}
          height={'100%'}
          width={'100%'}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            borderRadius: 10,
          }}
        />
        <GenioLatestNewsItem
          title={'skeleton'}
          style={{visibility: 'hidden'}}
        />
      </SkeletonWrapper>
    ));
};

export default NewsSkeleton;
