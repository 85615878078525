import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {RootState} from '../../redux/store';
import {DrawerProps} from '../../interfaces/connected-components/drawer';
import {closeDrawer, openDrawer, toggleDrawer} from './actions';
import Icon from '../../components/icon';
import Paths from '../../config/paths';
import {tr} from '../../translation';
import {DarkModeSwitch} from 'react-toggle-dark-mode';
import AnimateHeight from 'react-animate-height';
import {openUrl, sleep} from '../../utils/helper';
import {showGlobalModal} from '../globalModal/actions';
import {endSession} from '../../screens/auth/login/actions';
import {toggleDarkMode} from '../../screens/content/settings/action';
import styled, {css, keyframes, useTheme} from 'styled-components';
import {drawerRoutes} from '../../route/routes/drawerRoutes';
import {Theme} from '../../theme/lightTheme';
import {useNavigate} from 'react-router-dom';

const duration = '0.4s';

const slideIn = keyframes`
    from {
        transform: translateX(100vw);
        opacity: 0;
    }
    
    to {
        transform: translateX(0);
        opacity: 1;
    }
`;
const slideOut = keyframes`
  to {
    transform: translateX(100vw);
      opacity: 0;
  }
`;

const SidebarContainer = styled.div<any>`
  width: ${({isSidebarOpen, widthExpanded, widthCollapsed}) =>
    isSidebarOpen ? widthExpanded : widthCollapsed};
  height: 100vh;
  max-width: ${({maxWidth}) => maxWidth};
  min-width: ${({minWidth}) => minWidth};
  background-image: linear-gradient(
      315deg,
      ${({theme}) => theme.drawer.bgColor1} 0%,
      ${({theme}) => theme.drawer.bgColor2} 74%
    ),
    url(${({backgroundImage}) => backgroundImage});
  backdrop-filter: blur(8px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: ${({theme}) => theme.drawer.fontColorSelected};
  transition: 0.2s ease-in-out all;
  z-index: 1002;
  position: ${({theme}) => (theme.isMobile ? 'absolute' : 'sticky')};
  top: 0;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;
const SidebarScreenContainer = styled.div<any>`
  width: ${({widthExpanded, theme}) =>
    theme.isMobile ? `calc( 100vw )` : `calc( 100vw - ${widthExpanded})`};
  height: 100vh;
  background-image: linear-gradient(
      315deg,
      ${({theme}) => theme.drawer.bgColor1} 0%,
      ${({theme}) => theme.drawer.bgColor2} 74%
    ),
    url(${({backgroundImage}) => backgroundImage});
  backdrop-filter: blur(8px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1002;
  position: fixed;
  top: 0;
  right: 0;
  overflow: hidden;
  visibility: ${({isFirstOpen}) => (isFirstOpen ? 'visible' : 'hidden')};
  pointer-events: ${({isFirstOpen}) => (isFirstOpen ? 'auto' : 'none')};
  flex-direction: column;
  display: flex;
  transition:
    transform 0.5s ease-in-out,
    opacity 0.5s ease-in-out;

  animation: ${({isSubSidebarOpen}) => css`
    ${isSubSidebarOpen ? slideIn : slideOut} 0.5s ease-in-out forwards;
  `};
`;
const Overlay = styled.div<{isSidebarOpen?: boolean}>`
  position: fixed;
  inset: 0;
  background-color: ${({theme}) => theme.overlay};
  opacity: ${({isSidebarOpen}) => (isSidebarOpen ? 1 : 0)};
  transition: opacity ${duration} ease;
  z-index: 1001;
  pointer-events: ${({isSidebarOpen}) => (isSidebarOpen ? 'auto' : 'none')};
`;
const SidebarHeaderText = styled.h3<{hasHeaderClick?: boolean}>`
  padding: 20px 0;
  text-align: center;
  margin-bottom: 10px;
  letter-spacing: 6px;
  font-weight: 300;
  ${({hasHeaderClick}) => hasHeaderClick && 'cursor: pointer'}
`;
const SidebarHeaderImageContainer = styled.div<any>`
  ${({hasHeaderClick}) => hasHeaderClick && 'cursor: pointer'};
  width: 100%;
  text-align: ${p => p.align || 'center'};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({theme}) => theme.text.s3};
  margin: 1rem 0;
`;
const MenuItemContainer = styled.div``;
const ItemContainer = styled.div``;
const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0.3rem 0;
`;

// Menu items -------------------------------------------------------------
const MenuItem = styled.div<{
  isSidebarOpen?: boolean;
  selected?: boolean;
  isOpen?: boolean;
}>`
  ${({isSidebarOpen, selected, theme}) =>
    !isSidebarOpen &&
    `
    text-align: center;
    ${selected && `background-color: ${theme.drawer.selectedBackgroundCollapsedMode === 'dark' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.2)'}`};
  `};

  font-weight: 300;
  cursor: pointer;
  color: ${({selected, theme}) =>
    selected ? theme.drawer.fontColorSelected : theme.drawer.fontColor};
  white-space: nowrap;
  position: relative; // Dropdown Icon
  transition: 0.2s ease-in all;
  padding: 0.6rem 0.5rem;
  border-radius: 6px;
  width: 90%;
  margin: 0.2rem auto;
  &:hover {
    color: ${({theme}) => theme.drawer.fontColorSelected};
    background-color: ${({theme}) => theme.drawer.fontColorSelected + '22'};
    transition: 0.1s ease-in all;
  }
`;
const Separator = styled.div<any>`
  margin: 0 auto;
  height: 2px;
  background-color: ${({theme, selected}) =>
    selected ? theme.drawer.fontColorSelected : theme.drawer.dividerColor};
  width: 90%;
  opacity: ${({isSidebarOpen, selected, isOpen}) =>
    isSidebarOpen && selected && isOpen ? 0 : 1};
  transition: 0.2s ease-in all;
  ${({selected}) =>
    !selected &&
    `
    &:hover {
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: 0.1s ease-in all;
  }
  `}
`;
const Text = styled.p<any>`
  opacity: ${({isSidebarOpen}) => (isSidebarOpen ? 1 : 0)};
  font-size: ${({isSidebarOpen, theme}) => (isSidebarOpen ? theme.text.s8 : 0)};
  padding-left: ${({theme, isSidebarOpen}) =>
    isSidebarOpen ? `calc(${theme.text.s8} )` : 0};
  line-height: ${({isSidebarOpen, theme}) =>
    isSidebarOpen ? `calc(${theme.text.s8} * 1.4)` : 0};
  min-height: ${({theme}) => `calc(${theme.text.s8} * 1.4)`};
  transition: 0.2s ease-in all;
  ${({isSidebarOpen}) => !isSidebarOpen && 'font-size:0;'};
`;
const IconContainer = styled.div<any>`
  width: ${({theme, isSidebarOpen}) =>
    isSidebarOpen ? 'auto' : `calc(${theme.text.s1})`};
  transition: 0.2s ease-in all;
`;
// Sub menu items -------------------------------------------------------------------------
const SubMenuItemContainer = styled.div<any>`
  font-size: 14px;
  ${({isSidebarOpen}) => isSidebarOpen && 'padding-left: 15%'};
`;
const SubMenuItem = styled.p<any>`
  color: ${({selected, theme}) =>
    selected ? theme.drawer.fontColorSelected : theme.drawer.fontColor};
  font-weight: 300;
  cursor: pointer;
  ${({selected}) => selected && 'font-weight: 400; letter-spacing: 2px;'};
  transition: 0.2s;
  padding: 2% 0;
  &:hover {
    color: ${({theme}) => theme.drawer.fontColorSelected};
  }
`;
const DarkModeSwitchContainer = styled.div`
  margin-left: auto;
  margin-right: 20px;
`;

// Dropdown icon ----------------------------------------------------------------------
const DropdownIcon = styled.span<any>`
  margin-left: auto;
  border: solid
    ${({selected, theme}) =>
      selected ? theme.drawer.fontColorSelected : theme.drawer.fontColor};
  border-width: 0 2px 2px 0;
  padding: 4px;
  transform: ${({isOpen}) => (isOpen ? 'rotate(-135deg)' : 'rotate(45deg)')};
  transition: 0.4s;
`;

const CloseIconContainer = styled.div`
  z-index: 99;
  padding: 1rem;
  aspect-ratio: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1rem;
  right: 1rem;
  background-color: ${({theme}) => theme.text.light + '22'};
  border-radius: 100px;
  cursor: pointer;
  &:hover {
    opacity: 0.95;
  }
  &:active {
    opacity: 0.9;
    scale: 0.9;
  }
`;

const Drawer: React.FC<DrawerProps> = props => {
  const theme = useTheme() as Theme;
  const navigate = useNavigate();
  const {
    drawer,
    settings,
    backgroundImage = '',
    useImageAsHeader = true,
    header = {
      fullName: '',
      shortName: '',
    },
    headerImage = {
      urlExpanded: '',
      urlCollapsed: '',
      heightExpanded: theme.text.s3,
      heightCollapsed: theme.text.s3,
      align: 'center',
    },
    menuItems = drawerRoutes,
    widthExpanded = `${theme.isMobile ? `calc(100%)` : `calc(26%)`}`,
    widthCollapsed = `calc(0%)`,
    minWidth = `calc(0%)`,
    maxWidth = `calc(${theme.dimensions.wdp(100)}px)`,
    className = {},
    showToggle = theme.isMobile,
    onToggleClick = null,
    onHeaderClick = null,
    onMenuItemClick = null,
    onSubMenuItemClick = null,
  } = props;
  const {isVisible} = drawer || {};
  const {isDarkMode} = settings || {};

  const {privacyPolicyUrl, termsConditionsUrl, privacyPolicyFromUrl} =
    useSelector((state: RootState) => state.auth)?.config || {};
  // State
  const [selected, setSelectedMenuItem] = useState(
    menuItems[0] ? menuItems[0].name : null,
  );
  const [headerState, setHeader] = useState(header.fullName);
  const [subMenusStates, setSubmenus] = useState({});
  const switchThumbRef = useRef<any>(null);
  const [isFirstOpen, setIsFirstOpen] = useState(false);
  const [subSidebarOpen, setSubSidebarOpen] = useState(null);

  // Effects
  // Set selected menu item based on URL pathname
  useLayoutEffect(() => {
    const path = window.location.pathname;
    const parts = path.split('/');

    if (
      path !== '/' &&
      parts[1].charAt(0).toUpperCase() !== menuItems[0].name
    ) {
      const selectedItem = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);
      setSelectedMenuItem(selectedItem);
    }
  }, [menuItems]);

  // Update of header state
  useEffect(() => {
    isVisible
      ? setTimeout(() => setHeader(header.fullName), 200)
      : setHeader(header.shortName);
  }, [isVisible, header]);

  // Add index of items that contain sub menu items
  useEffect(() => {
    const newSubmenus = {};

    menuItems.forEach((item, index) => {
      const hasSubmenus = !!(item?.subMenuItems || []).length;

      if (hasSubmenus) {
        newSubmenus[index] = {};
        newSubmenus[index]['isOpen'] = false;
        newSubmenus[index]['selected'] = null;
      }
    });

    // Set selected submenu if user landed on one
    const path = window.location.pathname;
    const parts = path.split('/');

    if ((parts || []).length === 3) {
      const selectedItem = parts[1].toLowerCase();
      const selectedSubItem = parts[2].toLowerCase();
      const selectedItemIndex = menuItems.findIndex(
        item => item.name.toLowerCase() === selectedItem,
      );
      const selectedSubItemIndex = menuItems[selectedItemIndex]
        ? menuItems[selectedItemIndex].subMenuItems.findIndex(
            subItem => subItem.name.toLowerCase() === selectedSubItem,
          )
        : null;

      if (selectedItemIndex !== -1)
        newSubmenus[selectedItemIndex]['isOpen'] = true;
      if (selectedItemIndex !== -1 && selectedSubItemIndex !== -1)
        newSubmenus[selectedItemIndex]['selected'] = selectedSubItemIndex;
    }
    Object.keys(newSubmenus || {}).length != 0 &&
      Object.keys(subMenusStates || {}).length === 0 &&
      setSubmenus(newSubmenus);
  }, [menuItems, subMenusStates]);

  useEffect(() => {
    let timeout = null;
    if (!isVisible) {
      setSubmenus({});
      setSubSidebarOpen(null);
      setIsFirstOpen(isVisible);
    } else {
      timeout = setTimeout(() => setIsFirstOpen(isVisible), 500);
    }
    document.body.style.overflow = isVisible ? 'hidden' : '';
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isVisible]);

  const handleMenuItemClick = async (event, item, index) => {
    if (onMenuItemClick) onMenuItemClick(event, index);
    if (!onItemPress(item?.name)) return;
    if (!isVisible) {
      openDrawer();
      await sleep(200);
    }
    if (item?.compactComponent) {
      if (subSidebarOpen && item?.name !== subSidebarOpen?.name) {
        setSubSidebarOpen(null);
        await sleep(200);
      }
      setSubSidebarOpen(item);
    }
    setSelectedMenuItem(item?.name);

    const subMenusCopy = JSON.parse(JSON.stringify(subMenusStates));

    if (subMenusStates.hasOwnProperty(index)) {
      subMenusCopy[index]['isOpen'] = !subMenusStates[index]['isOpen'];
      setSubmenus(subMenusCopy);
    } else {
      for (const item in subMenusStates) {
        subMenusCopy[item]['isOpen'] = false;
        subMenusCopy[item]['selected'] = null;
      }
      setSubmenus(subMenusCopy);
    }
  };

  const onItemPress = (name: string) => {
    try {
      switch (name) {
        case 'logout': {
          showGlobalModal({
            title: tr('home.logOutTitle'),
            message: tr('home.logoutInfo'),
            onConfirm: () => {
              closeDrawer();
              setSubSidebarOpen(null);
              setSelectedMenuItem(menuItems[0] ? menuItems[0].name : null);
              endSession();
            },
            confirmText: tr('drawer.logout'),
            type: 'question',
          });
          return;
        }
        case 'privacyPolicy': {
          openUrl(privacyPolicyUrl || process.env.REACT_APP_POLICY);
          return;
        }
        case 'privacyPolicyForm': {
          openUrl(privacyPolicyFromUrl || process.env.REACT_APP_POLICY_FORM);
          return;
        }
        case 'termsConditions': {
          openUrl(
            termsConditionsUrl || process.env.REACT_APP_TERMS_AND_CONDITIONS,
          );
          return;
        }
        case 'home': {
          navigate(Paths.content.home, {replace: true});
          closeDrawer();
          setSubSidebarOpen(null);
          setSelectedMenuItem(menuItems[0] ? menuItems[0].name : null);
          return;
        }
        default: {
          return true;
        }
      }
    } catch (error) {
      if (process.env.REACT_APP_DEBUG === 'true') {
        console.log(error);
      }
      return;
    }
  };

  const handleSubMenuItemClick = async (
    event,
    menuItemIdx,
    subMenuItemIdx,
    subMenuItem,
  ) => {
    if (onSubMenuItemClick)
      onSubMenuItemClick(event, menuItemIdx, subMenuItemIdx);
    if (subMenuItem.name === 'darkMode') {
      toggleDarkMode({switchThumbRef});
      return;
    }
    if (subSidebarOpen && subMenuItem?.name !== subSidebarOpen?.name) {
      setSubSidebarOpen(null);
      await sleep(200);
    }
    if (subMenuItem?.compactComponent) {
      setSubSidebarOpen(subMenuItem);
    }
    const subMenusCopy = JSON.parse(JSON.stringify(subMenusStates));
    subMenusCopy[menuItemIdx]['selected'] = subMenuItemIdx;
    setSubmenus(subMenusCopy);
  };

  const handleToggle = async () => {
    if (onToggleClick) onToggleClick();
    if (isVisible) {
      setSubmenus({});
      await sleep(200);
    }
    toggleDrawer();
  };

  const handleHeaderClick = () => {
    if (onHeaderClick) onHeaderClick();
    navigate(Paths.content.home, {replace: true});
  };

  const menuItemsJSX = (menuItems || []).map((item, index) => {
    const isItemSelected = selected === item.name;

    const hasSubmenus = !!(item?.subMenuItems || []).length;
    const isOpen = subMenusStates[index] ? subMenusStates[index].isOpen : false;

    const subMenusJSX = (item?.subMenuItems || []).map(
      (subMenuItem, subMenuItemIndex) => {
        const isSubmenuItemSelected = subMenusStates[index]
          ? subMenusStates[index].selected === subMenuItemIndex
          : false;

        return (
          <Row
            key={subMenuItemIndex}
            onClick={e =>
              handleSubMenuItemClick(e, index, subMenuItemIndex, subMenuItem)
            }>
            <SubMenuItem
              key={subMenuItemIndex}
              selected={
                isSubmenuItemSelected && subMenuItem.name !== 'darkMode'
              }>
              {tr(`drawer.${subMenuItem.name}`)}
            </SubMenuItem>
            {subMenuItem.name === 'darkMode' && (
              <DarkModeSwitchContainer>
                <DarkModeSwitch
                  checked={!isDarkMode}
                  size={22}
                  sunColor={theme.text.light}
                  moonColor={'#0094df'}
                  onChange={() => {}}
                />
                <div ref={switchThumbRef} />
              </DarkModeSwitchContainer>
            )}
          </Row>
        );
      },
    );

    return (
      <ItemContainer key={index}>
        <MenuItem
          selected={isItemSelected}
          onClick={e => handleMenuItemClick(e, item, index)}
          isSidebarOpen={isVisible}
          isOpen={isOpen}>
          <Row>
            <IconContainer
              widthCollapsed={widthCollapsed}
              isSidebarOpen={isVisible}>
              <Icon
                name={item?.iconName}
                type={'SVG'}
                color={theme.text.light}
                size={theme.text.s55}
              />
            </IconContainer>
            <Text isSidebarOpen={isVisible}>{tr(`drawer.${item.name}`)}</Text>
            {hasSubmenus && isVisible && (
              <DropdownIcon selected={isItemSelected} isOpen={isOpen} />
            )}
          </Row>
        </MenuItem>
        <Separator
          selected={isItemSelected}
          isSidebarOpen={isVisible}
          isOpen={isOpen}
        />
        <AnimateHeight
          duration={500}
          height={hasSubmenus && isOpen && isVisible ? 'auto' : 0}>
          <SubMenuItemContainer isSidebarOpen={isVisible}>
            {subMenusJSX}
          </SubMenuItemContainer>
        </AnimateHeight>
      </ItemContainer>
    );
  });

  return (
    <>
      <Overlay onClick={() => handleToggle()} isSidebarOpen={isVisible} />
      <SidebarContainer
        backgroundImage={backgroundImage}
        isSidebarOpen={isVisible}
        widthCollapsed={widthCollapsed}
        widthExpanded={widthExpanded}
        minWidth={minWidth}
        maxWidth={maxWidth}
        style={{...className}}>
        {useImageAsHeader ? (
          <SidebarHeaderImageContainer
            align={isVisible ? headerImage.align : 'center'}
            hasHeaderClick
            onClick={() => handleHeaderClick()}
            isSidebarOpen={isVisible}>
            <Icon
              type={'SVG'}
              name={isVisible ? 'onboardingLogo' : 'logoChar'}
              size={
                isVisible
                  ? '8rem'
                  : '1.8rem'
              }
              color={theme.text.light}
            />
          </SidebarHeaderImageContainer>
        ) : (
          <SidebarHeaderText
            hasHeaderClick={!!onHeaderClick}
            onClick={() => handleHeaderClick()}>
            {headerState}
          </SidebarHeaderText>
        )}
        <MenuItemContainer>{menuItemsJSX}</MenuItemContainer>
        {showToggle && (
          <CloseIconContainer onClick={() => handleToggle()}>
            <Icon
              type={'SVG'}
              name={'close'}
              color={theme.text.light}
              size={theme.text.s9}
            />
          </CloseIconContainer>
        )}
      </SidebarContainer>
      <SidebarScreenContainer
        isFirstOpen={isFirstOpen}
        backgroundImage={backgroundImage}
        isSubSidebarOpen={!!subSidebarOpen}
        isSidebarOpen={isVisible}
        widthCollapsed={widthCollapsed}
        widthExpanded={widthExpanded}
        minWidth={minWidth}
        maxWidth={maxWidth}>
        <CloseIconContainer onClick={() => setSubSidebarOpen(null)}>
          <Icon
            type={'SVG'}
            name={'close'}
            color={theme.text.light}
            size={theme.text.s9}
          />
        </CloseIconContainer>
        {subSidebarOpen && subSidebarOpen?.compactComponent()}
      </SidebarScreenContainer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  drawer: state.drawer,
  settings: state.settings,
});

export default connect(mapStateToProps)(Drawer);
