import {Colors} from '../interfaces/theme/colors';

/**
 * The colors object that defines the color palette for the application.
 * @type {Colors}
 */
export const colors: Colors = {
  primary: '#2823F5',
  secondary: '#0B0D6F',
  tertiary: '#7CF3FF',
  ripple: '#D9D9D9',
  warning: '#F4B310',
  error: '#AC2F2F',
  success: '#1D832E',
  title: '#000000',
  description: '#707070',
  icon: '#A1A1A1',
};
