// Ophthalmology Guidelines
import {basePdfUrl} from '../../../../../api/api';

const guidelinesGlOphthalmology = basePdfUrl + 'gl/ophthalmology/';

export const guidelinesOphthalmologyData = [
  {
    id: 0,
    title: 'Age-Related Macular Degeneration',
    pdf_link: guidelinesGlOphthalmology + '3-AMD-Guidelines.pdf',
  },
  {
    id: 1,
    title: 'Eye Infections',
    pdf_link: guidelinesGlOphthalmology + '3-Eye-infections-guidelines.pdf',
  },
  {
    id: 2,
    title: 'Cataract',
    pdf_link: guidelinesGlOphthalmology + 'Cataract-Guidelines.pdf',
  },
  {
    id: 3,
    title: 'Diabetic Retinopathy',
    pdf_link: guidelinesGlOphthalmology + 'Diabetic-Retinopathy-Guidelines.pdf',
  },
  {
    id: 4,
    title: 'Dry Eye Syndrome',
    pdf_link: guidelinesGlOphthalmology + 'Dry-Eye-Syndrome-Guidelines.pdf',
  },
  {
    id: 5,
    title: 'Glaucoma',
    pdf_link: guidelinesGlOphthalmology + 'Glaucoma-Guidelines.pdf',
  },
  {
    id: 6,
    title: 'Graves Eye Disease',
    pdf_link: guidelinesGlOphthalmology + 'Graves-Eye-Disease-Guidelines.pdf',
  },
  {
    id: 7,
    title: 'Idiopathic Intracranial Hypertension',
    pdf_link:
      guidelinesGlOphthalmology +
      'Idiopathic-Intracranial-Hypertension-Guidelines.pdf',
  },
  {
    id: 8,
    title: 'Refractive Eye Errors',
    pdf_link:
      guidelinesGlOphthalmology + 'Refractive-Eye-Errors-Guidelines.pdf',
  },
  {
    id: 9,
    title: 'Retinoblastoma',
    pdf_link: guidelinesGlOphthalmology + 'Retinoblastoma-Guidelines.pdf',
  },
];
