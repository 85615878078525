// Dermatology
import {basePdfUrl} from '../../../../../api/api';

const diagnosticToolsDtDermatology = basePdfUrl + 'dt/dermatology/';

export const diagnosticToolsDermatologyData = [
  {
    id: 0,
    title: 'Lupus',
    pdf_link: diagnosticToolsDtDermatology + '1-Lupus-Diagnosis.pdf',
  },
  {
    id: 1,
    title: 'Acne',
    pdf_link: diagnosticToolsDtDermatology + 'Acne-Diagnosis.pdf',
  },
  {
    id: 2,
    title: 'Anogenital Warts',
    pdf_link: diagnosticToolsDtDermatology + 'Anogenital-Warts-Diagnosis.pdf',
  },
  {
    id: 3,
    title: 'Dermatitis and Urticaria',
    pdf_link:
      diagnosticToolsDtDermatology + 'Dermatitis-and-Urticaria-Diagnosis.pdf',
  },
  {
    id: 4,
    title: 'Psoriasis',
    pdf_link: diagnosticToolsDtDermatology + 'Psoriasis-Diagnosis.pdf',
  },
  {
    id: 5,
    title: 'Rosacea',
    pdf_link: diagnosticToolsDtDermatology + 'Rosacea-Diagnosis.pdf',
  },
  {
    id: 6,
    title: 'Skin Cancer',
    pdf_link: diagnosticToolsDtDermatology + 'Skin-Cancer-Diagnosis.pdf',
  },
  {
    id: 7,
    title: 'Tinea Infection',
    pdf_link: diagnosticToolsDtDermatology + 'TINEA-INFECTION-DIAGNOSIS.pdf',
  },
  {
    id: 8,
    title: 'Varicella Zoster Virus',
    pdf_link:
      diagnosticToolsDtDermatology + 'Varicella-Zoster-Virus-Diagnosis.pdf',
  },
  {
    id: 9,
    title: 'Vitiligo',
    pdf_link: diagnosticToolsDtDermatology + 'Vitiligo-Diagnosis.pdf',
  },
];
