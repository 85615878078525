import React, {useEffect, useState, useCallback, useMemo} from 'react';
import styled, {useTheme} from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {motion} from 'framer-motion';
import {RootState} from '../../../redux/store';
import {TabItemType} from '../../../interfaces/api/content/home';
import {NewsItemType} from '../../../interfaces/api/tabs';
import {getHomeTabs} from './actions';
import {tr} from '../../../translation';
import HomeTab from '../../../components/homeTab';
import HomeTabsSkeleton from '../../../components/homeTabsSkeleton';
import HomeHeader from '../../../components/homeHeader';
import {useSetMainLayoutProps} from '../../../route/routeMainLayout/context';
import {initMainHomeTabs} from '../customizeHome/actions';
import EmptyList from '../../../components/emptyList';
import {Theme} from '../../../theme/lightTheme';
import Icon from '../../../components/icon';
import GenioNews from '../../../components/genioNews';
import LatestNewsSkeleton from '../../../components/latestNewsSkeleton';
import Paths from '../../../config/paths';
import {drugsAndDiseasesOptions} from '../../../assets/static/tabsData';
import { TabIds} from '../../../utils/enums';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const TopContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
`;

const LatestNewsContainer = styled(motion.div)`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-self: center;
  position: relative;
  margin-top: 32px;
`;

const RecommendationsGrid = styled.div`
  display: grid;
  gap: 16px;
  margin-bottom: 16px;
  @media (min-width: 1201px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const SectionTitle = styled.p`
  font-size: ${({theme}) => theme.text.s4};
  color: ${({theme}) => theme.text.dark};
  margin-bottom: 16px;
`;

const ViewAll = styled.p`
  font-size: ${({theme}) => theme.text.s6};
  color: ${({theme}) => theme.text.dark};
  letter-spacing: 1px;
  margin-right: 1rem;
`;

const TabsContainer = styled.div`
  z-index: 3;
  padding-top: 16px;

  align-self: center;
  display: flex;
  @media (min-width: 1201px) {
    width: 40%;
  }

  @media (max-width: 1200px) {
    width: 75%;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const TabGrid = styled.div`
  display: grid;
  gap: 16px;
  margin: 0 auto;
  width: 90%;
  align-self: center;
    grid-template-columns: repeat(2, 1fr);
`;

const TabItem = styled(motion.div)<{zIndex?: number}>`
  z-index: ${({zIndex}) => zIndex || 1};
  width: 100%;
`;

const ErrorContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5%;
`;

export const EmptyContainer = styled.div`
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const ViewAllContainer = styled.button`
  align-items: center;
  justify-content: center;
  width: 94%;
  margin: 1% 0;
  display: flex;
  align-self: center;
  flex-direction: row;
  border: none;
  background: none;
  cursor: pointer;
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.8;
    scale: 0.99;
  }
`;
export const GenioLatestNewsItem = styled(GenioNews)`
  display: flex;
  flex: 1;
  position: relative;
`;

// Memoized recommendation item component
const RecommendationItem = React.memo(
  ({
    item,
    index,
    onPress,
  }: {
    item: NewsItemType;
    index: number;
    onPress: (item: NewsItemType) => void;
  }) => (
    <motion.div
      key={item.id}
      style={{display: 'flex'}}
      initial={{opacity: 0, y: 20}}
      animate={{opacity: 1, y: 0}}
      transition={{delay: index * 0.1 + 0.5}}>
      <GenioLatestNewsItem
        title={item?.title || ''}
        imageUri={item?.img || item?.image_url || ''}
        onPress={() => onPress(item)}
      />
    </motion.div>
  ),
);

RecommendationItem.displayName = 'RecommendationItem';

// Memoized tab item component
const TabItemComponent = React.memo(
  ({
    item,
    index,
    onPress,
  }: {
    item: TabItemType;
    index: number;
    onPress: (item: TabItemType, multiTabKey?: string) => void;
  }) => {
    const itemVariants = useMemo(
      () => ({
        hidden: {y: 20, opacity: 0},
        visible: {
          y: 0,
          opacity: 1,
          transition: {
            type: 'spring',
            stiffness: 300,
            damping: 24,
          },
        },
      }),
      [],
    );

    return (
      <TabItem
        key={(item.id || index).toString()}
        zIndex={item.label === 'drugsAndDiseases' ? 2 : 1}
        variants={itemVariants}
        className="tab-item-container"
        whileHover={{
          y: -2,
        }}>
        <HomeTab
          title={item.label ? tr(`home.${item.label}`) : item.tab_name || ''}
          imageUri={item.imageUri || item.img}
          defaultImage={item.img || ''}
          onPress={multiTabKey => onPress(item, multiTabKey)}
          isPrimary={item.label === 'medicalUpdates'}
          multiTabs={
            (item.label === 'drugsAndDiseases'
              ? drugsAndDiseasesOptions
              : item.multiTabs) || []
          }
        />
      </TabItem>
    );
  },
);

TabItemComponent.displayName = 'TabItemComponent';

const Home = () => {
  const theme = useTheme() as Theme;
  const navigate = useNavigate();

  useSetMainLayoutProps({
    showHeader: false,
    showBack: false,
    showSearch: false,
    backgroundImage: null,
    showBackgroundImage: false,
    showDrawer: true,
    showMenu: true,
  });

  // Selective state selection
  const token = useSelector(
    (state: RootState) => state.auth.currentUser?.token,
  );
  const {mainHomeTabs, recommendations, isLoading, isError} = useSelector(
    (state: RootState) => state.homeTabs,
  );

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    initMainHomeTabs();
  }, []);

  useEffect(() => {
    if (!isInitialized && token) {
      getHomeTabs();
      setIsInitialized(true);
    }
  }, [isInitialized, token]);

  // Memoized callbacks
  const onHomeTabPressHandler = useCallback(
    (item: TabItemType, multiTabKey?: string) => {
      if (item.label === 'drugsAndDiseases') {
        navigate(Paths.content.drugs);
      } else if (item.label === 'calculator') {
        navigate(Paths.content.calculators, {
          state: {data: item.calculator_data},
        });
      } else if (item.pdf_link || item.fileUrl) {
        navigate(Paths.content.pdfViewer, {
          state: item,
        });
      } else {
        navigate(Paths.content.tabNews, {
          state: {
            ...(item || {}),
          },
        });
      }
    },
    [],
  );

  // Memoized callbacks
  const onTabPressHandler = useCallback(
    (item: TabItemType, multiTabKey?: string) => {
      if (item?.label === 'medicalUpdates') {
        navigate(Paths.content.medicalUpdates, {state: item});
        return;
      }
      if (item?.label === 'qna') {
        navigate(Paths.content.qna);
        return;
      }
      if (item?.label === 'clinicalCase') {
        navigate(Paths.content.clinicalCase);
        return;
      }
      if (item?.label === 'drugsAndDiseases') {
        navigate(Paths.content.drugs, {
          state: {
            ...(item || {}),
            isDiseases: multiTabKey === 'diseases',
            id: multiTabKey === 'diseases' ? TabIds.Diseases : TabIds.Drugs,
          },
        });
        return;
      }
      onHomeTabPressHandler(item);
    },
    [],
  );

  const onRecommendationPress = useCallback(
    (item: NewsItemType) => {
      navigate(Paths.content.article, {
        state: {item, isHomeRecommendation: true},
      });
    },
    [navigate],
  );

  const onViewAllPress = useCallback(() => {
    navigate(Paths.content.news, {
      state: {isHomeRecommendation: true},
    });
  }, [navigate]);

  // Memoized container variants
  const containerVariants = useMemo(
    () => ({
      hidden: {opacity: 0},
      visible: {
        opacity: 1,
        transition: {
          staggerChildren: 0.1,
        },
      },
    }),
    [],
  );

  // Memoized render functions
  const renderHomeTabs = useCallback(() => {
    if (isLoading) {
      return (
        <TabsContainer>
          <HomeTabsSkeleton />
        </TabsContainer>
      );
    }

    return (
      <TabsContainer>
        <TabGrid
          as={motion.div}
          variants={containerVariants}
          initial="hidden"
          animate="visible">
          {mainHomeTabs.map((item, index) => (
            <TabItemComponent
              key={item.id || index}
              item={item}
              index={index}
              onPress={onTabPressHandler}
            />
          ))}
        </TabGrid>
      </TabsContainer>
    );
  }, [mainHomeTabs, isLoading, containerVariants, onTabPressHandler]);

  const renderLatestNews = useCallback(() => {
    return (
      <LatestNewsContainer
        initial={{opacity: 0, y: 50}}
        animate={{opacity: 1, y: 0}}
        transition={{
          type: 'spring',
          stiffness: 300,
          damping: 24,
          delay: 0.3,
        }}>
        {recommendations.length !== 0 && !isLoading && (
          <SectionTitle>{tr('home.latestNewsTitle')}</SectionTitle>
        )}
        {recommendations.length === 0 ? (
          _renderEmptyRecommendations()
        ) : (
          <RecommendationsGrid>
            {recommendations
              ?.slice(0, 3)
              .map((item, index) => (
                <RecommendationItem
                  key={item.id}
                  item={item}
                  index={index}
                  onPress={onRecommendationPress}
                />
              ))}
          </RecommendationsGrid>
        )}
        {recommendations.length > 3 && (
          <ViewAllContainer onClick={onViewAllPress}>
            <ViewAll>{tr('app.viewAll')}</ViewAll>
            <Icon type={'SVG'} name={'navArrow'} size={theme.text.s3} />
          </ViewAllContainer>
        )}
      </LatestNewsContainer>
    );
  }, [
    recommendations,
    isLoading,
    theme.text.s3,
    onRecommendationPress,
    onViewAllPress,
  ]);

  const renderErrorState = useCallback(
    () => (
      <ErrorContainer
        initial={{opacity: 0, scale: 0.9}}
        animate={{opacity: 1, scale: 1}}
        transition={{duration: 0.5}}>
        <EmptyList
          iconName={'error'}
          iconType={'SVG'}
          iconSize={'8rem'}
          onRefreshPress={() => getHomeTabs()}
          title={'app.error'}
          showRefresh
          containerStyle={{
            backgroundColor: theme.text.light,
          }}
        />
      </ErrorContainer>
    ),
    [theme.text.light],
  );

  const _renderEmptyRecommendations = () => {
    if (isError) {
      return (
        <EmptyContainer>
          <EmptyList
            iconName={'error'}
            iconType={'SVG'}
            iconSize={'8rem'}
            onRefreshPress={() => getHomeTabs()}
            title={'app.error'}
            showRefresh
            containerStyle={{
              backgroundColor: theme.text.light,
            }}
          />
        </EmptyContainer>
      );
    }
    if (isLoading) {
      return <LatestNewsSkeleton />;
    }
    return null;
  };

  return (
    <Container>
      <HomeHeader />
      {isError ? (
        renderErrorState()
      ) : (
        <TopContainer>
          {renderHomeTabs()}
          {renderLatestNews()}
        </TopContainer>
      )}
    </Container>
  );
};

export default React.memo(Home);
