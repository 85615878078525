// Dermatology Guidelines
import {basePdfUrl} from '../../../../../api/api';

const guidelinesGlDermatology = basePdfUrl + 'gl/dermatology/';

export const guidelinesDermatologyData = [
  {
    id: 0,
    title: 'Dermatitis and Urticaria',
    pdf_link:
      guidelinesGlDermatology + '3-Dermatitis-and-Urticaria-Guidelines.pdf',
  },
  {
    id: 1,
    title: 'Lupus',
    pdf_link: guidelinesGlDermatology + '3-Lupus-Guidelines.pdf',
  },
  {
    id: 2,
    title: 'Acne',
    pdf_link: guidelinesGlDermatology + 'Acne-Guidelines.pdf',
  },
  {
    id: 3,
    title: 'Anogenital Warts',
    pdf_link: guidelinesGlDermatology + 'Anogenital-Warts-Guidelines.pdf',
  },
  {
    id: 4,
    title: 'Psoriasis',
    pdf_link: guidelinesGlDermatology + 'Psoriasis-Guidelines.pdf',
  },
  {
    id: 5,
    title: 'Rosacea',
    pdf_link: guidelinesGlDermatology + 'Rosacea-Guidelines.pdf',
  },
  {
    id: 6,
    title: 'Skin Cancer',
    pdf_link: guidelinesGlDermatology + 'Skin-Cancer-Guidelines.pdf',
  },
  {
    id: 7,
    title: 'Tinea Infection',
    pdf_link: guidelinesGlDermatology + 'TINEA-INFECTION-GUIDELINES.pdf',
  },
  {
    id: 8,
    title: 'Varicella Zoster Virus',
    pdf_link: guidelinesGlDermatology + 'Varicella-Zoster-Virus-GUIDELINES.pdf',
  },
  {
    id: 9,
    title: 'Vitiligo',
    pdf_link: guidelinesGlDermatology + 'vitiligo-Guidelines.pdf',
  },
];
