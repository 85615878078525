// Ophthalmology
import {basePdfUrl} from '../../../../../api/api';

const diagnosticToolsDtOphthalmology = basePdfUrl + 'dt/ophthalmology/';

export const diagnosticToolsOphthalmologyData = [
  {
    id: 0,
    title: 'Age-Related Macular Degeneration',
    pdf_link: diagnosticToolsDtOphthalmology + '1-AMD-Diagnostic-Tools.pdf',
  },
  {
    id: 1,
    title: 'Eye Infection',
    pdf_link:
      diagnosticToolsDtOphthalmology + '1-Eye-infection-diagnostic-tools.pdf',
  },
  {
    id: 2,
    title: 'Cataract',
    pdf_link: diagnosticToolsDtOphthalmology + 'Cataract-Diagnosis.pdf',
  },
  {
    id: 3,
    title: 'Diabetic Retinopathy',
    pdf_link:
      diagnosticToolsDtOphthalmology + 'Diabetic-Retinopathy-Diagnosis.pdf',
  },
  {
    id: 4,
    title: 'Dry Eye Syndrome',
    pdf_link: diagnosticToolsDtOphthalmology + 'Dry-Eye-Syndrome-Diagnosis.pdf',
  },
  {
    id: 5,
    title: 'Glaucoma',
    pdf_link: diagnosticToolsDtOphthalmology + 'Glaucoma-Diagnosis.pdf',
  },
  {
    id: 6,
    title: 'Graves Eye Disease',
    pdf_link:
      diagnosticToolsDtOphthalmology + 'Graves-Eye-Disease-Diagnosis.pdf',
  },
  {
    id: 7,
    title: 'Idiopathic Intracranial Hypertension',
    pdf_link:
      diagnosticToolsDtOphthalmology +
      'Idiopathic-Intracranial-Hypertension-Diagnosis-.pdf',
  },
  {
    id: 8,
    title: 'Refractive Eye Errors',
    pdf_link:
      diagnosticToolsDtOphthalmology + 'Refractive-Eye-Errors-Diagnosis.pdf',
  },
  {
    id: 9,
    title: 'Retinoblastoma',
    pdf_link: diagnosticToolsDtOphthalmology + 'Retinoblastoma-Diagnosis.pdf',
  },
];
