import React, {useEffect, useState} from 'react';
import styled, {css, useTheme} from 'styled-components';
import {Theme} from '../../theme/lightTheme';
import moment from 'moment';
import GenericButton from '../button';
import Icon from '../icon';
import {VoiceQuestionInputProps} from '../../interfaces/components/voiceQuestionInput';
import {motion, Variants} from 'framer-motion';
import {tr} from '../../translation';
import TextInput from '../textInput';
import {FaPause, FaMicrophone} from 'react-icons/fa6';

const questionContainerVariants: Variants = {
  closed: {
    height: '3.5rem',
    padding: 0,
    borderRadius: 100,
    transition: {
      type: 'tween',
      duration: 0.2,
    },
  },
  open: {
    height: 'auto',
    padding: '1rem',
    borderRadius: 16,
    transition: {
      type: 'tween',
      duration: 0.2,
    },
  },
};

const Container = styled(motion.div).attrs({
  initial: 'closed',
  variants: {open: {borderRadius: 20}, close: {borderRadius: 100}},
})`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
`;
const ContentContainer = styled.div<{isClose: boolean}>`
  position: relative;
  display: flex;
  width: 100%;
  padding: ${({isClose}) => (isClose ? '0.5rem' : '3% 5%')};
  background: linear-gradient(
    135deg,
    ${({theme}) => theme.voiceQuestionInput.background + 'BB'} 0%,
    ${({theme}) => theme.voiceQuestionInput.background + '44'} 100%
  );
  flex-direction: column;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
  box-shadow: 0 8px 16px ${({theme}) => theme.shadow};
  backdrop-filter: blur(6px);
`;
const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: ${({theme}) => `calc(${theme.text.s2} / 4)`};
`;
const ImageContainer = styled.div`
  width: ${({theme}) => theme.text.s2};
  aspect-ratio: 1;
  border-radius: ${({theme}) => `calc(${theme.text.s2} / 2)`};
  overflow: hidden;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({theme}) => `calc(${theme.text.s2} / 4)`};
  border: 1px solid ${({theme}) => theme.primary};
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const Name = styled.p`
  font-size: ${({theme}) => theme.text.s8};
  color: ${({theme}) => theme.text.title};
  font-weight: 700;
`;
const DateContainer = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
`;
const DateText = styled.p`
  font-size: ${({theme}) => theme.text.s11};
  line-height: ${({theme}) => theme.text.s11};
  letter-spacing: 0.5px;
  color: ${({theme}) => theme.text.grey + '55'};
  font-weight: bold;
`;

const QuestionContainer = styled(motion.div).attrs({
  initial: 'closed',
  variants: questionContainerVariants,
})<{isClose: boolean}>`
  background-color: ${({theme}) => theme.voiceQuestionInput.background};
  box-shadow: 0 4px 8px ${({theme}) => theme.text.dark + '22'};
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  ${({isClose}) =>
    isClose &&
    css`
      flex-direction: row;
    `};
`;
const TextInputContainer = styled(motion.div)<{isClose: boolean}>`
  flex: 1;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  ${({isClose}) =>
    isClose &&
    css`
      border-radius: 100px;
      flex-direction: row;
      flex: 1;
      margin: 0.5rem;
    `};
`;
const TextInputItem = styled(TextInput)`
  width: 100%;

  .input-container {
    box-shadow: none;
  }
  .input::placeholder {
    color: ${({theme}) => theme.text.grey + 'BB'};
    aspect-ratio: 2.3;
  }
`;
const CheckAnswerButton = styled(GenericButton)`
  align-self: center;
  margin-top: 1rem;
`;
const ArrowContainer = styled(motion.button)`
  height: ${({theme}) => theme.text.s55};
  aspect-ratio: 1;
  align-self: center;
  margin-top: 0.5rem;
  border: none;
  background: none;
`;
const BottomContainer = styled.div`
  max-width: 75%;
  display: flex;
  position: relative;
  align-self: center;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 1rem;
`;
const BottomIconContainer = styled(motion.button)`
  width: 3.2rem;
  aspect-ratio: 1;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.text.description};
  border-radius: 100px;
  background: ${({theme}) =>
    `linear-gradient(45deg, ${theme.voiceQuestionInput.mainButtonStartColor}, ${theme.voiceQuestionInput.mainButtonEndColor})`};
  border: none;
`;
const OpenContainer = styled(motion.button)`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 2rem;
  border-radius: 100px;
  background-color: ${({theme}) => theme.text.dark + '11'};
  align-items: center;
  justify-content: center;
  display: flex;
  border: none;
  transform: rotate(180deg);
`;

/**
 * VoiceQuestionInput component.
 *
 * @param style
 * @param name
 * @param imageUri
 * @param dynamicHeight
 * @param onCheckAnswerPressed
 * @param onRecordPress
 * @param isRecording
 * @param {VoiceQuestionInputProps} props - Props including style customizations and additional TextInput properties.
 * @returns {JSX.Element} The stylized QuestionInput component with conditional theming and localized placeholders.
 */
const VoiceQuestionInput: React.FC<VoiceQuestionInputProps> = ({
  className,
  style,
  name,
  imageUri,
  onCheckAnswerPressed,
  onRecordPress,
  isRecording,
  alwaysShowTextInput,
  isClose,
  hideHeader,
  onOpenPress,
  isError,
  ...props
}: VoiceQuestionInputProps): JSX.Element => {
  const theme = useTheme() as Theme;
  const [date, setDate] = useState('');

  const textInputContainerVariants: Variants = {
    closed: {
      backgroundColor: theme.text.border + '22',
    },
    open: {
      backgroundColor: theme.text.border + '77',
    },
  };

  useEffect(() => {
    if ((props?.value + '' || '').trim().length !== 0) {
      setDate(moment().format('h:mm A'));
    } else {
      setDate('');
    }
  }, [props]);

  // Function to handle what happens when Enter is pressed
  const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onCheckAnswerPressed && onCheckAnswerPressed();
    }
  };

  return (
    <Container
      className={className}
      animate={isClose ? 'close' : 'open'}
      style={style}>
      <ContentContainer isClose={isClose}>
        {!hideHeader && (
          <HeaderContainer>
            <ImageContainer>
              <Image
                src={
                  imageUri
                    ? {uri: imageUri}
                    : require('../../assets/images/user.png')
                }
              />
            </ImageContainer>
            <Name>{name}</Name>
          </HeaderContainer>
        )}
        <QuestionContainer
          isClose={isClose}
          animate={isClose ? 'closed' : 'open'}>
          <TextInputContainer
            isClose={isClose}
            initial={'closed'}
            variants={textInputContainerVariants}
            animate={
              (props?.value + '' || '').trim().length !== 0 ||
              alwaysShowTextInput
                ? 'open'
                : 'closed'
            }>
            <TextInputItem
              {...props}
              style={{
                color: theme.voiceQuestionInput.text,
                fontSize: theme.text.s8,
              }}
              inputContainerStyle={{
                borderWidth: 0,
                borderRadius: 10,
              }}
              multiline={!isClose}
              disabled={isClose}
              onKeyDown={handleEnterPress}
              isUserTyping={!isRecording}
            />
            <DateContainer>
              <DateText>{date}</DateText>
            </DateContainer>
            {isClose && (
              <OpenContainer whileTap={{opacity: 0.7}} onClick={onOpenPress}>
                <motion.div
                  animate={{
                    y: ['-0.3rem', '0.3rem'],
                  }}
                  transition={{
                    duration: 1,
                    repeat: Infinity,
                    repeatType: 'mirror',
                  }}>
                  <Icon name={'arrows'} size={theme.text.s55} />
                </motion.div>
              </OpenContainer>
            )}
          </TextInputContainer>
          {!isClose && (
            <>
              <CheckAnswerButton
                disabled={!props?.value}
                isSmall
                outlineBackgroundColor={theme.text.light}
                label={tr('voiceInteraction.checkAnswer')}
                labelStyle={{
                  fontSize: theme.text.s8,
                  fontWeight: 500,
                }}
                showRightIcon={isError}
                rightIconName={'refresh'}
                rightIconType={'MaterialCommunityIcons'}
                onPress={onCheckAnswerPressed}
              />
              <ArrowContainer
                whileTap={{opacity: 0.7}}
                onClick={onOpenPress}
                animate={{
                  y: ['-0.4rem', '0.4rem'],
                }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  repeatType: 'mirror',
                }}>
                <Icon
                  name={'arrowDown'}
                  color={theme.text.grey + 'AA'}
                  size={theme.text.s4}
                />
              </ArrowContainer>
            </>
          )}
        </QuestionContainer>
        {!isClose && (
          <BottomContainer>
            <BottomIconContainer
              disabled={!onRecordPress}
              onClick={onRecordPress}
              whileTap={{opacity: 0.7}}>
              {isRecording ? (
                <FaPause color={theme.text.light} size={theme.text.s5} />
              ) : (
                <FaMicrophone color={theme.text.light} size={theme.text.s5} />
              )}
            </BottomIconContainer>
          </BottomContainer>
        )}
      </ContentContainer>
    </Container>
  );
};

export default VoiceQuestionInput;
