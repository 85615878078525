/**
 * Types of result response.
 */
export enum Result {
  SUCCESS = 'success',
  ERROR = 'error',
}
/**
 * Defines various constants used in the application.
 */

/**
 * Tab names for the home page.
 */
export const tabs = {
  latestUpdates: 'latest_updates',
  treatmentOptions: 'treatment_options',
  guidelines: 'guidelines',
  diagnosticTools: 'diagnostic_tools',
  drugs: 'drugs',
  media: 'media',
  latestInnovations: 'latest_innovations',
  labValues: 'lab-values',
  medicalCalculators: 'medical-calculators',
  congresses: 'congresses',
  medicalOpportunities: 'medical_job',
  drugInteractions: 'drug-interactions',
  pocketGuides: 'pocket-guides',
  CME: 'CME', // Continuing Medical Education
};
export const Speciality = {
  Respiratory: 'Respiratory',
  Cardiology: 'Cardiology',
};

export enum SpecialityType {
  Cardiology = 1,
  Respiratory = 2,
  Dermatology = 67,
  Endocrinology = 37,
  Neurology = 116,
  Ophthalmology = 133,
  Pediatrics = 94,
  Urology = 83,
  Anti = 192,
  Gastroenterology = 168,
  rheumatology = 148,
}

export const Subspeciality = {
  Respiratory: {
    Asthma: 'Asthma',
    COPD: 'COPD',
    Bronchitis: 'Bronchitis',
    Pneumonia: 'Pneumonia',
    CommonCold: 'Common Cold/Flu',
    LungCancer: 'Lung Cancer',
    Pharyngitis: 'Pharyngitis',
    Sinusitis: 'Sinusitis',
    OtitisMedia: 'Otitis Media',
    Tuberculosis: 'Tuberculosis',
  },
  Cardiology: {
    HeartFailure: 'Heart Failure',
    Hypertension: 'Hypertension',
    AtrialFibrillation: 'Atrial Fibrillation',
    CoronaryArteryDisease: 'Coronary artery disease (CAD)',
    Stroke: 'Stroke',
    RheumaticHeartDisease: 'Rheumatic heart disease',
    PulmonaryArterialHypertension: 'Pulmonary Arterial Hypertension',
    MyocardialInfarction: 'Myocardial infarction',
    Atherosclerosis: 'Atherosclerosis',
  },
};

export const TypeOfStudiesAndReaserch = {
  Presentations: 'Presentations',
  Interviews: 'Interviews',
  CaseStudies: 'Case Studies',
  Webinars: 'Webinars',
  MedicalNews: 'Medical News',
};

export const Sensation = {
  Neutral: 'Neutral',
  Positive: 'Positive',
};
export const Resource = {
  MedicalJournals: 'Medical Journals',
  MedicalWebsites: 'Medical Websites',
  ResearchAndMedicalCenters: 'Research and Medical Centers',
};

export enum TabIds {
  LatestUpdates = 1,
  Media = 5,
  Congresses = 9,
  TreatmentOptions = 2,
  Guidelines = 3,
  DiagnosticTools = 4,
  LatestInnovations = 6,
  MedicalJobOpportunities = 7,
  Diseases = 13,
  Drugs = 8,
  Reviews = 3, // Same ID as Guidelines
  CaseReport = 6, // Same ID as LatestInnovations
}

// Create a map to prioritize names for each ID
export const tabIdNameMap: {[key: number]: string} = {
  1: 'LatestUpdates',
  2: 'TreatmentOptions',
  3: 'Guidelines',
  4: 'DiagnosticTools',
  5: 'Media',
  6: 'LatestInnovations',
  7: 'MedicalJobOpportunities',
  8: 'Drugs',
  9: 'Congresses',
};

// Define the enum for action types
export enum ActionType {
  ADD_TO_FAVORITES = 'ADD_TO_FAVORITES',
  REMOVE_FROM_FAVORITES = 'REMOVE_FROM_FAVORITES',
  CREATE_NOTE = 'CREATE_NOTE',
  DELETE_NOTE = 'DELETE_NOTE',
  EDIT_NOTE = 'EDIT_NOTE',
}

// Define the enum for article types
export enum ArticleType {
  SUMMARY = 'SUMMARY',
  RECOMMENDATIONS = 'RECOMMENDATIONS',
  NEWS = 'NEWS',
}

export enum LoginFrequency {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
  rarely = 'rarely',
  firstTime = 'first_time',
}

export enum AnalyticsEvents {
  AppOpen = 'APP_OPEN',
  NewsArticleCompleted = 'NEWS_ARTICLE_COMPLETED',
  RecommendationArticleCompleted = 'RECOMMENDATION_ARTICLE_COMPLETED',
  SummaryArticleCompleted = 'SUMMARY_ARTICLE_COMPLETED',
  DrugArticleCompleted = 'DRUG_ARTICLE_COMPLETED',
  NoteActions = 'NOTE_ACTIONS',
  ToggleFavorite = 'TOGGLE_FAVORITE',
  OpenQAndA = 'OPEN_Q_AND_A',
  OpenTab = 'OPEN_TAB',
  OpenSearch = 'OPEN_SEARCH',
  OpenAfterLogin = 'OPEN_AFTER_LOGIN',
}

/**
 * Types for deep Linking schema
 */
export enum deepLinkingTypes {
  article = 'article?token=',
  sharedArticle = 'shared-article',
  empty = '',
}

export enum NewsOptionTab {
  Summaries = 'summaries',
  Analysis = 'analysis',
  Predictions = 'predictions',
}

/**
 * Enum to represent message status states
 */
export enum MessageStatus {
  NotSent, // Message not sent yet (locally)
  SentNotReceived, // Sent but not received by everyone
  ReceivedNotRead, // Received by everyone but not read by everyone
  ReadByEveryone, // Received and read by everyone
}

/**
 * Enum to represent chat type
 */
export enum ChatType {
  QNA = 1,
  ClinicalCase = 2,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Drugs = 1,
}
