import {InternalAxiosRequestConfig} from 'axios';
import {api} from '../api/api';

export const cacheKey = (config: InternalAxiosRequestConfig<any>) =>
  `${config.url}-${config.headers.Authorization}-${JSON.stringify(config.params || {})}`;

export const setCache = (
  key,
  data,
  cache,
  ttl = process.env.REACT_APP_DEBUG === 'true' ? 30000 : 30000,
) => {
  // ttl is time to live in ms (default: 5 minutes)
  if (
    key.includes(api.qna.ask) ||
    key.includes(api.qna.userThreads) ||
    key.includes(api.translate.googleTranslate)
  ) {
    return;
  }
  const expiryTime = Date.now() + ttl;
  cache.set(key, {data, expiryTime});
};

export const getCache = (key: string, cache: Map<any, any>) => {
  const cached = cache.get(key);
  if (!cached) return null;

  // Check if the cache has expired
  if (Date.now() > cached.expiryTime) {
    cache.delete(key);
    return null;
  }

  return cached.data;
};
