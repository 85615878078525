import {setIsOnboardingSeen} from './reducer';
import {store} from '../../../redux/store';
import {keys} from '../../../api/keys';
import {storage} from '../../../utils/storage';

/**
 * Hide Onboarding screen.
 */
export const initOnboardingScreen = async () => {
  const isSeen = await storage.get<boolean>(keys.ONBOARDING);
  store.dispatch(setIsOnboardingSeen({isSeen: !!isSeen}));
};
/**
 * Hide Onboarding screen.
 */
export const hideOnboardingScreen = () => {
  storage.set(keys.ONBOARDING, true);
  store.dispatch(setIsOnboardingSeen({isSeen: true}));
};
