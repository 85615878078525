import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ChatState, MessageItem} from '../../../interfaces/reducers/chat';
import {
  ChatModelsItem,
  ChatQuestionItem,
  ThreadItemType,
} from '../../../interfaces/api/qna';

const initialState: ChatState = {
  presentUsers: [],
  data: [],
  unreadMessages: [],
  isLoading: false,
  isError: false,
  isLastPage: false,
  isMessageError: false,
  isMessageLoading: false,
  streamMessageLoadingId: null,
  status: {},
  page: 1,
  threadId: undefined,
  threads: [],
  isChatQuestionsLoading: false,
  isChatQuestionsError: false,
  questions: [],
  models: [],
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setChatIsLoading: state => {
      state.isLoading = true;
      state.isError = false;
    },
    setChatIsEnd: (state, action: PayloadAction<boolean>) => {
      state.isLastPage = action.payload;
    },
    setChatIsFail: state => {
      state.isLastPage = true;
      state.isLoading = false;
      state.isError = true;
    },
    setMessageSuccess: state => {
      state.isMessageLoading = false;
      state.isMessageError = false;
    },
    setMessageIsLoading: state => {
      state.isMessageLoading = true;
      state.isMessageError = false;
    },
    setMessageIsFail: state => {
      state.isMessageLoading = false;
      state.isMessageError = true;
    },
    setChat: (state, action: PayloadAction<{data: MessageItem[]}>) => {
      state.data = action.payload.data;
      state.isLoading = false;
      state.isError = false;
    },
    setUnreadMessages: (
      state,
      action: PayloadAction<{data: MessageItem[]}>,
    ) => {
      state.unreadMessages = action.payload.data;
    },
    setPresentUsers: (state, action: PayloadAction<{data: string[]}>) => {
      state.presentUsers = action.payload.data;
    },
    setTypingStatus: (
      state,
      action: PayloadAction<{
        status: {userId: number | string; isTyping: boolean};
      }>,
    ) => {
      state.status = action.payload.status;
      state.isLoading = false;
      state.isError = false;
    },
    setCurrentThreadId: (state, action: PayloadAction<string | undefined>) => {
      state.threadId = action.payload;
    },
    setUserThreadsLoading: state => {
      state.isUserThreadsLoading = true;
      state.isUserThreadsError = false;
    },
    setUserThreadsFail: state => {
      state.isUserThreadsLoading = false;
      state.isUserThreadsError = true;
    },
    setUserThreads: (state, action: PayloadAction<Array<ThreadItemType>>) => {
      state.threads = action.payload;
      state.isUserThreadsLoading = false;
      state.isUserThreadsError = false;
    },
    setStreamMessageLoadingId: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.streamMessageLoadingId = action.payload;
    },
    setChatQuestionsLoading: state => {
      state.isChatQuestionsLoading = true;
      state.isChatQuestionsError = false;
      state.questions = [];
    },
    setChatQuestionsFail: state => {
      state.isChatQuestionsLoading = false;
      state.isChatQuestionsError = true;
    },
    setChatQuestions: (
      state,
      action: PayloadAction<Array<ChatQuestionItem>>,
    ) => {
      state.questions = action.payload;
      state.isChatQuestionsLoading = false;
      state.isChatQuestionsError = false;
    },
    setChatModels: (state, action: PayloadAction<Array<ChatModelsItem>>) => {
      state.models = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setChatPage,
  setChatIsLoading,
  setChatIsEnd,
  setMessageSuccess,
  setChatIsFail,
  setChat,
  setTypingStatus,
  setMessageIsLoading,
  setMessageIsFail,
  setUnreadMessages,
  setPresentUsers,
  setCurrentThreadId,
  setUserThreadsLoading,
  setUserThreadsFail,
  setUserThreads,
  setStreamMessageLoadingId,
  setChatQuestionsLoading,
  setChatQuestionsFail,
  setChatQuestions,
  setChatModels,
} = chatSlice.actions;

export default chatSlice.reducer;
