import {
  finishLoading,
  startLoading,
} from '../../../connected-components/loading/actions';
import {services} from '../../../api';

export interface GetShareLinkDetailsResponse {
  content: ShareLinkDetailsData;
}
export interface GenerateShareLinkResponse {
  content: {
    token: string;
  };
}

export interface GenerateShareLinkBody {
  model_id?: number;
  shared_via?: string;
  type?: 'Article' | 'Recommendation' | 'Summary';
}
export interface ShareLinkDetailsData {
  id: number;
  model_id: number;
  type: 'Article' | 'Recommendation' | 'Summary';
  model: NewsItemDetailsType;
}
export interface NewsItemDetailsType {
  id?: number;
  specialty?: string;
  subspecialty?: string;
  type_of_study_value?: string;
  resource?: string;
  publishdate?: string;
  title?: string;
  url?: string;
  publisher?: string;
  sentiment?: string;
  readabilityscore?: string;
  subspecialty_id?: number;
  img?: string;
  sentiment_id?: any;
  accesslevel?: string;
  //for summary
  origin?: string;
  summary?: string;
  summarydate?: string;
  onCustomSeeMorePress?: () => void;
  isLanguageRTL?: boolean;
  tableTranslations?: TableLabelTranslations;
  created_at?: string;
  normal_summarization?: string;
  description?: string;
  is_favorite?: boolean;
}
export interface TableLabelTranslations {
  specialty: string;
  subspecialty: string;
  url: string;
  publisher: string;
  resource: string;
  origin: string;
  readabilityscore: string;
  sentiment: string;
  accesslevel: string;
  date: string;
  type_of_study_value: string;
  summary: string;
  viewMore: string;
  successMessage: string;
}

/**
 * this function to generate shared News link
 *
 * @return {string | null} shared link to news detail
 */
export const generateShareLink = async (
  body: GenerateShareLinkBody,
): Promise<string | null> => {
  try {
    startLoading();
    const res = await services.news.generateShareLink(body);
    return res?.content?.token;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Generate Share Link FAIL: ', error);
    }
    return null;
  } finally {
    finishLoading();
  }
};

/**
 * this function to get Share Link News Details
 *
 * @return {SharedLinkDetailsData | null} shared link news detail date
 */
export const getShareLinkNewsDetails = async (
  token: string,
): Promise<ShareLinkDetailsData | null> => {
  try {
    const res = await services.news.getShareLinkDetails(token);
    return res?.content;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Get Share Link News Details FAIL: ', error);
    }
    return null;
  }
};
