import {StackItem} from '../../interfaces/router/stackItem';
import paths from '../../config/paths';
import {lazy} from 'react';

const Home = lazy(() => import('../../screens/content/home'));
const MedicalUpdates = lazy(
  () => import('../../screens/content/home/medicalUpdates'),
);
const News = lazy(() => import('../../screens/content/news'));
const TabNews = lazy(() => import('../../screens/content/tabNews'));
const Search = lazy(() => import('../../screens/content/search'));
const PdfViewer = lazy(() => import('../../screens/content/pdfViewer'));
const Article = lazy(() => import('../../screens/content/article'));
const Support = lazy(() => import('../../screens/content/support'));
const Favorite = lazy(() => import('../../screens/content/favorite'));
const Notes = lazy(() => import('../../screens/content/notes'));
const ViewNote = lazy(() => import('../../screens/content/notes/viewNote'));
const AddNote = lazy(() => import('../../screens/content/notes/addNote'));
const Calculators = lazy(() => import('../../screens/content/calculators'));
const Profile = lazy(() => import('../../screens/content/profile'));
const GenioChat = lazy(() => import('../../screens/content/voiceInteraction'));
const Summaries = lazy(() => import('../../screens/content/summaries'));
const Drugs = lazy(() => import('../../screens/content/drugs'));
const Chat = lazy(() => import('../../screens/content/chat'));
const ClinicalCase = lazy(() => import('../../screens/content/clinicalCase'));

/**
 * An array of content routes for a navigation stack.
 * @type {StackItem[]}
 */
const contentRoutes: StackItem[] = [
  {
    path: paths.content.home,
    component: Home,
  },
  {
    path: paths.content.medicalUpdates,
    component: MedicalUpdates,
  },
  {
    path: paths.content.news,
    component: News,
  },
  {
    path: paths.content.tabNews,
    component: TabNews,
  },
  {
    path: paths.content.search,
    component: Search,
  },
  {
    path: paths.content.pdfViewer,
    component: PdfViewer,
  },
  {
    path: paths.content.article,
    component: Article,
  },
  {
    path: paths.content.favorite,
    component: Favorite,
  },
  {
    path: paths.content.contactUs,
    component: Support,
  },
  {
    path: paths.content.notes,
    component: Notes,
  },
  {
    path: paths.content.viewNote,
    component: ViewNote,
  },
  {
    path: paths.content.editNote,
    component: AddNote,
  },
  {
    path: paths.content.calculators,
    component: Calculators,
  },
  {
    path: paths.content.profile,
    component: Profile,
  },
  {
    path: paths.content.genioChat,
    component: GenioChat,
  },
  {
    path: paths.content.clinicalCase,
    component: ClinicalCase,
  },
  {
    path: paths.content.qna,
    component: Chat,
  },
  {
    path: paths.content.summaries,
    component: Summaries,
  },
  {
    path: paths.content.drugs,
    component: Drugs,
  },
  {
    path: paths.content.diseases,
    component: Drugs,
  },
];

export default contentRoutes;
