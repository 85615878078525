import React, {SVGProps} from 'react';

const SvgComponent = ({
  width = 24,
  height = 24,
  fill = '#0B0D6F',
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      stroke="none"
      {...props}>
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M8 5v14m8-14v14"
      />
    </svg>
  );
};

export default SvgComponent;
