// Pediatrics Treatment Options
import {basePdfUrl} from '../../../../../api/api';

const treatmentOptionsToPediatrics = basePdfUrl + 'to/pediatrics/';

export const treatmentOptionsPediatricsData = [
  {
    id: 0,
    title: 'Pediatric Pneumonia',
    pdf_link:
      treatmentOptionsToPediatrics +
      '2-Pediatric-Pneumonia-Treatment-Options.pdf',
  },
  {
    id: 1,
    title: 'Gastroenteritis and Constipation',
    pdf_link:
      treatmentOptionsToPediatrics +
      '2-gastroenteritis-and-constipation-treatment.pdf',
  },
  {
    id: 2,
    title: 'Asthma',
    pdf_link: treatmentOptionsToPediatrics + 'Asthma-Treatment-Option.pdf',
  },
  {
    id: 3,
    title: 'Croup',
    pdf_link: treatmentOptionsToPediatrics + 'Croup-Treatment-Options-.pdf',
  },
  {
    id: 4,
    title: 'Influenza',
    pdf_link: treatmentOptionsToPediatrics + 'Influenza-Treatment-Options.pdf',
  },
  {
    id: 5,
    title: 'Measles, Mumps, Rubella',
    pdf_link:
      treatmentOptionsToPediatrics + 'Measles-Mumps-Rubella-Treatment.pdf',
  },
  {
    id: 6,
    title: 'Pediatric Allergic Rhinitis',
    pdf_link:
      treatmentOptionsToPediatrics +
      'Pediatric-Allergic-Rhinitis-Treatment.pdf',
  },
  {
    id: 7,
    title: 'Pediatric Hepatitis',
    pdf_link:
      treatmentOptionsToPediatrics + 'Pediatric-Hepatitis-Treatment-Option.pdf',
  },
  {
    id: 8,
    title: 'Pediatric Urinary Tract Infection',
    pdf_link:
      treatmentOptionsToPediatrics +
      'Pediatric-Urinary-Tract-Infection-Treatment.pdf',
  },
];
