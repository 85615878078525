// Pediatrics Guidelines
import {basePdfUrl} from '../../../../../api/api';

const guidelinesGlPediatrics = basePdfUrl + 'gl/pediatrics/';

export const guidelinesPediatricsData = [
  {
    id: 0,
    title: 'Pediatric Pneumonia',
    pdf_link: guidelinesGlPediatrics + '3-Pediatric-Pneumonia-Guidelines.pdf',
  },
  {
    id: 1,
    title: 'Gastroenteritis and Constipation',
    pdf_link:
      guidelinesGlPediatrics +
      '3-gastroenteritis-and-constipation-guidelines.pdf',
  },
  {
    id: 2,
    title: 'Asthma',
    pdf_link: guidelinesGlPediatrics + 'Asthma-Guideline.pdf',
  },
  {
    id: 3,
    title: 'Croup',
    pdf_link: guidelinesGlPediatrics + 'Croup-Guidelines.pdf',
  },
  {
    id: 4,
    title: 'Influenza',
    pdf_link: guidelinesGlPediatrics + 'Influenza-Recommendations.pdf',
  },
  {
    id: 5,
    title: 'Measles, Mumps, Rubella',
    pdf_link: guidelinesGlPediatrics + 'Measles-Mumps-Rubella-guidelines.pdf',
  },
  {
    id: 6,
    title: 'Pediatric Allergic Rhinitis',
    pdf_link:
      guidelinesGlPediatrics + 'Pediatric-Allergic-Rhinitis-Guidelines.pdf',
  },
  {
    id: 7,
    title: 'Pediatric Hepatitis',
    pdf_link: guidelinesGlPediatrics + 'Pediatric-Hepatitis-Guidelines.pdf',
  },
  {
    id: 8,
    title: 'Pediatric Urinary Tract Infection',
    pdf_link:
      guidelinesGlPediatrics +
      'Pediatric-Urinary-Tract-Infection-Guidelines.pdf',
  },
];
