import React, {useEffect, useState} from 'react';
import styled, {useTheme} from 'styled-components';
import {motion, AnimatePresence} from 'framer-motion';
import Icon from '../icon';
import GenericButton from '../button';
import {Theme} from 'theme/lightTheme';
import {HomeTabsProps} from '../../interfaces/components/homeTabs';
import {removeHtmlTags} from '../../utils/helper';
import {tr} from '../../translation';
import ReactDOM from 'react-dom';

const Container = styled(motion.button)`
  width: 100%;
  aspect-ratio: 1;
  border-radius: 30px;
  background-color: ${({theme}) => theme.home.cardBackground};
  padding: 8%;
  border: none;
  cursor: pointer;
  position: relative;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  transition: transform 0.2s ease;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0 auto;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5px;
`;

const ImageContainer = styled(motion.div)`
  display: flex;
  flex: 1;
  height: 95%;
  aspect-ratio: 1;
  padding-top: 5%;
  align-self: flex-end;
  position: relative;
`;

const Image = styled.img`
  flex: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  object-fit: contain;
`;

const Title = styled.p`
  font-size: ${({theme}) => theme.text.s6};
  color: ${({theme}) => theme.text.light};
  flex: 1;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: left;
  font-weight: 200;
`;

const ExpandContainer = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 500px;
  background-color: ${({theme}) => theme.homeBackground + 'AA'};
  border-radius: 16px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  backdrop-filter: blur(10px);
`;

const MultiTabsTitle = styled.p`
  width: 100%;
  font-size: ${({theme}) => theme.text.s6};
  padding-top: ${({theme}) => theme.text.s6};
  color: ${({theme}) => theme.text.black};
  letter-spacing: 0.5px;
  text-align: center;
  margin: 0;
  font-weight: bold;
`;

const SelectButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex: 1;
  margin-top: 10%;
  margin-bottom: 5%;
`;

const SelectButton = styled(GenericButton)`
  width: 80%;
`;

const Overlay = styled(motion.div)`
  position: fixed;
  inset: 0;
  background-color: ${({theme}) => theme.overlay};
  z-index: 999;
  backdrop-filter: blur(3px);
`;

const CloseIconContainer = styled.button`
  position: absolute;
  padding: ${({theme}) => theme.text.s8};
  top: 16px;
  right: 16px;
  background-color: ${({theme}) => theme.secondary + '22'};
  border-radius: 100px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.9);
  }
`;

const containerVariants = {
  hidden: {
    opacity: 0,
    scale: 0.8,
    x: '-50%',
    y: '-50%',
  },
  visible: {
    opacity: 1,
    scale: 1,
    x: '-50%',
    y: '-50%',
  },
  exit: {
    opacity: 0,
    scale: 0.8,
    x: '-50%',
    y: '-50%',
  },
};

const overlayVariants = {
  hidden: {opacity: 0},
  visible: {opacity: 1},
  exit: {opacity: 0},
};

// Variants for hover states
const itemVariants = {
  initial: {
    boxShadow: '0px 0px 0px rgba(0,0,0,0)',
  },
  hover: {
    boxShadow: '0px 10px 30px rgba(0,0,0,0.15)',
  },
  tap: {
    scale: 0.98,
  },
};

// Child variants for the image container
const imageVariants = {
  initial: {
    scale: 1,
  },
  hover: {
    scale: 1.1,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 25,
    },
  },
};
const homeTabsOverlay = document.getElementById('homeTabsOverlay');

const HomeTab: React.FC<HomeTabsProps> = ({
  title,
  titleStyle,
  style,
  className,
  onPress,
  imageUri,
  defaultImage,
  multiTabs = [],
  isPrimary,
}) => {
  const theme = useTheme() as Theme;
  const [isExpanded, setIsExpanded] = useState(false);

  const handlePress = () => {
    if (multiTabs?.length > 0) {
      setIsExpanded(true);
    } else if (onPress) {
      onPress();
    }
  };

  const handleClose = () => {
    setIsExpanded(false);

    homeTabsOverlay.style.pointerEvents = 'none';
  };

  const handleTabSelect = (key?: string) => {
    onPress?.(key);
    handleClose();
  };

  useEffect(() => {
    homeTabsOverlay.style.pointerEvents = isExpanded ? 'auto' : 'none';
  }, [isExpanded]);

  // This is what we want to "portal" into #overlay
  const overlayContent = (
    <AnimatePresence>
      {isExpanded && (
        <>
          <Overlay
            variants={overlayVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            onClick={handleClose}
          />

          <ExpandContainer
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit">
            <CloseIconContainer onClick={handleClose}>
              <Icon
                name={'close'}
                size={theme.text.s8}
                color={theme.secondary}
              />
            </CloseIconContainer>
            <MultiTabsTitle>{tr('drugs.choose')}</MultiTabsTitle>

            <SelectButtonsContainer>
              {multiTabs.map(item => (
                <SelectButton
                  key={item.label}
                  label={item.label}
                  onPress={() => handleTabSelect(item.key)}
                />
              ))}
            </SelectButtonsContainer>
          </ExpandContainer>
        </>
      )}
    </AnimatePresence>
  );

  return (
    <>
      <Container
        className={className}
        onClick={handlePress}
        style={style}
        variants={itemVariants}
        initial="initial"
        whileHover="hover"
        whileTap="tap">
        <Row>
          <Title style={titleStyle}>{removeHtmlTags(title || '')}</Title>
          <Icon
            type="SVG"
            name="navArrow"
            size={theme.text.s3}
            color={isPrimary ? theme.primary : theme.text.light + '44'}
            stroke={theme.text.light}
          />
        </Row>
        <ImageContainer variants={imageVariants}>
          <Image
            src={imageUri || defaultImage}
            alt={title || 'Tab image'}
            onError={e => {
              const target = e.target as HTMLImageElement;
              target.src =
                defaultImage ||
                require('../../assets/images/newsCardBackground.png');
            }}
          />
        </ImageContainer>
      </Container>
      {homeTabsOverlay &&
        multiTabs?.length > 0 &&
        ReactDOM.createPortal(overlayContent, homeTabsOverlay)}
    </>
  );
};

export default HomeTab;
