import React from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import styled, {useTheme} from 'styled-components';

// Styled components for web
const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999;
  background-color: ${({theme}) => theme.homeBackground + 'AA'};
  backdrop-filter: blur(5px);
`;

const ImageContainer = styled(motion.div)`
  width: clamp(150px, 40vw, 300px);
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  z-index: 2;
  object-fit: contain;
`;

const RecordText = styled.p`
  color: ${({theme}) => theme.text?.dark};
  font-size: ${({theme}) => theme.text?.s6 || '24px'};
  text-align: center;
  align-self: center;
  z-index: 3;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const CloseIconContainer = styled.button`
  background-color: ${({theme}) => theme.text?.light || '#FFFFFF'};
  border: none;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: -48px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateX(-50%) scale(1.05);
  }

  &:active {
    transform: translateX(-50%) scale(0.95);
  }
`;

const PulseAnimation = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid ${({theme}) => theme.primary + '22'};
  opacity: 0;
`;

const PulseEffect: React.FC = () => {
  return (
    <>
      {[...Array(3)].map((_, index) => (
        <PulseAnimation
          key={index}
          animate={{
            scale: [1, 1.8],
            opacity: [0.5, 0],
          }}
          transition={{
            duration: 3,
            repeat: Infinity,
            delay: index * 1,
            ease: 'easeOut',
            times: [0, 1],
            repeatDelay: 0.2,
          }}
          style={{
            filter: 'blur(0.5px)',
          }}
        />
      ))}
    </>
  );
};

interface RecordingViewProps {
  isRecording: boolean;
  stopListening?: () => void;
  style?: React.CSSProperties;
}

const RecordingView: React.FC<RecordingViewProps> = ({
  isRecording,
  stopListening,
  style,
}) => {
  const theme = useTheme();

  return (
    <AnimatePresence>
      {isRecording && (
        <Container
          onClick={stopListening}
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          style={style}>
          <div
            style={{position: 'relative', textAlign: 'center', width: '100%'}}>
            <ImageContainer
              animate={{rotate: 360}}
              transition={{
                duration: 8,
                repeat: Infinity,
                ease: 'linear',
              }}>
              <Image
                src={require('../../assets/images/circle.png')}
                alt="Recording indicator"
              />
              <PulseEffect />
            </ImageContainer>
            <CloseIconContainer onClick={stopListening}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke={theme.primary || '#007AFF'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round">
                <rect x="6" y="4" width="4" height="16" />
                <rect x="14" y="4" width="4" height="16" />
              </svg>
            </CloseIconContainer>
            <RecordText>Listening ...</RecordText>
          </div>
        </Container>
      )}
    </AnimatePresence>
  );
};

export default RecordingView;
